import React from 'react'
import { Container, Typography, Grid, Divider, Box, Button, ListItem, List, SvgIcon, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AboutBanner from '../../assets/about-banner.png'
import OpenBook from '../../assets/open-book.png'
import OrionNebula from '../../assets/orion-nebula.png'
import AstronomyImg from '../../assets/astronomy.png'
import YoutubeImg from '../../assets/youtube.png'
import Share from '../../assets/share.png'
import Facebook from '../../assets/facebook.png'
import SquareImg1 from '../../assets/square-img1.png';
import SquareImg2 from '../../assets/square-img2.png';
import SquareImg3 from '../../assets/square-img3.png';
import HandImg from '../../assets/choices.png';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    contentBox: {
        maxWidth: "1120px",
        margin: "0 auto"
    },
    insideTitle: {
        color: "#6500B0",
        fontSize: "20px",
        fontFamily: "Roboto",
        textAlign: "center",
        fontWeight: 400,
        margin: "15px auto 0",
    },
    insideTextP: {
        color: "#000000CC",
        fontSize: "28px",
        fontFamily: "Roboto Slab",
        fontWeight: 500,
        textAlign: "center",
        margin: '18px auto 40px',
        lineHeight: 1.4,
        "@media (min-width: 841px)": {
            maxWidth: "85%",
        }
    },
    adobeBanner: {
        width: "100%",
    },
    religiousHr: {
        border: "1px solid #d3d3d333",
        margin: "15px auto"
    },
    religiousBookText: {
        "@media (min-width: 841px)": {
            paddingLeft: "60px"
        }
    },
    reasonText: {
        color: "#000000CC",
        fontSize: "24px",
        fontFamily: "Roboto Slab",
        lineHeight: 1.4,
        margin: "15px auto",
        "@media (max-width: 767px)": {
            fontSize: "16px",
        }
    },
    missionText: {
        color: "#00000099",
        fontSize: "18px",
        fontFamily: "Open Sans",
        fontStyle: "italic",
        lineHeight: 1.6,
        borderLeft: "3px solid #6500B0",
        paddingLeft: "10px",
        marginTop: "35px",
        marginBottom: "25px",
        "@media (max-width: 767px)": {
            fontSize: "14px",
        },
        "@media (min-width: 841px)": {
            marginLeft: "80px"
        }
    },
    scientificContentP: {
        margin: "25px 0",
        color: "#00000099",
        fontSize: "18px",
        fontFamily: "Open Sans",
        lineHeight: 1.3,
        "@media (max-width: 767px)": {
            fontSize: "14px",
        }
    },
    image: {
        width: "100%"
    },
    originLife: {
        color: "#6500B0",
        fontSize: "24px",
        fontFamily: "Roboto Slab",
        fontWeight: 500,
        margin: "25px 0 13px",
    },
    originText: {
        color: "#00000099",
        fontSize: "18px",
        fontFamily: "Open Sans",
        lineHeight: 1.5,
        "@media (max-width: 767px)": {
            fontSize: "14px",
        }
    },
    lowerText: {
        fontSize: "14px",
        fontFamily: "Open Sans",
        fontWeight: 400,
        fontStyle: "italic",
        color: "#00000080",
        margin: "25px 0 20px",
    },
    youtTextB: {
        fontSize: "14px",
        color: "#0A1AD6",
        fontWeight: 600,
    },
    shareText: {
        color: "#444444",
        fontSize: "12px",
        fontFamily: "Roboto Slab",
        border: "1px solid #E2E2E2",
        padding: "7px 12px",
        display: "inline-flex",
        margin: "0 5px",
    },
    faceText: {
        backgroundColor: "#516EAB",
        padding: "5px 12px 2px 12px",
        margin: "0 5px",
    },
    faceTextImg: {
        width: "7px",
    },
    twitterText: {
        backgroundColor: "#29C5F6",
        padding: "5px 8px 2px 10px",
        margin: "0 5px",
    },
    lowerSocialIcons: {
        float: "right",
    },
    socialImgTag: {
        width: "13px",
        height: "10px",
        top: "-3px",
        position: "relative",
        fill: "#fff !important"
    },
    donateContent: {
        border: '2px solid #FEC553',
        background: '#fff',
        padding: '15px 15px 20px',
        borderRadius: '0px 0px 4px 4px'
    },
    donateTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 24,
        margin: 0,
        textTransform: "uppercase",
    },
    handContent: {
        background: '#FEC553',
        padding: '10px 15px',
        textAlign: 'center',
        borderRadius: '4px 4px 0px 0px',
        marginTop: 40
    },
    donateButton: {
        padding: 10,
        background: '#FEC553 !important',
        borderRadius: 4,
        color: 'white',
        border: 0,
        width: '100%',
        height: '50px',
        fontSize: 15,
        marginBottom: 10,
        '&:hover': {
            background: '#FEC553'
        },
        marginTop: "20px"
    },
    donate: {
        color: '#666',
        fontSize: "14px",
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        paddingTop: "5px"
    },
    smallAds: {
        backgroundColor: "#F2F2F2",
        color: "#9cb4cb5c",
        fontSize: "50px",
        padding: "50px 20px",
        textAlign: "center",
        fontWeight: 900,
        fontFamily: 'Poppins ,sans-serif',
        margin: "30px 0",
    },
    latestNewsLabel: {
        textAlign: "center",
        textTransform: "uppercase",
        fontSize: "20px",
        fontFamily: 'sans-serif',
        backgroundColor: "#6500B0",
        color: "#fff",
        padding: "12px 20px",
    },
    squareImg: {
        width: "85px",
        height: "85px",
    },
    newsSection: {
        display: "flex",
        borderBottom: "1px solid #d3d3d333",
        margin: "30px 0",
        paddingBottom: "10px",
    },
    lastNews: {
        borderBottom: "none",
    },
    newsText: {
        paddingLeft: "15px",
    },
    mainText: {
        color: "#292929",
        fontSize: "14px",
        fontFamily: "sans-serif",
        lineHeight: 1.4,
        fontWeight: 700,
        margin: "10px auto"
    },
    newsDate: {
        color: "#979797",
        fontSize: "14px",
        fontFamily: "sans-serif"
    },
    customBox: {
        border: "1px solid #E4E8EC",
        marginTop: "30px",
        padding: "10px",
    },
    cGreen: {
        backgroundColor: "#A0CE4E",
        color: "#fff",
        fontSize: "20px",
        padding: "12px",
        display: "inline-block",
    },
    customBoxHeader: {
        display: "flex",
    },
    infoM: {
        paddingLeft: "10px",
    },
    infoP: {
        margin: 0,
        color: " #627FFF",
        fontFamily: 'sans-serif',
        fontSize: "16px"
    },
    originTextSpan: {
        display: 'flex'
    },
    fbBtn: {
        backgroundColor: "#fff !important",
        border: "none",
        color: "#444444 !important",
        width: "100%",
        marginTop: "40px",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: 500,
    },
    gt: {
        float: "right",
        display: "inline-block",
    },
    findBtn: {
        marginLeft: "auto"
    },



}));


export default function Body() {
    const classes = useStyles();

    return (
        <Container>
            <Grid container className={classes.contentBox}>
                <Grid item xs={12} >
                    <Typography variant="h6" className={classes.insideTitle}>
                        WHAT'S INSIDE
                    </Typography>
                    <Typography variant="body1" className={classes.insideTextP}>
                        There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:
                    </Typography>
                    <img src={AboutBanner} className={classes.adobeBanner} alt="banner" />
                </Grid>
            </Grid>
            <Box component={"div"} >
                <Grid container spacing={5} className={classes.contentBox}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body1" className={classes.reasonText}>
                            There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:
                        </Typography>
                        <Divider className={classes.religiousHr} />

                        <Box component={"div"} className={classes.scientificContent}>
                            <Typography variant="body1" className={classes.scientificContentP}>
                                1. There are scientific and historical facts found in the Quran which many people were unfamiliar at the
                                time, and have only been discovered recently by contemporary science.
                            </Typography>
                            <Typography variant="body1" className={classes.scientificContentP}>
                                2. The Quran is in a unique style of language that cannot be replicated, this is known as the
                                ‘Inimitability of the Quran.’
                            </Typography>
                            <Typography variant="body1" className={classes.scientificContentP}>
                                3. There are prophecies made in the Quran and by the Prophet Muhammad Sallalahu Alahi wa Sallam which
                                have been fulfilled.
                            </Typography>
                        </Box>

                        <Box component={"div"}>
                            <img src={OpenBook} alt="open-book" className={classes.image} />
                        </Box>

                        <Typography variant="h6" className={classes.originLife}>
                            Origin of Life
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            This is the Book (the Quran), whereof there is no doubt, a guidance to those who are
                            Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil
                            deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained).
                            Al-Quran 2:2
                        </Typography>


                        <Typography variant="body1" className={classes.missionText}>
                            Our mission is, “To provide multifaceted services to the Muslim community of Brooklyn through building and
                            maintaining appropriate facilities.”
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            Muslims & organizations collectively around the globe has pioneered and contributed
                            immensely to the development of human civilization. Fatima and Miriam al-Firhi prioneered the education system and built world’s first university
                        </Typography>

                        <Box component={"div"} mt={2}>
                            <img src={OrionNebula} alt="orion-nebula" className={classes.image} />
                        </Box>


                        <Typography variant="h6" className={classes.originLife}>
                            Astronomy
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            This is the Book (the Quran), whereof there is no doubt, a guidance to those who are
                            Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil
                            deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained).
                            Al-Quran 2:2
                        </Typography>

                        <Box component={"div"} >
                            <img src={AstronomyImg} alt="astronomy-img" className={classes.image} />
                        </Box>


                        <Typography variant="h6" className={classes.originLife}>
                            Geology
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            This is the Book (the Quran), whereof there is no doubt, a guidance to those who are
                            Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil
                            deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained).
                            Al-Quran 2:2
                        </Typography>

                        <Typography variant="h6" className={classes.originLife}>
                            Biology
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            This is the Book (the Quran), whereof there is no doubt, a guidance to those who are
                            Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil
                            deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained).
                            Al-Quran 2:2
                        </Typography>

                        <List>
                            <ListItem>
                                <Typography variant="body1" className={`${classes.originText} ${classes.originTextSpan}`}>
                                    <span>• &nbsp;&nbsp; </span><span>Organizations that are fraudulent, misleading, inaccurate, dishonest, impossible or
                                    imitating any other person or fundraising campaign without permission (whether on the Platform or not)</span>
                                </Typography>

                            </ListItem>
                            <ListItem>
                                <Typography variant="body1" className={`${classes.originText} ${classes.originTextSpan}`}>
                                    <span>• &nbsp;&nbsp;</span><span>The Quran is in a unique style of language that cannot be replicated, this is known
                                    as the ‘Inimitability of the Quran.’</span>
                                </Typography>
                            </ListItem>
                        </List>

                        <Box component={"div"} >
                            <img src={YoutubeImg} alt="youtube-img" className={classes.image} />
                        </Box>

                        <Typography variant="h6" className={classes.originLife}>
                            Embryology
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            This is the Book (the Quran), whereof there is no doubt, a guidance to those who are
                            Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil
                            deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained).
                            Al-Quran 2:2
                        </Typography>

                        <Typography variant="h6" className={classes.originLife}>
                            Historical Divine Events
                        </Typography>

                        <Typography variant="body1" className={classes.originText}>
                            This is the Book (the Quran), whereof there is no doubt, a guidance to those who are
                            Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil
                            deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained).
                            Al-Quran 2:2
                        </Typography>

                        <Divider className={classes.religiousHr} />

                        <Typography variant="body1" className={classes.lowerText}>
                            1. There are scientific and historical facts found in the Quran which many people were
                            unfamiliar at the time, and <Typography variant="caption" className={classes.youtTextB}>have only been discovered</Typography> recently by
                            contemporary science.
                        </Typography>

                        <Typography variant="body1" className={classes.lowerText}>
                            2. <Link
                                href="https://youtu.be/PDxKxnVZtgo"
                                underline="none"
                                target="_blank"
                                rel="noopener"
                                className={classes.donateButtonLink}
                            ><Typography variant="caption" className={classes.youtTextB}>

                                    https://youtu.be/PDxKxnVZtgo
                            </Typography>
                            </Link>
                        </Typography>

                        <Typography variant="body1" className={classes.lowerText}>
                            3. There are prophecies made in the Quran and by the Prophet Muhammad Sallalahu Alahi wa
                            Sallam which have been fulfilled.
                        </Typography>


                        <Divider className={classes.religiousHr} />

                        <Box component={"div"} className={classes.lowerSocialIcons}>
                            <Box component="span" className={classes.shareText}>
                                <img src={Share} alt="share" /> | Share
                            </Box>
                            <Box component="span" className={classes.faceText}>
                                <Link
                                    href="https://facebook.com"
                                    underline="none"
                                    target="_blank"
                                    rel="noopener"
                                    className={classes.donateButtonLink}
                                >
                                    <img src={Facebook} alt="facebook" className={classes.faceTextImg} />
                                </Link>
                            </Box>
                            <Box component="span" className={classes.twitterText}>
                                <Link
                                    href="https://twitter.com"
                                    underline="none"
                                    target="_blank"
                                    rel="noopener"
                                    className={classes.donateButtonLink}
                                >
                                    <SvgIcon className={classes.socialImgTag} xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24"
                                        viewBox="0 0 24 24">
                                        <path
                                            d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                    </SvgIcon>
                                </Link>

                            </Box>
                        </Box>


                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={classes.handContent}>
                            <img src={HandImg} alt="hand-png" />
                        </Box>
                        <Box className={classes.donateContent}>
                            <h4 className={classes.donateTitle}>ORDER FREE QURAN</h4>
                            <p className={classes.donate}>Order your free copy of Quran in the language and quantity you need.</p>
                            <Button variant="outlined" size="medium" className={`${classes.donateButton} mdc-button mdc-button--raised`} component={RouterLink} to="/order">
                                ORDER NOW
                                 </Button>
                        </Box>

                        <Box component="div" className={classes.smallAds}>
                            ADS 336x280
                        </Box>


                        <Box component="div" className={classes.latestNewsLabel}>
                            Latest news & views
                        </Box>

                        <Box component="div" className={classes.newsSection}>
                            <img src={SquareImg1} className={classes.squareImg} alt="square-img" />
                            <Box component="div" className={classes.newsText}>
                                <Typography variant="body1" className={classes.mainText}>
                                    Islam, the Quran, and the Five Pillars All Without...
                                    </Typography>
                                <Typography variant="body1" className={classes.newsDate}>
                                    Jun 11, 2020
                                    </Typography>
                            </Box>
                        </Box>

                        <Box component="div" className={classes.newsSection}>
                            <img src={SquareImg2} className={classes.squareImg} alt="square-img" />
                            <Box component="div" className={classes.newsText}>
                                <Typography variant="body1" className={classes.mainText}>
                                    Rescuing Ex-Muslims: Leaving Islam                                    </Typography>
                                <Typography variant="body1" className={classes.newsDate}>
                                    Jun 11, 2020
                                    </Typography>
                            </Box>
                        </Box>

                        <Box component="div" className={`${classes.newsSection} ${classes.lastNews}`}>
                            <img src={SquareImg3} className={classes.squareImg} alt="square-img" />
                            <Box component="div" className={classes.newsText}>
                                <Typography variant="body1" className={classes.mainText}>
                                    Bracing the Perfect Storm with an Air of Calm                                    </Typography>
                                <Typography variant="body1" className={classes.newsDate}>
                                    Jun 11, 2020
                                    </Typography>
                            </Box>
                        </Box>


                        <Box component="div" className={classes.latestNewsLabel}>
                            Get Connected
                        </Box>

                        <Box component="div" className={classes.customBox}>
                            <Box component="div" className={classes.customBoxHeader}>
                                <Typography variant="body2" className={classes.cGreen}>
                                    M
                                </Typography>
                                <Box component="div" className={classes.infoM}>
                                    <Typography variant="body1" className={classes.infoP}>
                                        Masjid Bilal of NYC
                                    </Typography>
                                    <Typography variant="body1">
                                        10,000 likes
                                    </Typography>
                                </Box>
                            </Box>

                            <Button variant="outlined" size="medium" className={`${classes.fbBtn} mdc-button mdc-button--raised`} component={RouterLink} to="/order">
                                <Typography variant="caption" className={`${classes.findBtn} mdc-button__label`}>Find us on Facebook</Typography>
                                <Typography variant="caption" className={classes.findBtn}> {">"} </Typography>
                            </Button>

                        </Box>

                    </Grid>
                </Grid>
            </Box>

        </Container>
    )
}
