import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { ArrowForward } from '@material-ui/icons';
import Img1 from '../../assets/inside-quranic.png';
import Img2 from '../../assets/astronomy.png';
import Img3 from '../../assets/geology.png';
import Img4 from '../../assets/biology.png';
import Img5 from '../../assets/embroyology.png';
import Img6 from '../../assets/inside-quranic.png';


const useStyles = makeStyles((theme) => ({
    mainBox: {
        background: '#fafbfe',
        marginTop: 60
    },
    title: {
        color: '#080F58',
        fontSize: 36,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        fontWeight: 400,
        lineHeight: 1,
        paddingTop: 40,
        '@media (max-width:510px)': {
            fontSize: 24
        }
    },
    text: {
        color: '#666',
        fontSize: 16,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        fontWeight: 600
    },
    insideTitle: {
        fontFamily: 'Roboto Slab',
        fontSize: 30,
        color: '#080F58',
        margin: '0 0 15px',
        fontWeight: 400
    },
    insideContent: {
        color: '#666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        lineHeight: 1.8
    },
    marginTop: {
        marginTop: 10
    },
    insideQuranic: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: 15,
        minHeight: 240,
        borderRadius: '5px 5px 0px 0px',
        width: 'auto',
        backgroundRepeat: 'no-repeat'
    },
    img1: {
        background: `linear-gradient(180deg, #4B008200 0%, #4B008299 100%), url(${Img1})`
    },
    img2: {
        background: `linear-gradient(180deg, #4B008200 0%, #4B008299 100%), url(${Img2})`
    },
    img3: {
        background: `linear-gradient(180deg, #4B008200 0%, #4B008299 100%), url(${Img3})`
    },
    img4: {
        background: `linear-gradient(180deg, #4B008200 0%, #4B008299 100%), url(${Img4})`
    },
    img5: {
        background: `linear-gradient(180deg, #4B008200 0%, #4B008299 100%), url(${Img5})`
    },
    img6: {
        background: `linear-gradient(180deg, #4B008200 0%, #4B008299 100%), url(${Img6})`
    },
    insideLabel: {
        color: '#fff',
        fontFamily: 'Roboto Slab',
        fontSize: 20,
        padding: 15,
        fontWeight: 600
    },
    imgContent: {
        background: '#fff',
        padding: '25px 15px',
        border: '1px solid #0000001A',
        minHeight: "171px",
        position: "relative",

        '@media (max-width:500px)': {
            minHeight: "initial",
        }


    },
    quranicText: {
        margin: 0,
        fontFamily: 'Open Sans',
        fontSize: 14,
        lineHeight: 1.5,
        color: '#666'
    },
    readMore: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#080F58',
        fontFamily: 'Open Sans',
        fontWeight: '700',
        marginTop: 20,
        position: "absolute",
        left: "20px",
        bottom: "20px",

        '@media (max-width:500px)': {
            position: "relative",
            left: "0px",
            bottom: "0px",
            marginTop: 10,
        }

    },
    buttonContainer: {
        paddingTop: 20,
        paddingBottom: 40,
        textAlign: 'center',
    },
    orderButton: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        marginBottom: 10,
        height: 45,
        width: 230,
        '&:hover': {
            background: '#00C9A8'
        }
    },

}));



export default function MainFeaturedPost(props) {
    const classes = useStyles();

    return (

        <div className='ProjectContainer'>
            <Box className={classes.mainBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container>
                        <Grid item xs={12} >
                            <Typography variant="h3" ref={props.insideRef} classes={{ h3: classes.title }}>What's Inside? </Typography>
                            <Typography className={classes.text} >There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:</Typography>

                        </Grid>

                        <Grid container className={classes.marginTop} spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h3" classes={{ h3: classes.insideTitle }}>1.</Typography>
                                <Typography className={classes.insideContent}>There are scientific and historical facts found in the Quran which many people were unfamiliar at the time, and have only been discovered recently by contemporary science.</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h3" classes={{ h3: classes.insideTitle }}>2.</Typography>
                                <Typography className={classes.insideContent}>The Quran is in a unique style of language that cannot be replicated, this is known as the ‘Inimitability of the Quran.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h3" classes={{ h3: classes.insideTitle }}>3.</Typography>
                                <Typography className={classes.insideContent}>There are prophecies made in the Quran and by the Prophet Muhammad Sallalahu Alahi wa Sallam which have been fulfilled.</Typography>
                            </Grid>

                        </Grid>
                        <Grid container item xs={12} spacing={3} >


                            <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>

                                <Box className={`${classes.insideQuranic} ${classes.img1}`} >
                                    <Typography variant="h3" className={classes.insideLabel} >Origin of Life</Typography>
                                </Box>

                                <Box className={classes.imgContent}>
                                    <Typography className={classes.quranicText}>From an examination of creation as described in the Qur’an, an extremely important general concept emerges: The Qur’anic narration is quite different from the Biblical narration. </Typography>
                                    <Typography className={classes.readMore} >
                                        <a href="https://www.whyislam.org/on-faith/the-quran-and-modern-science-3/" target="_blank"><span>READ MORE</span></a>

                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>

                                <Box className={`${classes.insideQuranic} ${classes.img2}`} >
                                    <Typography variant="h3" className={classes.insideLabel} >Astronomy</Typography>
                                </Box>

                                <Box className={classes.imgContent}>
                                    <Typography className={classes.quranicText}>Whenever I describe to Westerners the details the Qur’an contains on certain points of astronomy, it is common for someone to reply that there is nothing unusual in this since the Arabs made important discoveries in the field of astronomy long before the Europeans.</Typography>
                                    <Typography className={classes.readMore} >
                                        <a href="https://www.whyislam.org/on-faith/the-quran-and-modern-science-3/" target="_blank"><span>READ MORE</span></a>

                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>

                                <Box className={`${classes.insideQuranic} ${classes.img3}`} >
                                    <Typography variant="h3" className={classes.insideLabel} >Geology</Typography>
                                </Box>

                                <Box className={classes.imgContent}>
                                    <Typography className={classes.quranicText}>Let us now return to earth to discover some of the many amazing statements contained in Qur’anic reflections about our own planet. They deal, not only with the physical phenomena observed here on earth, but also with details concerning the living organisms that inhabit it.</Typography>
                                    <Typography className={classes.readMore} >
                                        <a href="https://www.whyislam.org/on-faith/the-quran-and-modern-science-3/" target="_blank"><span>READ MORE</span></a>

                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>

                                <Box className={`${classes.insideQuranic} ${classes.img4}`} >
                                    <Typography variant="h3" className={classes.insideLabel} >Biology</Typography>
                                </Box>

                                <Box className={classes.imgContent}>
                                    <Typography className={classes.quranicText}>More than anything else, I was struck by statements in the Qur’an dealing with living things, both in the animal and vegetable kingdoms, especially with regard to reproduction.</Typography>
                                    <Typography className={classes.readMore} >
                                        <a href="https://www.whyislam.org/on-faith/the-quran-and-modern-science-3/" target="_blank"><span>READ MORE</span></a>

                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>

                                <Box className={`${classes.insideQuranic} ${classes.img5}`} >
                                    <Typography variant="h3" className={classes.insideLabel} >Embryology</Typography>
                                </Box>

                                <Box className={classes.imgContent}>
                                    <Typography className={classes.quranicText}>There are a multitude of statements in the Qur’an on the subject of human reproduction which constitute a challenge to the embryologist seeking a human explanation for them.</Typography>
                                    <Typography className={classes.readMore} >
                                        <a href="https://www.whyislam.org/on-faith/the-quran-and-modern-science-3/" target="_blank"><span>READ MORE</span></a>

                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} className={classes.marginTop}>

                                <Box className={`${classes.insideQuranic} ${classes.img6}`} >
                                    <Typography variant="h3" className={classes.insideLabel} >Women in the Qur’an</Typography>
                                </Box>

                                <Box className={classes.imgContent}>
                                    <Typography className={classes.quranicText}>From cosmic to mundane affairs, the Qur’an is a scripture (kitāb) containing a “clarification of all things, and a guidance and a mercy and glad tidings for those who submit” (Qur’an 16:89).</Typography>
                                    <Typography className={classes.readMore} >
                                        <a href="https://yaqeeninstitute.org/read/paper/women-in-the-quran-appreciating-female-faith-wisdom-and-knowledge" target="_blank"><span>READ MORE</span></a>

                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.buttonContainer} >
                            <Button variant="outlined" size="medium" className={classes.orderButton} component={RouterLink} to="/order">
                                ORDER FREE QURAN
                            </Button>
                        </Grid>

                    </Grid>

                </Container>
            </Box>
        </div>
    );
}
