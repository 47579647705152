import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Container, Button, Box, Grid, Typography, Link } from '@material-ui/core';
import Influencer from '../../assets/influencer.png';
import Charity from '../../assets/charity.png';
import Order from '../../assets/choices.png';
import Recieve from '../../assets/order.png';
import orderIcon from '../../assets/order.svg';
import Explore from '../../assets/hand-color.png';
import BackgroundImage from '../../assets/grey_pattren.png';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import { GetImages } from '../../actions/homeActions';
import { Link as RouterLink } from 'react-router-dom';



const styles = theme => ({
    donateBox: {
        marginTop: 40
    },
    aboutTitle: {
        color: '#080F58',
        fontSize: 36,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        lineHeight: 1,
        paddingTop: 60,
        fontWeight: 400,
        '@media (max-width:510px) ': {
            fontSize: 24
        }
    },
    aboutText: {
        color: '#666',
        fontFamily: 'Open Sans',
        wordSpacing: 1.5,
        fontSize: 15,
        lineHeight: 1.7,
        textAlign: "justify",
        width: "90%",
        '@media (max-width:510px) ': {
            fontSize: 16
        }
    },
    AboutbarImg: {
        width: '100%',
        marginTop: 20,
        marginBottom: 0,
        '@media (max-width:400px)': {
            height: 180,
        },
        '@media (min-width:401px) and (max-width:600px)': {
            height: 250
        },
        '@media (min-width:601px) and (max-width:900px)': {
            height: 400
        },
        '@media (min-width:901px)': {
            height: 450
        }
    },
    influencerTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 18,
        fontWeight: 400,
        marginTop: 10
    },
    influencerText: {
        color: '#666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        lineHeight: 1.5,
        marginTop: 20,
        marginBottom: 20
    },
    influencerParent: {
        textAlign: 'center',
        border: '1px solid #0000001A',
        borderRadius: 3,
        background: '#fff',
        padding: '20px 30px',
        height: '90%',
        '@media (max-width:959px)': {
            height: 'auto'
        }
    },
    orderButton: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        marginBottom: 50,
        height: 45,
        width: 160,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    InButton: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        height: 45,
        width: 160,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    title: {
        fontSize: 40,
        fontFamily: 'Roboto Slab',
        color: '#080F58',
        fontWeight: 400,
        '@media (max-width:510px) ': {
            fontSize: 26
        }
    },
    MainBox: {
        textAlign: 'center',
        marginTop: 65,
        paddingTop: 60,
        backgroundImage: `url(${BackgroundImage})`
    },
    donateButton: {
        padding: 10,
        marginLeft: 5,
        background: 'white',
        borderColor: '#00C9A8',
        borderRadius: 3,
        color: '#00C9A8',
        marginBottom: 50,
        height: 45,
        width: 160

    },
    buttonContainer: {
        marginTop: 30
    },
    gallery: {
        background: 'black',
        padding: '10px 5px 0px',
        borderRadius: '0px 0px 4px 4px',
        textAlign: 'center',
        height: 85,
        overflow: 'hidden',
        marginBottom: 20
    },
    galleryContent: {
        width: '75%',
        display: 'inline-block',
        textAlign: 'center'
    },
    galleryImg: {
        background: '#fff',
        height: 75,
        width: 90,
        display: 'inline-block',
        margin: '0px 4px 10px 0px',
        borderRadius: 4
    },
    arrowLeft: {
        border: '1px solid #fff',
        borderRadius: 25,
        padding: 5,
        position: 'relative',
        top: 20,
        float: 'left',
        color: 'white'
    },
    arrowRight: {
        border: '1px solid #fff',
        borderRadius: 25,
        padding: 5,
        position: 'relative',
        top: 20,
        float: 'right',
        color: 'white'
    },
    miniTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 22,
        fontWeight: 400,
        marginTop: 30
    },
    maxWidth: {
        maxWidth: 1120
    }
});

class Involved extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [
                {
                    "img" :"img1.png" 
                },
                {
                    "img" :"img2.png"
                },
                {
                    "img" :"img3.png"
                },
                {
                    "img" :"img4.png"
                },
                {
                    "img" :"img6.png"
                },
                {
                    "img" :"img7.png"
                },
                
                {
                    "img" :"img8.png"
                },
                
                {
                    "img" :"img9.png"
                }
            ],
            showImg: 0
        }
    }


    componentDidMount() {
        this.props.GetImages();
    }

    static getDerivedStateFromProps(props, state) {

        const { images } = props?.home ? props.home : {}    ;

        let stateChanged = false;
        let changedState = {};


        if (images && JSON.stringify(state.images) !== JSON.stringify(images)) {
            changedState.images = images;
            stateChanged = true;
        }


        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    onClick = index => {
        console.log('index', index);
        this.setState({
            showImg: index
        })

    }

    nextClick = () => {
        const nextIndex = (this.state.showImg + 1) % this.state.images.length
        this.setState({
            showImg: nextIndex
        })
    }


    prevClick = () => {

        let nextIndex = ''

        if (this.state.showImg === 0) {
            nextIndex = this.state.images.length - 1
        }
        else {
            nextIndex = this.state.showImg - 1
        }


        this.setState({
            showImg: nextIndex
        })
    }

    render() {
        const { classes } = this.props;
        const { images, showImg } = this.state;
        let backendServerURL = process.env.REACT_APP_API_URL

        return (
            <React.Fragment>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} >

                            <Typography variant="h3" ref={this.props.wordsInActionRef} classes={{ h3: classes.aboutTitle }}>Get Involved</Typography>
                            <Typography className={classes.aboutText} >And let there be [arising] from you a nation inviting to [all that is] good, enjoining what is right and forbidding what is wrong, and those will be the successful. Al-Quran 3:104</Typography>
                            {images && images.length && <img src={`${backendServerURL}/images/${images[showImg].img}`} className={classes.AboutbarImg} alt="about bar img" />}

                            <Box className={classes.gallery}>
                                <ArrowBack className={classes.arrowLeft} onClick={this.prevClick} />

                                <Box className={classes.galleryContent} >
                                    {images && images.map((image, index) => (
                                        <img src={`${backendServerURL}/images/${image.img}`} key={index} className={classes.galleryImg} onClick={() => this.onClick(index)} alt="slider Imges" />
                                    ))}
                                </Box>

                                <ArrowForward className={classes.arrowRight} onClick={this.nextClick} />
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box className={classes.influencerParent}>
                                <img src={Influencer} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >Become an Ambassador</Typography>
                                <Typography className={classes.influencerText}>We are always looking forward to partner with amazing individuals and organizations in distributing Quran to the unreached.</Typography>

                                <Button variant="outlined" size="medium" className={classes.InButton} component={RouterLink} to="/about-us#contact-us"> Yes I'm In</Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box className={classes.influencerParent}>
                                <img src={Charity} alt="charity-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >Become a Volunteer</Typography>
                                <Typography className={classes.influencerText}>We are always looking forward to partner with amazing individuals and organizations in distributing Quran to the unreached.</Typography>

                                <Button variant="outlined" size="medium" className={classes.InButton} component={RouterLink} to="/about-us#contact-us"> Yes I'm In</Button>
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box className={classes.influencerParent}>
                                <img src={Influencer} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >Become an Ambassador</Typography>
                                <Typography className={classes.influencerText}>We are always looking forward to partner with amazing individuals and organizations in distributing Quran to the unreached.</Typography>

                                <Button variant="outlined" size="medium" className={classes.InButton} component={RouterLink} to="/about-us#contact-us"> Yes I'm In</Button>
                            </Box>

                        </Grid>

                    </Grid>
                </Container>

                <Box className={classes.MainBox}>
                    <Container classes={{ root: classes.maxWidth }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Typography variant="h3" ref={this.props.howItWorksRef} classes={{ h3: classes.title }}>How it Works </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Box className={classes.influencerParent}>
                                    <img src={orderIcon} alt="influencer-img" />
                                    <Typography variant="h3" className={classes.miniTitle} >Order</Typography>
                                    <Typography className={classes.influencerText}>Order your free Quran in the language and quantity you need.</Typography>

                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4} >
                                <Box className={classes.influencerParent}>
                                    <img src={Recieve} alt="influencer-img" />
                                    <Typography variant="h3" className={classes.miniTitle} >Recieve</Typography>
                                    <Typography className={classes.influencerText}>Receive your free Quran by shipment.</Typography>

                                </Box>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Box className={classes.influencerParent}>
                                    <img src={Explore} alt="influencer-img" />
                                    <Typography variant="h3" className={classes.miniTitle} >Explore</Typography>
                                    <Typography className={classes.influencerText}>Dive in and reflect with the direct words from the Almighty and Everlasting God.</Typography>

                                </Box>

                            </Grid>

                            <Grid item xs={12} className={classes.buttonContainer} >
                                <Button variant="outlined" size="medium" className={classes.orderButton} component={RouterLink} to="/order">
                                    ORDER MY COPY
                        </Button>
                                <Link
                                    target="_blank"
                                    underline="none"
                                    rel="noopener noreferrer"
                                    href={process.env.REACT_APP_DONATE_URL}
                                >
                                    <Button variant="outlined" size="medium" className={classes.donateButton}>
                                        DONATE US
                                   </Button>
                                </Link>

                            </Grid>

                        </Grid>
                    </Container>
                </Box>
            </React.Fragment>
        );
    }
}



Involved.propTypes = {
    GetImages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    home: state.home
});


export default withStyles(styles)(connect(mapStateToProps, { GetImages })(Involved));