import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Container, Button, Box, Grid, Typography, Link } from '@material-ui/core';
import PattrenImg from '../../assets/grey_pattren.png';
import { GetImages } from '../../actions/homeActions';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';




const styles = theme => ({
    MainBox: {
        textAlign: 'center',
        color: '#fff',
        marginTop: 80,
        paddingBottom: 70,
        backgroundImage: `url(${PattrenImg})`,
    },
    activityContainer: {
        textAlign: 'left',
        paddingTop: 25,
        paddingRight: 20,
        '@media (max-width:768px)': {
            padding: '20px !important'
        }
    },
    activities: {
        color: '#080F58',
        fontSize: 60,
        fontWeight: 400,
        fontFamily: 'Roboto Slab',
        marginBottom: 25
    },
    activityLabel: {
        color: '#00000099',
        fontSize: 30,
        fontFamily: 'Roboto Slab',
        lineHeight: 1.5,
        display: 'block'
    },
    activityQuote: {
        fontSize: 20,
        color: '#00000099',
        fontFamily: 'Open Sans',
        display: 'block',
        lineHeight: '1.5',
        marginTop: 20,
        paddingRight: 30
    },
    supportUsButton: {
        background: '#00C9A8',
        marginTop: 45,
        border: 0,
        color: '#fff',
        fontSize: '20px',
        height: 70,
        width: '207px',
        '&:hover': {
            background: '#00C9A8'
        },

        '@media (max-width:768px)': {
            width: '100%',
        }
    },
    imgContent: {
        width: '100%',
        display: 'flex',
        height: 366
    },
    imgSection: {
        '@media (max-width:340px)': {
            paddingRight: '0px !important'
        }
    },
    paddingTop: {
        paddingTop: 40
    },
    gallery: {
        background: 'black',
        padding: '10px 5px 0px',
        borderRadius: '0px 0px 4px 4px',
        textAlign: 'center',
        height: 85,
        overflow: 'hidden'
    },
    galleryContent: {
        width: '75%',
        display: 'inline-block',
        textAlign: 'center'
    },
    galleryImg: {
        background: '#fff',
        height: 75,
        width: 90,
        display: 'inline-block',
        margin: '0px 4px 10px 0px',
        borderRadius: 4
    },
    arrowLeft: {
        border: '1px solid #fff',
        borderRadius: 25,
        padding: 5,
        position: 'relative',
        top: 20,
        float: 'left',
        color: 'white'
    },
    arrowRight: {
        border: '1px solid #fff',
        borderRadius: 25,
        padding: 5,
        position: 'relative',
        top: 20,
        float: 'right',
        color: 'white'
    },
    maxWidth: {
        maxWidth: 1120
    }
});

class OurActivities extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            images: [
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-1.png"
                },
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-2.jpeg"
                },
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-3.jpeg"
                },
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-4.jpeg"
                },
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-5.jpeg"
                },
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-6.jpeg"
                },
                {
                    "img" :"deenconnect-alquran-dawah-center-free-quran-7.jpeg"
                },
                
                {
                    "img" :"img1.png" 
                },
                {
                    "img" :"img2.png"
                },
                {
                    "img" :"img3.png"
                },
                {
                    "img" :"img4.png"
                },
                {
                    "img" :"img6.png"
                },
                {
                    "img" :"img7.png"
                },
                
                {
                    "img" :"img8.png"
                },
                
                {
                    "img" :"img9.png"
                },

                


              
                
            ],
            showImg: 0
        }
    }


    componentDidMount() {
        this.props.GetImages();
    }

    static getDerivedStateFromProps(props, state) {

        const { images } = props?.home ? props.home : '' ;

        let stateChanged = false;
        let changedState = {};


        if (images && JSON.stringify(state.images) !== JSON.stringify(images)) {
            changedState.images = images;
            stateChanged = true;
        }


        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    onClick = index => {
        console.log('index', index);
        this.setState({
            showImg: index
        })

    }

    nextClick = () => {
        const nextIndex = (this.state.showImg + 1) % this.state.images.length
        this.setState({
            showImg: nextIndex
        })
    }


    prevClick = () => {

        let nextIndex = ''

        if (this.state.showImg === 0) {
            nextIndex = this.state.images.length - 1
        }
        else {
            nextIndex = this.state.showImg - 1
        }


        this.setState({
            showImg: nextIndex
        })
    }

    render() {
        const { classes } = this.props;

        const { images, showImg } = this.state;
        let backendServerURL = process.env.REACT_APP_API_URL


        return (
            <Box className={classes.MainBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container spacing={3} className={classes.paddingTop}>
                        <Grid item sm={12} md={6} className={classes.activityContainer} >
                            <Typography variant="h2" className={classes.activities} >Our Activities</Typography>
                            <Typography variant="caption" className={classes.activityLabel}>Be a part of the efforts and help support us in our causes.</Typography>
                            <Typography variant="caption" className={classes.activityQuote}>This is my way; I invite to God with insight, I and those who follow me. And exalted is Allah; and I am not of those who associate others with Him. (Al Qur'an 12:108)</Typography>


                            <Link
                                href={process.env.REACT_APP_DONATE_URL}
                                underline="none"
                                target="_blank"
                                rel="noopener"
                                className={classes.donateButtonLink}
                            >
                                <Button variant="outlined" size="large" className={classes.supportUsButton}>
                                    SUPPORT US
                            </Button>
                            </Link>


                        </Grid>

                        <Grid item sm={12} md={6} className={classes.imgSection}   >
                            <Box className={classes.imgContent}>
                                {images && images.length && <img src={`${backendServerURL}/images/${images[showImg].img}`} style={{ width: '100%', objectFit:"cover" }} alt="about bar img" />}
                            </Box>

                            <Box className={classes.gallery}>
                                <ArrowBack className={classes.arrowLeft} onClick={this.prevClick} />

                                <Box className={classes.galleryContent} >
                                    {images && images.map((image, index) => (
                                        <img src={`${backendServerURL}/images/${image.img}`} key={index} className={classes.galleryImg} onClick={() => this.onClick(index)} alt="slider images" />
                                    ))}
                                </Box>

                                <ArrowForward className={classes.arrowRight} onClick={this.nextClick} />
                            </Box>

                        </Grid>

                    </Grid>
                </Container>
            </Box>
        );
    }
}

OurActivities.propTypes = {
    GetImages: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    home: state.home
});


export default withStyles(styles)(connect(mapStateToProps, { GetImages })(OurActivities));