import React from 'react';

function Donate() {
  // Redirect to an external URL
  React.useEffect(() => {
    window.location.href = 'https://give.deenfund.com/alquran-dawah-center';
  }, []);

  return (
    <div>
      Redirecting...
    </div>
  );
}

export default Donate;
