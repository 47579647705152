import axios from 'axios';

import {
    PAGE_LOADING,
    PAGE_LOADED,
    HOME_FAQ,
    ALL_FAQS,
    INQUIRIES,
    ORDER_FORM,
    INVOLVED_IMAGES
} from './types';

let backendServerURL = process.env.REACT_APP_API_URL;

export const setPageLoading = () => {
	console.log('setPageLoading');
	return {
		type: PAGE_LOADING
	};
};


export const clearPageLoading = () => {
	console.log('clearPageLoading');
	return {
		type: PAGE_LOADED
	};
};


export const GetFAQS = () => dispatch => {
    dispatch(setPageLoading());

	axios
    .get(
        backendServerURL+'/api/faq.json'
    )
    .then(res => {
        console.log('HOME_FAQ', res.data);
        dispatch({type: HOME_FAQ, payload: res.data})
    })
    .catch(err => console.log('err', err ))
    .finally(() => dispatch(clearPageLoading()));
};


export const GetAllFAQS = () => dispatch => {
    dispatch(setPageLoading());

	axios
    .get(
        backendServerURL+'/api/faqAll.json'
    )
    .then(res => {
        console.log('ALL_FAQ', res.data);
        dispatch({type: ALL_FAQS, payload: res.data})
    })
    .catch(err => console.log('err', err ))
    .finally(() => dispatch(clearPageLoading()));
};


export const GetInquiryTypes = () => dispatch => {

	axios
    .get(
        backendServerURL+'/api/inquiryTypes.json'
    )
    .then(res => {
        console.log('INQUIRY', res.data);
        dispatch({type: INQUIRIES, payload: res.data})
    })
    .catch(err => console.log('err', err ))
};



export const OrderFormData = () => dispatch => {

	axios
    .get(
        backendServerURL+'/api/orderFormData.json'
    )
    .then(res => {
        console.log('OrderFormData ', res.data);
        dispatch({type: ORDER_FORM, payload: res.data})
    })
    .catch(err => console.log('err', err ))
};

export const GetImages = () => dispatch => {

	axios
    .get(
        backendServerURL+'/api/images.json'
    )
    .then(res => {
        console.log('INVOLVED_IMAGES ', res.data);
        dispatch({type: INVOLVED_IMAGES, payload: res.data})
    })
    .catch(err => console.log('err', err ))
};