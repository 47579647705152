import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";


export default function DrawerMain(props) {

  const [drawerClose, setDrawerClose] = useState(true);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerClose(open);
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={props.open}
        onClose={props.openHandler}
        className={`side-drawer ${props.sideDrawerClass}`}
      >
        <div>
          <h3 className="DrawerTitel">
            {props.title}
          </h3>
          <div className="DrawerBody">
            {props.children}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
