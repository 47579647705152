import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BannerImage from '../../assets/blue_pattren.png'



const useStyles = makeStyles((theme) => ({
bannerImg : {
  backgroundImage: `url(${BannerImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  textAlign: 'center',
  color: '#fff',
  paddingBottom: 60
},
queryText : {
  color: '#fff',
  margin: '0px auto',
  padding: 20,
  fontSize: 60,
  fontFamily: 'Roboto Slab, serif',
  fontWeight: 600,
  '@media (max-width:599px)' : {
    fontSize: 34
  }
},
title :{
  color: '#fff',
  display: 'block',
  fontSize: 24,
  fontFamily: 'Roboto Slab',
  lineHeight: 1.5,
  paddingTop: 25,
  fontWeight: 600
},
placeOrderText : {
  fontSize: 34,
  fontFamily: 'Open Sans',
  '@media (min-width:961px)' :{
    fontSize: 26
  }
},
button : {
  background: '#fec553',
  marginTop: 15,
  color: 'white',
  '&:hover': {
    background: 'white' 
}
},
arrowBox : {
  textAlign: 'right'
},
arrow :{
  border: '1px solid',
  borderRadius: 35,
  margin: '5px',
  color: '#fff'
},
textCenter : {
  textAlign : 'center'
},
maxWidth :{
  maxWidth: 1120
}
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Grid Container className={classes.bannerImg}> 
    <Container classes={{ root : classes.maxWidth }}>
      <Grid item xs={12}  className={ classes.textCenter}  >
      
          <Typography variant="caption" className={classes.title}>AlQuranForAll.com</Typography>
          <Typography variant="h1" className={ classes.queryText }>Order Form</Typography>
          <Typography variant="caption" className={classes.placeOrderText}>Place Order, Receive and Explore direct words of God.</Typography>
      </Grid>
      </Container>

  </Grid>
  );
}
