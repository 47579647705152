import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PackageCard, SelectField, ButtonOne, TextInputFixedLabel } from "master-react-lib";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import OrderDetais from './OrderDetais'
import Delivery from './Delivery'
import DeliveryMethod from './DeliveryMethod'
import Payment from './Payment'
import Alert from '@material-ui/lab/Alert';
import './OrderForm.css'

import { useSelector, useDispatch } from 'react-redux'
import { setRequesterInfo } from '../../features/counter/counterSlice'


function Checkout(props) {

    const rInfo = useSelector((state) => state.counter.requesterInfo)
    const oInfo = useSelector((state) => state.counter.orderInfo);
    const dispatch = useDispatch();

    const [hidePayment, setHidePayment] = useState(false);

    const [expandPayment, setExpandPayment] = useState(false);

    const [showSummary, setShowSummary] = useState(false);

    useEffect(() => {
        if (rInfo.shippingAddressSameAsBilling) {
            setShowSummary(true);
            setExpandPayment(true);
        }
        if (parseInt(oInfo.orderSubtotal) < 1) {
            setHidePayment(true);
        }
    }, []);

    const clickEditButton = (e) => {
        setShowSummary(false);
        console.log('hii');
    };


    // const [showElement, setShowElement] = React.useState(props.PaymentFailedAlert)
    useEffect(() => {
        
    },
        []);

    // const setPaymentFailedAlert = () =>{
    //     setTimeout(function () {
    //         setShowElement(false);
    //     }, 5000);
    // }



    return (

        <div className="FormContainer">

            <div className="AccordionContianer">

           
                

                <div className='formHeaderCtn'>
                    <Button className='backbutton' href="#text-buttons"><ArrowBackIosRoundedIcon /> Back </Button>
                    <div className='secureText'>Secured checkout <LockOutlinedIcon /></div>
                </div>

                {props.PaymentFailedAlert ? <div className="cashFailedAlrt">
                    <Alert severity="error">Your payment authorization failed on card •••4242. Please verify your information and try again, or try another payment method.</Alert>
                </div> :<></>} 

                

                <Accordion defaultExpanded>

                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <div className="aAccordionTitle" ><div className='AccordionTitleLeft'><CheckCircleRoundedIcon className='checkIcon' /> Order Details</div></div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <OrderDetais />
                    </AccordionDetails>

                </Accordion>
                {!showSummary && (
                    <Accordion expanded={true} >

                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                            <div className="aAccordionTitle" ><div className='AccordionTitleLeft'><CheckCircleRoundedIcon className='checkIcon' />Delivery</div></div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Delivery setExpandPayment={setExpandPayment} deliveryCompleted={setShowSummary} />
                        </AccordionDetails>

                    </Accordion>
                )}

                {showSummary && (
                    <Accordion expanded={showSummary}>

                        <AccordionSummary expanded={true} aria-controls="panel1a-content" id="panel1a-header" >
                            <div className="aAccordionTitle" ><div className='AccordionTitleLeft'><CheckCircleRoundedIcon className='checkIcon' />Delivery Method</div></div>
                        </AccordionSummary>

                        <AccordionDetails expanded={true} >
                            <DeliveryMethod clickEditButton={clickEditButton} setExpandPayment={setExpandPayment} />
                        </AccordionDetails>

                    </Accordion>
                )}


                {!hidePayment && (
                    <Accordion expanded={expandPayment} >

                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                            <div className="aAccordionTitle" ><div className='AccordionTitleLeft'><CheckCircleRoundedIcon className='checkIcon' />Payment Method</div></div>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Payment errorValues={props.errorValues} setPaymentInfo={props.setPaymentInfo} setbuttonDisabled={props.setbuttonDisabled} />
                        </AccordionDetails>

                    </Accordion>
                )}




            </div>





        </div >
    );
}
export default Checkout;


