import React, { useEffect } from 'react';
import Header from '../common/head';
import Banner from './banner';
import Body from './Body';
import OurProject from '../common/ourProject';
import ThanksText from './ThanksText';
import Social from '../common/Social';
import Footer from '../common/Footer';

function App() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  });
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Body />
      <OurProject />
      <ThanksText />
      <Social />
      <Footer />
    </React.Fragment>
  );
}

export default App;