import React from "react";
import { BrowserRouter as Router, Route  } from "react-router-dom";
import Home from "./components/home";
import AllQuranForAll from "./components/Al-Quran-for-all";
import Feedback from "./components/feedback";
import AboutUs from "./components/aboutUs";
import FAQ from "./components/faq";
import ThankYou from "./components/thankYou";
import OrderForm from "./components/OrderForm/";
import ArticleDetails from "./components/articleDetails/Index.js";
import TermsAndPolicies from "./components/terms/Index.js";
import Donate from "./components/donate";


function App() {
  return (
    <Router>
      <Route exact path={"/"} component={() => <Home />} />

      <Route
        exact
        path={"/al-quran-for-all"}
        component={() => <AllQuranForAll />}
      />

      <Route exact path={"/about-us"} component={() => <AboutUs />} />

      <Route exact path={"/feedback"} component={() => <Feedback />} />

      <Route exact path={"/faq"} component={() => <FAQ />} />

      <Route exact path={"/thank-you"} component={() => <ThankYou />} />

      <Route exact path={"/order"} component={() => <OrderForm />} />

      <Route exact path={"/article-details"} component={() => <ArticleDetails />} />

      <Route exact path={"/terms-and-policies"} component={() => <TermsAndPolicies />} />

      <Route exact path={"/donate"} component={() => <Donate />} />

    </Router>
  );
}

export default App;