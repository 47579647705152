import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Container , Button , Grid, Typography , ExpansionPanel , ExpansionPanelDetails , ExpansionPanelSummary } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { GetFAQS } from '../../actions/homeActions';
import { Link as RouterLink } from 'react-router-dom';


const styles =  theme => ({
questionContainer :{
    textAlign: 'left',
	paddingTop: 25
},
title : {
    color: '#080F58',
	fontSize: 60,
	fontWeight: 400,
	fontFamily: 'Roboto Slab',
    marginBottom: 25,
    marginTop: 60,
    '@media (max-width:768px)' : {
        padding: "20px 0",
        marginBottom: 0,
        marginTop: 0,
    }
},
iconColor : {
    background: '#fff',
    color: '#080F58',
    cursor: 'pointer'
},
root: {
    width: '100%',
},
question: {
    color: '#666',
	fontFamily: 'Roboto Slab',
	fontSize: 24,
	cursor: 'pointer',
	lineHeight: 1.5
},
activeColor : {
    color: '#080F58',
},
answer : {
    color: '#666',
	fontFamily: 'Open Sans',
	fontSize: 20,
	lineHeight: 1.5,
    paddingRight: 100,
    '@media (max-width:600px)' : {
        paddingRight: 0
    }
},
moreButton : {
    background: '#00C9A8',
    marginTop: 50,
    border : 0,
    color : '#fff',
    height: 70,
    width:'199px',
    fontSize:'20px',
    '&:hover': {
        background: '#00C9A8', 
    },

    '@media (max-width:768px)' : {
        width:'90%',
        margin: 20,
    }
},
rounded: {
    borderBottom: '1px solid lightgray'
},
PanelRoot : {
    boxShadow : 'none',
    '&:before':{
        display: 'none'
    }
},
maxWidth :{
    maxWidth: 1120
}
});

class Questions extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            panel : 'panel0',
            faqs : [
                {
                    "question" :"What are your projects?" ,
                    "answer" : "Our projects consists of spreading the word of God by giving out the Holy Book, Qur’an. The Holy Qur’an is given out to hospitals, dawah organizations, prisons, dorms, and to those who put in the requests for free Qur’ans."
                },
                {
                    "question" :"How can I contribute to your projects?" ,
                    "answer" : "You can help us by contributing and sponsoring Qur’ans by click on the DONATE NOW button located on top of the page."
                },
                {
                    "question" :"What other resources do you provide for converts?" ,
                    "answer" : "We have pamphlets in different languages and other assistance are provided for converts. Please email us at team@alquranforall.com."
                },
                {
                    "question" :"I’m interested in Islam and have questions. Who can I reach out to?" ,
                    "answer" : "For any inquiries or concerns, reach out to us at team@alquranforall.com."
                },
                {
                    "question" :"Do you have any volunteering opportunities?" ,
                    "answer" : "Yes, please check out “Get Involved” section in our About Us page."
                }
            ]
        }
    }

    handleChange = (panel) =>{
        this.setState({
            panel : panel
        })
    }
    componentDidMount(){
        this.props.GetFAQS();
    }

    onRemoveClick = () =>{
        this.setState({
            panel : false
        })
    }

    
    render(){
        const { classes } = this.props;
        const { panel , faqs } = this.state;
    
        return (
        <Container classes={{ root : classes.maxWidth }}>
            <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2" className={classes.title}>Got Questions?</Typography>

                    {faqs && faqs.map((faq, index) => (

                    <ExpansionPanel key={index} classes={{ root:classes.PanelRoot, rounded : classes.rounded }} expanded={panel === `panel${index}`} >
                        <ExpansionPanelSummary
                            expandIcon={panel === `panel${index}` ? <RemoveCircleIcon className={ classes.iconColor} onClick={this.onRemoveClick }/> : <AddCircleIcon className={ classes.iconColor } onClick={() => this.handleChange(`panel${index}`)} />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography onClick={() => this.handleChange(`panel${index}`)} className={`${classes.question}  ${panel === `panel${index}` && classes.activeColor  } `}>0{index + 1 }. {faq.question} </Typography>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                            <Typography className={ classes.answer }>
                                {faq.answer}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    ))}

                <Button variant="outlined" size="large" className={classes.moreButton } component={RouterLink} to="/faq">
                    MORE FAQS
                </Button>
            </Grid>
            </Grid>
        </Container>
        );
    }
}


Questions.propTypes = {
    GetFAQS: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    home: state.home
});


export default withStyles(styles)(connect(mapStateToProps, {GetFAQS })(Questions));
 