import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button, Box, Grid } from '@material-ui/core'
import QuranImg from '../../assets/quran.png';
import PersonReading from '../../assets/person-book.png';
import BackgroundImage from '../../assets/grey_pattren.png';

const useStyles = makeStyles((theme) => ({
    mainBox: {
        backgroundImage: `url(${BackgroundImage})`,
        marginTop: 80,
        paddingBottom: 127,
    },
    missionText: {
        color: '#00000099',
        fontSize: 24,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        // lineHeight: '42px',
    },
    title: {
        color: '#080F58',
        fontSize: 60,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        fontWeight: 400,
        lineHeight: 1,
        paddingTop: 70,
        '@media (max-width:600px)': {
            fontSize: 30
        }
    },
    text: {
        color: '#00000099',
        fontSize: 24,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        fontWeight: 600
    },
    influencerTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 24,
        fontWeight: 800,
        marginTop: 10
    },
    influencerText: {
        color: '#00000099',
        fontFamily: 'Open Sans',
        fontSize: 20,
        lineHeight: 1.5,
        marginTop: 20,
        marginBottom: 20
    },
    influencerParent: {
        textAlign: 'center',
        border: '1px solid #0000001A',
        borderRadius: 3,
        background: '#fff',
        padding: 20,
        '@media (max-width:959px)': {
            height: 'auto'
        }
    },
    orderButton: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        fontSize: '20px',
        width: '213px',
        height: '70px',
        marginTop: 10,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    padding: {
        padding: '30px 210px 30px 210px',
        textAlign: 'center',
        lineHeight: 1.5
    },
    imgWidth: {
        marginTop: 30,
        width: 100
    },
    mailFeed: {
        color: '#00C9A8'
    },
    marginTop: {
        marginTop: 50,
        width: '100%'
    },
    maxWidth: {
        maxWidth: 1120
    },

    projectImg: {
        width: '150px',
    },

    paddingRight: {
        '@media (min-width:961px)': {
            paddingRight: 60
        }
    }
}));



export default function MainFeaturedPost(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Container classes={{ root: classes.maxWidth }}>
                <Grid container>
                    <Grid item xs={12} md={8} className={classes.paddingRight} >

                        <Typography variant="h3" classes={{ h3: classes.title }}>Our Mission </Typography>
                        <Typography className={classes.missionText} >Our goal is to send the message of the Holy Qur’an by distributing free Qur’ans to households, prisons, hotels, and various lobbies in different languages. Add verse: "And who is better in speech than one who invites to Allah and does righteousness and says, "Indeed, I am of the Muslims." (al Qur'an, 41:33) </Typography>

                        <Typography variant="h3" classes={{ h3: classes.title }}>Our Vision </Typography>
                        <Typography className={classes.missionText} > To spread the Book of Allah sponsored by our generous donors like you! For only $1 for one Qur’an, spreading the light of Allah and truth to those who one day will become our brothers and sisters in Islam.  </Typography>

                    </Grid>
                    <Grid item xs={12} md={4}  >
                        <img src={PersonReading} className={classes.marginTop} alt="person reading" />
                    </Grid>
                </Grid>
            </Container>

            <Box className={classes.mainBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Typography variant="h3" classes={{ h3: classes.title }}>Our Projects </Typography>
                            <Typography className={classes.text} >There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:</Typography>

                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box className={classes.influencerParent}>
                                <img className={classes.projectImg} src={QuranImg} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >AL-QURAN FOR ALL</Typography>
                                <Typography className={classes.influencerText}>Alquranforall.com is committed to directly connect you to God’s words.</Typography>

                                <Button variant="outlined" size="medium" className={classes.orderButton}>
                                    LEARN MORE
                    </Button>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box className={classes.influencerParent}>
                                <img className={classes.projectImg} src={QuranImg} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >PROJECT 2</Typography>
                                <Typography className={classes.influencerText}>Alquranforall.com is committed to directly connect you to God’s words.</Typography>

                                <Button variant="outlined" size="medium" className={classes.orderButton}>
                                    LEARN MORE
                    </Button>
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box className={classes.influencerParent}>
                                <img className={classes.projectImg} src={QuranImg} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >PROJECT 3</Typography>
                                <Typography className={classes.influencerText}>Alquranforall.com is committed to directly connect you to God’s words.</Typography>

                                <Button variant="outlined" size="medium" className={classes.orderButton}>
                                    LEARN MORE
                    </Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </React.Fragment>
    );
}
