import { createSlice } from '@reduxjs/toolkit'

const productBasePrice = 8 ; 
const initialState = {  
  orderInfo: {
    item:1, 
    itemName: 'Quran', 
    language: 1, 
    quantity: 1, 
    orderFor: 'forMyself', 
    orderForFirstName: '', 
    orderForLastName: '', 
    orderForCity: '', 
    orderForState: '', 
    orderForPostalCode: '', 
    orderForOrgName: '', 
    orderSubtotal: productBasePrice * 1 , 
    contributingAmount: 0,
  },
  requesterInfo: {
    firstName: '', 
    lastName: '', 
    email: '', 
    phone: '', 
    billingStreetAddress: '', 
    billingFloorNumber: '', 
    billingCity: '', 
    billingState: "NY", 
    billingPostalCode: '', 
    shippingAddressSameAsBilling: 1, 
    shipppingFirstName: '', 
    shippingLastName: '', 
    shippingEmail: '', 
    shippingCity: '', 
    shippingFloorNumber: '', 
    shippingState: 'NY', 
    shippingPostalCode: '', 
    shippingStreetAddress: '',
    shippingPhone: '', 
  }, 
  paymentInfo: {
    method: "card", 
    payLaterDate: "", 
    paymentDate: '', 
    amount: '', 
    transactionID: '', 
    streetAddress: '',
    floorNumber: '',
    sameRequester: 1,    
    city: '',
    state: 'NY',
    postalCode: '',
    cardNumber: '', 
    expiration: '', 
    securityCode: '', 
    nameOnCard: '', 
    phone: '', 
    billingAddressSameAsShipping: 1, 
  }, 
  checkoutInfo: {}
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {    
    setOrderinfo: (state, action) => {
      state.orderInfo = action.payload
    },
    setRequesterInfo: (state, action) => {
      state.requesterInfo = action.payload
    },
    setCheckoutInfoInfo: (state, action) => {
      state.checkoutInfo = action.payload
    },
    setPaymentInfo: (state, action) => {
      state.paymentInfo = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOrderinfo, setRequesterInfo, setCheckoutInfoInfo, setPaymentInfo } = counterSlice.actions

export default counterSlice.reducer