import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Box, Grid, Typography } from "@material-ui/core";
import QuranImg from "../../assets/quran.png";
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: 60,
    "@media (max-width:600px)": {
      paddingTop: 25,
      textAlign: "center",
    },
  },
  paddingTopInner: {
    paddingTop: 20,

    "@media (max-width:768px)": {
      padding: "20px 0px",
    },
  },


  title: {
    color: "#080F58",
    fontSize: 60,
    fontFamily: "Roboto Slab",
    marginBottom: 25,
    fontWeight: 400,
    lineHeight: 1,
  },
  subtitle: {
    display: "flex",
    borderBottom: "1px solid #eaeaea",
    paddingBottom: 10,
  },
  quranImg: {
    marginRight: 10,
    width: 80,
    height: 80,
  },
  alQuran: {
    marginTop: 12,
    textTransform: "uppercase",
    fontFamily: "Open Sans",
    color: "#080F58",
    fontWeight: 800,
    fontSize: "24px",
  },
  alQuranSubHeading: {
    color: "#666",
    fontSize: 12,
    fontFamily: "Open Sans",
    marginTop: 5,
    display: "block",
    lineHeight: 1.5,
  },
  quranCommited: {
    fontFamily: "Open Sans",
    color: "#00000099",
    fontSize: 24,
    lineHeight: 1.5,
    marginTop: 0,
  },
  width: {
    width: "87%",

    "@media (max-width:768px)": {
      width: "100%",
    },
  },


  learnMoreButton: {
    background: "#00C9A8",
    marginTop: 35,
    color: "white",
    border: 0,
    height: 70,
    width: 270,
    fontSize: 20,
    fontFamily: "Open Sans",
    fontWeight: 600,
    "&:hover": {
      background: "#00C9A8",
    },
    "@media (max-width:768px)": {
      display: "flex",
      margin: "20px auto",
      width: "100%",
    },
  },
  counterBox: {
    display: "inline-block",
    width: "49%",
    marginBottom: 30,

    "@media (max-width:768px)": {
      width: "100%",
      textAlign: "center",
    },
  },
  printed: {
    fontFamily: "Open Sans",
    fontSize: 20,
    color: "#00000099",
    fontWeight: 600,


    "@media (max-width:768px)": {
      fontSize: 14,
    },
  },
  counter: {
    fontSize: 60,
    marginTop: 5,
    color: "#080F58",
    fontFamily: "Roboto Slab",
    fontWeight: 400,

    "@media (max-width:768px)": {
      fontSize: 50,
    },
  },
  sponsoringQuran: {
    backgroundColor: "#4B0082",
    borderRadius: 10,
    marginTop: 100,
  },
  sponsoringRequired: {
    color: "#fff",
    fontFamily: "Roboto Slab",
    fontWeight: 400,
  },
  quranicQuote: {
    color: "#fff",
    fontFamily: "Open Sans",
    lineHeight: 1.5,
    fontSize: 14,
  },
  quranicQuotePadding: {
    paddingTop: 25,
    paddingRight: 50,
  },
  mokeUp: {
    position: "relative",
    bottom: 30,
    left: 9,
  },
  quranMockup: {
    width: "100%",
  },
  padding: {
    paddingTop: 35,
    paddingLeft: 20,
  },
  noItem: {
    background: "#fff",
    boxShadow: "0px 3px 10px #00000029",
    color: "#4B0082",
    fontFamily: "Roboto Slab",
    textAlign: "center",
    fontSize: 20,
    padding: 10,
    borderRadius: 3,
  },
  maxWidth: {
    maxWidth: 1120,
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Container classes={{ root: classes.maxWidth }}>
      <Grid container>
        <Grid item xs={12} className={classes.paddingTop}>
          <Typography variant="h2" classes={{ h2: classes.title }}>
            Our Projects
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.subtitle}>
          <img src={QuranImg} className={classes.quranImg} alt="Quran" />

          <Box>
            <Typography variant="h5" classes={{ h5: classes.alQuran }}>
              AL-QURAN for all
            </Typography>
            <Typography
              variant="caption"
              classes={{ caption: classes.alQuranSubHeading }}
            >
              A project of AL-QURAN DAWAH CENTER
            </Typography>
          </Box>
        </Grid>

        <Grid item md={7} xs={12} sm={12} className={classes.paddingTopInner}>
          <Box className={classes.width}>
            <Typography
              variant="caption"
              classes={{ caption: classes.quranCommited }}
            >
              AlQuranForAll.com is committed to directly connect you to the
              words of God to revive peace within you and around you. Order a
              free Qur'an today!
            </Typography>
          </Box>
          <Button
            variant="outlined"
            size="medium"
            className={classes.learnMoreButton}
            component={RouterLink} to="/about-us#projects"
          >
            LEARN MORE
          </Button>
        </Grid>

        <Grid item md={5} xs={12} sm={12} className={classes.paddingTopInner}>
          <Box className={classes.counterBox}>
            <Typography
              variant="caption"
              classes={{ caption: classes.printed }}
            >
              Qur'ans Printed
            </Typography>
            <Typography variant="h2" classes={{ h2: classes.counter }}>
              {/* 110,000 */}
              110K
            </Typography>
          </Box>

          <Box className={classes.counterBox}>
            <Typography
              variant="caption"
              classes={{ caption: classes.printed }}
            >
              Qur'ans Distributed
            </Typography>
            <Typography variant="h2" classes={{ h2: classes.counter }}>
              {/* 160,000 */}
              160K
            </Typography>
          </Box>

          <Box className={classes.counterBox}>
            <Typography
              variant="caption"
              classes={{ caption: classes.printed }}
            >
              Qur'an Sponsored
            </Typography>
            <Typography variant="h2" classes={{ h2: classes.counter }}>
              {/* 200,000 */}
              200K
            </Typography>
          </Box>

          <Box className={classes.counterBox}>
            <Typography
              variant="caption"
              classes={{ caption: classes.printed }}
            >
             US States
            </Typography>
            <Typography variant="h2" classes={{ h2: classes.counter }}>
              {/* 100,000 */}
              50 
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
