import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Button from '@material-ui/core/Button';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CircularProgress from '@material-ui/core/CircularProgress';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { PackageCard, SelectField, ButtonOne, TextInputFixedLabel } from "master-react-lib";

import { useSelector, useDispatch } from 'react-redux'
import { setCheckoutInfoInfo } from '../../features/counter/counterSlice'

import './OrderForm.css'

import DrawerMain from './Drawer'
import OrderSummary from './OrderSummary'

const cardFunction = (value) => {
    (value) && console.log("clicked");
};


const OrderDetais = () => {

    const cInfo = useSelector((state) => state.counter.checkoutInfo);
    const oInfo = useSelector((state) => state.counter.orderInfo);
    const dispatch = useDispatch();

    const onCloseClick = () => {
        console.log("close")
    }



    //==Drawer==//
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({
        age: "",
    });

    const closeDrawer = () => {
        setOpen(!open);
    };

    const onCancel = () => {
        setOpen(false);
    };
    

    return (
        <div className="OderDetailsCtn">
            <Grid container spacing={2}>

                <Grid item md={12} xs={12}>
                    <div className='OrderDtllst'>
                        <li>Order total: <span> ${oInfo.orderSubtotal}</span></li>
                        <li><a onClick={() => setOpen(true)}>Show Order Summary</a></li>
                    </div>

                    {/* <div className='OrderDtllst'>
                        <li>Order total: <span> ${oInfo.orderSubtotal}</span> <MonetizationOnIcon className='amountIcon' /></li>
                        <li><a >Enter Coupon Code <KeyboardArrowDownRoundedIcon /></a> </li> 
                    </div> */}
                </Grid>

                {/* <Grid item md={6} xs={12}>
                    <div className='CpnTitle'>Apply a coupon</div> }
                    <div className='CopnField'>
                        <TextInputFixedLabel
                            labelStatus={false}
                            placeholder="Enter coupon code"
                            Errormessage={<span><CancelRoundedIcon className='errorIcon' /> The promotion code you entered is invalid.</span>}
                            errortextStatus={false}
                        />

                        <div className='applyBtn'>
                            <Button variant="outlined" disabled>Apply</Button>
                            <Button className='cencelBtn' variant="outlined">Cancel</Button>
                            <div className='loader'><CircularProgress /></div>
                        </div>
                    </div>

                </Grid> */}

            </Grid>


            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                loading={false}
                title="Order Summary"
            >

               <di>
                <OrderSummary />

               </di>
            </DrawerMain>


        </div>

    )
}

export default OrderDetais;