import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Grid, Typography, Link } from '@material-ui/core';
import HandImg from '../../assets/hand.png';
import AboutbarImg from '../../assets/about-bar.png';
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    donateBox: {
        marginTop: 60
    },
    aboutTitle: {
        color: '#080F58',
        fontSize: 36,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        fontWeight: 400,
        lineHeight: 1,
        paddingTop: 40,
        '@media (max-width:510px) ': {
            fontSize: 24
        }
    },
    aboutText: {
        color: '#666',
        fontFamily: 'Open Sans',
        wordSpacing: 1.5,
        fontSize: 15,
        lineHeight: 1.7,
        textAlign: "justify",
        '@media (max-width:510px) ': {
            fontSize: 16
        }
    },
    AboutImg: {
        width: '70%',
        marginLeft: 100
    },
    AboutbarImg: {
        width: '100%',
        marginTop: 20
    },
    handContent: {
        background: '#00C9A8',
        padding: '10px 15px',
        textAlign: 'center',
        borderRadius: '4px 4px 0px 0px'
    },
    donateContent: {
        border: '2px solid #00C9A8',
        background: '#fff',
        padding: '15px 20px 20px',
        borderRadius: '0px 0px 4px 4px'
    },
    donateTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 24,
        margin: 0
    },
    donateButton: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        width: '100%',
        marginBottom: 0,
        marginTop: 15,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    donate: {
        color: '#666',
        fontSize: 14,
        fontFamily: 'Open Sans',
        lineHeight: 1.5
    },
    donateProjects: {
        display: 'flex',
        marginTop: 20,
        padding: '20px 0 0',
        borderTop: '1px solid #eaeaea'
    },
    donateLabel: {
        opacity: 0.2,
        background: '#080F58',
        height: 45,
        width: 45,
        borderRadius: 3
    },
    ourProject: {
        marginLeft: 10,
        paddingTop: 5,
        marginTop: 0
    },
    projectTitle: {
        margin: '0 0 5px',
        color: '#080F58',
        fontSize: 16,
        lineHeight: 1,
        fontFamily: 'Roboto Slab',
        fontWeight: 600
    },
    creative: {
        margin: 0,
        fontFamily: 'Open Sans',
        fontSize: 12,
        color: '#666'
    }
}));

export default function MainFeaturedPost(props) {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} >

                <Typography variant="h3" ref={props.aboutRef} classes={{ h3: classes.aboutTitle }}>About Al-Quran</Typography>
                <Typography className={classes.aboutText} >This is the Book (the Quran), whereof there is no doubt, a guidance to those who are Al-Muttaqoon (the pious and righteous persons who fear Allah much (abstain from all kinds of sins and evil deeds which He has forbidden) and love Allah much (perform all kinds of good deeds which He has ordained). Al-Quran 2:2</Typography>

                <div className='VideoContainer'>
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/WhIJX92Ovn0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

            </Grid>

            <Grid item xs={12} md={4} className={classes.donateBox} >

                <Box className={classes.handContent}>
                    <img src={HandImg} alt="hand-png" />
                </Box>
                <Box className={classes.donateContent}>
                    <h4 className={classes.donateTitle}>DONATE US</h4>
                    <p className={classes.donate}>Be a part of our awesome quest. Be part of the reward by sponsoring Al-Quran</p>
                    <Link
                        target="_blank"
                        underline="none"
                        rel="noopener noreferrer"
                        href={process.env.REACT_APP_DONATE_URL}
                    >
                        <Button variant="outlined" size="medium" className={classes.donateButton}>
                            DONATE US
                        </Button>
                    </Link>
                </Box>

                <Link component={RouterLink} to="/about-us#projects" underline="none">
                    <Box className={classes.donateProjects}>
                        <Box className={classes.donateLabel}>&nbsp;</Box>
                        <Box className={classes.ourProject}>
                            <Typography variant="h4" className={classes.projectTitle}>OUR PROJECTS</Typography>
                            <Typography variant="caption" className={classes.creative}>Building faithful communities</Typography>
                        </Box>

                    </Box>
                </Link>
                
                <Link component={RouterLink} to="/order" underline="none">
                    <Box className={classes.donateProjects}>
                        <Box className={classes.donateLabel}>&nbsp;</Box>
                        <Box className={classes.ourProject}>
                            <Typography variant="h4" className={classes.projectTitle} >ORDER FREE QURAN</Typography>
                            <Typography variant="caption" className={classes.creative}>AL-Quran for all</Typography>
                        </Box>

                    </Box>
                </Link>

                <Box className={classes.donateProjects}>   </Box>

            </Grid>
        </Grid>

    );
}
