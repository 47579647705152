import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container , Typography, Button, Grid, Box} from '@material-ui/core';
import  CheckCircle  from '../../assets/green-check.png';
import {ArrowForward  } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux'
import { setRequesterInfo } from '../../features/counter/counterSlice'

import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
thanksTitle : {
  fontSize: 40,
  fontFamily: 'Roboto Slab',
  color: '#080F58',
  margin :20,
  '@media (max-width:960px)' :{
    fontSize: 26
  },
  '@media (max-width:576px)' :{
    fontSize: 30,
    marginLeft: 0,
    marginRight: 0
  }
},
thanksText : {
  color: '#666',
  fontFamily: 'Open Sans',
  wordSpacing: 1.5,
  fontSize: 18,
  linHeight: 1.7,
  marginBottom : 30,
  padding: '0px 130px',
  display: 'block',
  '@media (max-width:960px)' :{
    fontSize: 14
  },
  '@media (max-width:576px)' : {
    fontsize: 18,
    padding: 0
  }
},
mailFeed :  {
	color: '#666'
},
waitText : {
  marginTop :30,
  fontSize: 20,
  color: '#080F58',
  fontFamily: 'Roboto Slab',
  fontWeight: 400,
  margin: 0
},
textCenter : {
  textAlign : 'center'
},
imgWidth : {
  marginTop : 30,
  width: 100
},
exploreParent : {
  border: '1px solid #0000001A',
  textAlign: 'left',
  padding : 20,
  paddingBottom : 40,
  maxHeight :155,
  minHeight :155,
},
exploreTitle : {
  color: '#080F58',
  fontFamily: 'Roboto Slab',
  fontSize: 25,
  fontWeight: 400,
  marginBottom :20
},
exploreText : {
  color: '#00000080',
  fontFamily: 'Open Sans',
  fontSize: 12,
  lineHeight: 1.5
},
exploreButton : {
  width: '100%',
  borderRadius: 0,
  marginBottom: 0,
  height: 50,
  background: '#00C9A8',
  '&:hover': {
    background: '#00C9A8' 
  }
},
buttonLable: {
  justifyContent: 'space-between',
	position: 'relative',
  width : '100%',
  color : 'white'
},
arrow :{
  float: 'right'
},
maxWidth :{
  maxWidth: 1120
}
}));

export default function MainFeaturedPost(props) {

  const rInfo = useSelector((state) => state.counter.requesterInfo)
  const dispatch = useDispatch()

  const classes = useStyles();
  return (
    <Container classes={{ root : classes.maxWidth }}>
        <Grid container spacing={3}>
            <Grid item xs={12} className={ classes.textCenter} >
                <img src={CheckCircle} className={ classes.imgWidth} alt="influencer-img" />
                <Typography variant="h4" className={ classes.thanksTitle  }>Thank you for your order.</Typography>
                <Typography variant="caption" className={ classes.thanksText }>We’ve received your order. You will also receive an email confirmation shortly at <a href={ "mailto:" + rInfo.email } className={ classes.mailFeed}>{rInfo.email}</a></Typography>
                
                <Typography variant="h4" className={ classes.waitText }>While you wait, consider looking over the following…</Typography>
                
            </Grid>

            <Grid item xs={12} sm={6} md={3} > 
                  <Box className={ classes.exploreParent } >
                    <Typography className={ classes.exploreTitle}>Inside the Al-Quran</Typography>
                    <Typography variant="caption" className={ classes.exploreText }>Dive in and reflect with the direct words from the Almighty and Everlasting God.</Typography>
                  
                  </Box>

                  <Button variant="filled" size="medium" classes={{ label : classes.buttonLable , root : classes.exploreButton }} component={RouterLink} to="/article-details">
                    LEARN MORE

                    <ArrowForward  className={classes.arrow} />
                  </Button>

            </Grid>

            <Grid item xs={12} sm={6} md={3} > 
                  <Box className={ classes.exploreParent } >
                    <Typography className={ classes.exploreTitle}>Facts about Al-Quran</Typography>
                    <Typography variant="caption" className={ classes.exploreText }>Read more about awesome and inspirational facts about Al-Quran and Islam.</Typography>
                  
                  </Box>

                  <Button variant="filled" size="medium" classes={{ label : classes.buttonLable , root : classes.exploreButton }} component={RouterLink} to="/#quranic-facts">
                    LEARN MORE

                    <ArrowForward  className={classes.arrow} />
                  </Button>

            </Grid>

            <Grid item xs={12} sm={6} md={3} > 
                  <Box className={ classes.exploreParent } >
                    <Typography className={ classes.exploreTitle}>FAQs</Typography>
                    <Typography variant="caption" className={ classes.exploreText }>Our amazing team is very approachable and would love to help you with your queries.</Typography>
                  
                  </Box>

                  <Button variant="filled" size="medium" classes={{ label : classes.buttonLable , root : classes.exploreButton }} component={RouterLink} to="/faq">
                    LEARN MORE

                    <ArrowForward  className={classes.arrow} />
                  </Button>

            </Grid>

            <Grid item xs={12} sm={6} md={3} > 
                  <Box className={ classes.exploreParent } >
                    <Typography className={ classes.exploreTitle}>Our Projects</Typography>
                    <Typography variant="caption" className={ classes.exploreText }>Our amazing team is very approachable and would love to help you with your queries.</Typography>
                  
                  </Box>

                  <Button variant="filled" size="medium" classes={{ label : classes.buttonLable , root : classes.exploreButton }} component={RouterLink} to="/#our-projects">
                    LEARN MORE
                    <ArrowForward  className={classes.arrow} />
                  </Button>

            </Grid>

        </Grid>
    </Container>
  );
}
