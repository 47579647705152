import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, Container, Tabs, Tab, Box } from "@material-ui/core";
import Policy from "./PrivacyPolicy";
import Services from "./Services";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            component={"div"}
            role={"tabpanel"}
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabBar: {
        backgroundColor: "#20C9A8"
    },
    bigIndicator: {

        display: 'none'
    },
    selected: {
        background: '#23ECCB',
        opacity: 1,
    },

}));

export default function FullWidthTabs() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //   const handleChangeIndex = (index) => {
    //     setValue(index);
    //   };

    return (
        <Box component="div" className={classes.root}>
            <AppBar position="static" className={classes.tabBar}>
                <Container maxWidth="md">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="default"
                        aria-label="full width tabs example"
                        classes={{ indicator: classes.bigIndicator }}
                    >
                        <Tab label="TERMS OF SERVICES" {...a11yProps(0)} className={value === 0 && classes.selected} />
                        <Tab label="PRIVACY POLICY" {...a11yProps(1)} className={value === 1 && classes.selected} />
                    </Tabs>
                </Container>
            </AppBar>

            <TabPanel value={value} index={0} dir={theme.direction}>
                <Services />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <Policy />
            </TabPanel>
        </Box>
    );
}
