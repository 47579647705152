import axios from "axios";

export function getArrayValue (baseArray, selectedItem){
    let selectedValue = '' ; 

    baseArray.map(element=>{
        if( element.value == selectedItem ){
            selectedValue = element.select ; 
            return selectedValue ; 
        }
    });

    // console.log( selectedValue );

    return selectedValue ; 
}

export function makeOrder(postData, handlePaymentResponse) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_BACKEND_API_URL + '/orgOrder', postData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error.response)
          handlePaymentResponse( error.response )
        });
    });
}

export default getArrayValue;