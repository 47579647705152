import React, { Fragment, useEffect } from 'react'
import Header from '../common/head';
import Footer from '../common/Footer';
import TabMenu from './TabMenu';
import Banner from './Banner';



export default function Index() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });
    return (
        <Fragment>
            <Header />
            <Banner title="Terms and Policies" />
            <TabMenu />
            <Footer />
        </Fragment>
    )
}
