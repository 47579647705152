import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BannerImage from '../../assets/blue_pattren.png'



const useStyles = makeStyles((theme) => ({
bannerImg : {
    backgroundImage: `url(${BannerImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    color: '#fff',
    paddingBottom: 70
},
queryText : {
  margin: '0 auto',
  fontFamily: 'Roboto Slab, serif',
  color: '#fff',
  fontSize: 48,
  fontWeight : 600,
  paddingBottom: 15,
  marginTop: 15,
  '@media (max-width:510px)' : {
    fontSize: 24
  }
},
title :{
  color: '#fff',
	fontFamily: 'Roboto Slab',
  fontSize: 16,
	display: 'block',
	lineHeight: 1.5,
  paddingTop: 25,
  fontWeight: 600
},
plainText :{
  fontSize : 16,
  '@media (min-width:961px)' :{
    fontSize: 26
  }
},
button : {
  background: '#fec553',
  marginTop: 15,
  color: 'white',
  '&:hover': {
    background: 'white'
}
},
arrowBox : {
  textAlign: 'right'
},
arrow :{
  border: '1px solid',
  borderRadius: 35,
  margin: '5px',
  color: '#fff'
},
textCenter : {
  textAlign : 'center'
},
maxWidth :{
  maxWidth: 1120
}
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Grid Container className={classes.bannerImg}> 
    <Container classes={{ root : classes.maxWidth }}>
      <Grid item xs={12}  className={ classes.textCenter}  >
      
          <Typography variant="caption" className={classes.title}>KNOWLEDGE SEEKERS</Typography>
          <Typography variant="h1" className={ classes.queryText }>FREQUENTLY ANSWERED QUESTIONS</Typography>
          <Typography variant="caption" className={classes.plainText}>Got queries? We’ve got answers. Here are few to start with.</Typography>
      </Grid>
      </Container>

    
  </Grid>
  );
}
