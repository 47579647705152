import React, { createRef, useState, useEffect } from 'react'
import Header from '../common/head';
import Banner from './Banner';
import Steper from './Steper';



function OrderForm() {

    const orderRef = createRef();
    const aboutRef = createRef();
    
    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf("/order") > -1) {
                orderRef && orderRef.current.scrollIntoView({ behavior: "smooth" });

            } 
            else if (window.location.href.indexOf("/#aboutUs") > -1) {
                aboutRef && aboutRef.current.scrollIntoView({ behavior: "smooth" });

            }
            else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
            }
        }, 1000);

    }, [orderRef, aboutRef ]);


    return (
        <div >
            <Header />
            <Banner />
            <div ref={orderRef}>
                <Steper />
            </div>
        </div>
    );
}


export default OrderForm;