import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Box, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import PanelContent from './PanelContent';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    rootBox: {
        background: '#20C9A8',
        width: '100%',
        color: '#fff',
        '@media (max-width:768px)': {
            padding:"20px 0"
        }
    },
    mainBox: {
        background: '#fafbfe'
    },
    selected: {
        background: '#23ECCB',
        opacity: 1
    },
    indicator: {
        display: 'none'
    },
    Link: {
        textDecoration: "none",
        color: "inherit",
    },
    scroller: {
        '@media (max-width:840px)': {
            whiteSpace: 'normal'
        }
    },
    flexContainer: {
        '@media (max-width:840px)': {
            display: 'block'
        }
    },
    maxWidth: {
        maxWidth: 1120
    },
    paddingZero: {
        '@media (min-width:961px)': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    donateNowTab: {
        color: "#fff"
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


export default function MainFeaturedPost(props) {
    const { tab, aboutRef, handleChange } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className={classes.rootBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container>
                        <Grid item xs={12}>

                            <Tabs classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer, scroller: classes.scroller }} value={tab} onChange={handleChange} aria-label="simple tabs example">
                                <RouterLink to="/al-quran-for-all#about" className={classes.Link}>
                                    <Tab label="ABOUT AL-QURAN" {...a11yProps(0)} className={tab === 0 && classes.selected} />
                                </RouterLink>
                                <RouterLink to="/al-quran-for-all#inside" className={classes.Link}>
                                    <Tab label="WHAT'S INSIDE" {...a11yProps(1)} className={tab === 1 && classes.selected} />
                                </RouterLink>
                                <RouterLink to="/al-quran-for-all#words-in-action" className={classes.Link}>
                                    <Tab label="WORDS IN ACTION" {...a11yProps(2)} className={tab === 2 && classes.selected} />
                                </RouterLink>
                                <RouterLink to="/al-quran-for-all#how-it-works" className={classes.Link}>
                                    <Tab label="HOW IT WORKS" {...a11yProps(3)} className={tab === 3 && classes.selected} />
                                </RouterLink>

                                <Tab />
                                <Link
                                    target="_blank"
                                    underline="none"
                                    rel="noopener noreferrer"
                                    href={process.env.REACT_APP_DONATE_URL}
                                    className={classes.donateNowTab}
                                >
                                    <Tab label="DONATE US" {...a11yProps(4)} classes={{ root: classes.selected, selected: classes.selected }} />
                                </Link>
                            </Tabs>
                        </Grid>
                    </Grid>

                </Container>
            </Box>
            <Box className={classes.mainBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container >
                        <Grid item xs={12} >
                            <PanelContent aboutRef={aboutRef} />
                        </Grid>
                    </Grid>

                </Container>
            </Box>
        </React.Fragment>
    );
}

function TabPanel(props) {
    const { classes, children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box className={classes.paddingZero} p={3}>{children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};