import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PackageCard, SelectFieldOne, ButtonOne, TextInputFixedLabel } from "master-react-lib";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { getArrayValue } from './functions';
import Alert from '@material-ui/lab/Alert';
import ModalDelete from './ModalDelete'
import ModalOrder from './ModalOrder'

import { useSelector, useDispatch } from 'react-redux'
import { setOrderinfo } from '../../features/counter/counterSlice'

import './OrderForm.css'


function OrderInfo(props) {

    const orderInfo = useSelector((state) => state.counter.orderInfo)
    const dispatch = useDispatch()

    const productBasePrice = 8 ; 


    const [isOrderForOrg, setIsorderForOrg] = useState(false);
    const [isOrderForFriend, setIsOrderForFriend] = useState( false );
    const cardFunction = (orderFor) => (event ) => {
        
        setSelectedValues({
            ...selectedValues, 
            ['orderFor']: orderFor
        });
        if( orderFor =='forOrg'){
            setIsorderForOrg( true ); 
            setIsOrderForFriend( false ); 
        }else if(orderFor =='forAfriend'){
            setIsorderForOrg( false );
            setIsOrderForFriend( true ); 
        }else{
            setIsorderForOrg( false );
            setIsOrderForFriend( false ); 
        }

        dispatch( setOrderinfo( selectedValues) );
    };

    
    const [selectedValues, setSelectedValues ] = useState({
        'item': orderInfo.item , 
        'itemName': orderInfo.itemName, 
        'language': orderInfo.language, 
        'quantity': orderInfo.quantity, 
        'orderFor': orderInfo.orderFor, 
        'orderForFirstName': orderInfo.orderForFirstName, 
        'orderForLastName': orderInfo.orderForLastName, 
        'orderForCity': orderInfo.orderForCity, 
        'orderForState': orderInfo.orderForState, 
        'orderForPostalCode': orderInfo.orderForPostalCode, 
        'orderForOrgName': orderInfo.orderForOrgName, 
        'orderSubtotal': orderInfo.orderSubtotal , 
        'contributingAmount': orderInfo.contributingAmount, 
    });



    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openorderModal, setOpenorderModal] = React.useState(false);
    const handleOpenorderModal = () => setOpenorderModal(true);
    const handleCloseorderModal = () => setOpenorderModal(false);


    

    //=== Select field value ==//
    const selecItem = [
        { value: 1, select: "Quran" },
        { value: 2, select: "Books" },
        { value: 3, select: "Brochures" },
    ];

    const selectlanguage = [
        { value: 1, select: "English" },
        { value: 2, select: "Bangla" },
        { value: 3, select: "Chinese" },
        { value: 4, select: "Spanish" },
    ];

    const selectState = [
        { value: 1, select: "New York" },
        { value: 2, select: "California" },
        { value: 3, select: "NY" },
    ];

    const handleChange = ( prop ) => (event) => {

        if( prop =='quantity'){
            let amount = productBasePrice * parseInt( event.target.value ); 
            console.log( event.target.value );
            setSelectedValues( {
                ...selectedValues, 
                [prop]: event.target.value, 
                ['orderSubtotal']: amount , 

            });
            dispatch( setOrderinfo( {
                ...selectedValues, 
                [prop]: event.target.value,
                ['orderSubtotal']: amount , 
            }) );

        }else if( prop =='item'){

            let name = getArrayValue( selecItem, event.target.value); 
            setSelectedValues( {
                ...selectedValues, 
                [prop]: event.target.value, 
                ['itemName']: name , 

            });
            dispatch( setOrderinfo( {
                ...selectedValues, 
                [prop]: event.target.value, 
                ['itemName']: name , 
            }) );

        }else{
            setSelectedValues( {
                ...selectedValues, 
                [prop]: event.target.value
            }); 
            dispatch( setOrderinfo( {
                ...selectedValues, 
                [prop]: event.target.value
            }) );
        }

      
        // dispatch( setOrderinfo( selectedValues) );

        // console.log( "orderinfo: ",  orderInfo );
        
    };
    
    const [showSuccessMessage, setShowSuccessMessage] = useState( false );

    
    return (

        <div className="FormContainer">

            <div className="AccordionContianer">

            {showSuccessMessage && (<div className='AlertCtnSuccess'><Alert className='successAlert' onClose={() => {setShowSuccessMessage(false)}}>Order total adjusted</Alert></div>)}

                <Accordion defaultExpanded>

                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <div className="aAccordionTitle" >Order 1</div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={2}>

                        <Grid item md={12} sm={12} xs={12}>
                            <h3 className='formSectionTitleIn'>What are you ordering?</h3>
                        </Grid>


                            <Grid item md={12} sm={12} xs={12}>
                                <SelectFieldOne
                                    label="Select item"
                                    selectdata={selecItem}
                                    defaultValue={selectedValues.item }
                                    value={selectedValues.item }
                                    handleChange={handleChange('item')}
                                    //error="error"
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12}>
                                <SelectFieldOne
                                    label="Select language"
                                    selectdata={selectlanguage}
                                    defaultValue={selectedValues.language }
                                    value={selectedValues.language }
                                    handleChange={handleChange('language')}
                                    //error="error"
                                />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12}>
                                <label class="input_label">Quantity</label>
                                <input className='QuantityField' value={selectedValues.quantity} onChange={handleChange('quantity')} type="number" id="quantity" name="quantity" min="1" max="5" />
                                <div className='subtotalCont'>
                                    <p>Subtotal</p>
                                    <p>${orderInfo.orderSubtotal}</p>
                                </div>
                                <Button className='TextBtnBlue' onClick={handleOpenorderModal}>Get your order for free</Button>
                            </Grid>

                            {/* <Grid item md={12} sm={12} xs={12}>
                                <div className='secDevider'></div>
                            </Grid> */}

                            <Grid item md={4} sm={4} xs={4}>
                                <div className='packageCardLarge hidden'>
                                    <PackageCard
                                        cardFunction={cardFunction('forMyself')}
                                        dayText="For Myself"
                                        isTickVisible={true}
                                    />
                                </div>
                            </Grid>

                            <Grid item md={4} sm={4} xs={4}>
                                <div className='packageCardLarge hidden'>
                                    <PackageCard
                                        cardFunction={cardFunction('forAfriend')}
                                        dayText="For A Friend"
                                    />
                                </div>
                            </Grid>

                            <Grid item md={4} sm={4} xs={4}>
                                <div className='packageCardLarge hidden'>
                                    <PackageCard
                                        cardFunction={cardFunction('forOrg')}
                                        dayText="For An Organization/Company"
                                    />
                                </div>
                            </Grid>

                            <Grid container spacing={2} style={{ paddingTop: "40px !Important" }}>
                                
                                {isOrderForFriend && (
                                    
                                        <Grid item xs={12} md={8}>
                                            <div className="field_box">
                                                <TextInputFixedLabel
                                                    label="First name"
                                                    labelStatus={true}
                                                    value={selectedValues.orderForFirstName}
                                                    onChange={handleChange('orderForFirstName')}
                                                />
                                            </div>
                                        </Grid>                                       
                                    
                                )}

                                {isOrderForFriend && (                        
                                    
                                    <Grid item xs={12} md={4}>
                                        <div className="field_box">
                                            <TextInputFixedLabel
                                                label="Last name"
                                                labelStatus={true}
                                                value={selectedValues.orderForLastName}
                                                onChange={handleChange('orderForLastName')}
                                            />
                                        </div>
                                    </Grid>                                
                                )}

                                {isOrderForOrg && (
                                    <Grid item xs={12} md={12}>
                                        <div className="field_box">
                                            <TextInputFixedLabel
                                                label="Organization name"
                                                labelStatus={true}
                                                value={selectedValues.orderForOrgName}
                                                onChange={handleChange('orderForOrgName')}
                                            />
                                        </div>
                                    </Grid>
                                )}
                                
                                { (isOrderForFriend || isOrderForOrg ) && (
                                    <Grid item xs={12} md={4}>
                                        <div className="field_box">
                                            <TextInputFixedLabel
                                                label="City/Town"
                                                labelStatus={true}
                                                value={selectedValues.orderForCity}
                                                onChange={handleChange('orderForCity')}
                                            />
                                        </div>
                                    </Grid>
                                )}                                
                                { (isOrderForFriend || isOrderForOrg ) && (
                                <Grid item xs={12} md={4}>
                                    <div className="field_box">

                                        <SelectFieldOne
                                            label="State/Province"
                                            selectdata={selectState}
                                            defaultValue={1}
                                            value={selectedValues.orderForState}
                                            handleChange={handleChange('orderForState')}
                                            error="error"
                                        />
                                    </div>
                                </Grid>
                                )}
                                { (isOrderForFriend || isOrderForOrg ) && (
                                <Grid item xs={12} md={4}>
                                    <div className="field_box">
                                        <TextInputFixedLabel
                                            label="Zip/Postal code"
                                            labelStatus={true}
                                            value={selectedValues.orderForPostalCode}
                                            onChange={handleChange('orderForPostalCode')}
                                        />
                                    </div>
                                </Grid>
                                )}
                            </Grid>


                        </Grid>
                    </AccordionDetails>

                </Accordion>

                {/* <Accordion defaultExpanded> */}

                    {/* <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <div className="aAccordionTitle" ><h3>Order 1 </h3>
                            <a className='atnDelete' onClick={handleOpen}>
                                Remove
                            </a>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <h3 className='formSectionTitleIn'>Who are you ordering for?</h3>
                        </Grid>
                            <Grid item xs={12} md={12}>
                                <div className='FormDetailsContainer'>
                                    <div className='FormDetailsContainerHdr'>
                                        <div className='FormDetailsContainerDtl'>
                                            <h3>{selectedValues.itemName }</h3>
                                            <h2>Language: {getArrayValue( selectlanguage,  selectedValues.language) }</h2>
                                            <p>Quantity: {selectedValues.quantity}</p>
                                            <p>Subtotal: {selectedValues.orderSubtotal} </p>
                                            <p>Contributing: ${selectedValues.contributingAmount}</p>
                                        </div>
                                        <div className='AtdRightCol'>
                                            <Button href="#text-buttons" className='atnEdit'>
                                                Edit
                                            </Button>

                                        </div>
                                    </div>

                                </div>
                            </Grid>

                        </Grid>
                    </AccordionDetails> */}

                {/* </Accordion> */}
                {/* <div className='AddmoreBtn'>
                    <ButtonOne
                        label="ADD MORE ORDER"
                        onClick={click}
                        addIcon={true}
                        AddIconColor="#0590FA"
                        buttoncolor="#fff"
                        width="100%"
                        height="50px"
                        border="#0590FA 1px solid"
                        borderRadius="4px"
                        color="#0590FA"
                        fontWeight="600"
                        textAlign="center"

                    />
                </div> */}

            </div>


            <ModalDelete
                open={open}
                handleClose={handleClose}

            />

            <ModalOrder
                open={openorderModal}
                handleClose={handleCloseorderModal}
                orderTotal={selectedValues.orderSubtotal}
                setShowSuccessMessage={setShowSuccessMessage}

            />


        </div >
    );
}


export default OrderInfo;


