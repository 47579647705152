import React from 'react'
import Header from '../common/head';
import Footer from '../common/Footer';
import Social from '../common/Social';
import Body from '../articleDetails/Body.js';


export default function Index() {
    return (
        <React.Fragment>
            <Header />
            <Body />
            <Social />
            <Footer />
        </React.Fragment>
    )
}
