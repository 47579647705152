import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, List, Link, ListItem, Typography } from '@material-ui/core';
import QuranImg from '../../assets/quran.png'
import { Twitter, YouTube } from '@material-ui/icons';
import Dee from '../../assets/Rectangle.png'
import Facebook from '../../assets/facebook.png'
import { Link as RouterLink } from 'react-router-dom';
import { FooterBottomOne } from "master-react-lib";


const useStyles = makeStyles((theme) => ({
    FooterMainBox: {
        background: '#fbfafd',
        borderBottom: '1px solid #0000001A',
        padding: '100px 25px',
        paddingBottom: '40px',
        '@media (max-width:500px)': {
            padding: '30px 0px'
        }
    },
    FooterMainBoxBottom: {
        background: '#fbfafd',
        borderBottom: '1px solid #0000001A',
        padding: '40px 25px',
    },
    quranContent: {
        display: 'flex',
        '@media (max-width:330px)': {

        }
    },
    quranImg: {
        marginRight: 10,
        width: 60,
        height: 60
    },
    alQuranTitle: {
        fontFamily: 'Open Sans',
        color: '#080F58',
        fontWeight: 700,
        fontSize: 20,
        textTransform: "uppercase",
    },
    alQuranSubHeading: {
        color: '#666',
        fontSize: 12,
        fontFamily: 'Open Sans',
        marginTop: 5,
        display: 'block',
        lineHeight: 1.5
    },
    alQuranContent: {
        color: '#666',
        fontFamily: 'Open Sans',
        fontSize: 18,
        lineHeight: 1.5,
        marginTop: 30,

        '@media (max-width:768px)': {
            marginTop: 0,
            padding:"20px",
        }
    },
    footerTitle: {
        color: '#080F58',
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontSize: 20,
        textTransform: "uppercase",
        '@media (max-width:370px)': {
            paddingLeft: 0
        }
    },
    titleQuicklinks: {

    },
    titleProjects: {
        paddingRight: 30
    },
    listRoot: {
        paddingLeft: 0,
        listStyle: 'none',
        fontFamily: 'Open Sans',
        fontSize: 14,
        color: '#666',
        marginTop: '30px',
    },
    contentCenter: {
        justifyContent: 'center',
        display: 'flex'
    },
    listItemRoot: {
        paddingBottom: 15
    },
    ListItem: {
        fontFamily: 'Open Sans',
        cursor: 'pointer',
        fontSize: 18,
        color: '#00000099',
        '@media (max-width:370px)': {
            fontSize: 14
        }
    },
    subtitle: {
        display: 'flex',
        borderBottom: '1px solid #eaeaea',
        paddingBottom: 10
    },
    imgContainer: {
        width: 45,
        height: 45,
        margin: '0 auto',
        borderRadius: 70
    },
    imgtag: {
        position: 'relative',
        top: 9,
        color: '#fff',
        cursor: 'pointer'
    },
    facebook: {
        background: '#4267B2',
        marginTop: 15
    },
    socialLink: {
        verticalAlign: 'middle',
        width: 32,
        height: 32,
        margin: 5,
        fill: '#00000099',
        border: '1px solid gray',
        borderRadius: 39,
        padding: 5
    },
    facebookImg: {
        background: '#666',
        padding: '10px 15px',
        borderRadius: 39,
        position: 'relative',
        top: 17
    },
    poweredByText: {
        color: '#4C5E67',
        fontFamily: 'Roboto',
        fontSize: 12,
        lineHeight: 1.5,
        '@media (max-width: 939px)': {
            textAlign: 'center'
        }
    },
    marginTop: {
        marginTop: 0
    },
    textCenter: {
        textAlign: 'center'
    },
    deeImg: {
        width: 110,
        top: 3,
        position: 'relative'
    },
    paddingLeft: {
        '@media (max-width:370px)': {
            paddingLeft: 0
        }
    },
    alignRight: {
        textAlign: 'right'
    },
    maxWidth: {
        maxWidth: 1120
    },
    paddingRight: {
        '@media (min-width:1161px)': {
            paddingRight: 250,
        }
    }
}));

export default function MainFeaturedPost(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className='FooterContainer'>
                <Box className={classes.FooterMainBox}>
                    <Container classes={{ root: classes.maxWidth }}>
                        <Grid container>
                            <Grid container item md={4} xs={12} sm={6} className={classes.marginTop}>
                                <Grid item xs={12}  >
                                    <Box className={classes.quranContent}>
                                        <img src={QuranImg} className={classes.quranImg} alt="Quran" />
                                        <Box >
                                            <Typography variant="h6" classes={{ h6: classes.alQuranTitle }} >AL-QURAN for all</Typography>
                                            <Typography variant="caption" classes={{ caption: classes.alQuranSubHeading }} >A project of AL-QURAN DAWAH CENTER</Typography>
                                        </Box>
                                    </Box>

                                    <Typography className={classes.alQuranContent}>Find answers and guide your heart with the direct words from the Almighty and Everlasting God.</Typography>

                                </Grid>
                            </Grid>


                            <Grid container item md={2} xs={6} className={`${classes.marginTop} ${classes.textCenter}`} >
                                <Grid item xs={12} >
                                    <Typography variant="caption" className={`${classes.footerTitle} ${classes.titleQuicklinks}`} >Quick links</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.contentCenter}>
                                    <List classes={{ root: classes.listRoot }} >
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/">Home </Link>
                                        </ListItem>
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/about-us" >About Us </Link>
                                        </ListItem>
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} href={process.env.REACT_APP_DONATE_URL}
                                                target="_blank"
                                                rel="noopener">Support Us
                                            </Link>
                                        </ListItem>
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/faq" >FAQs </Link>
                                        </ListItem>
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/about-us#contact-us" >Contact Us </Link>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>

                            <Grid container item md={3} xs={6} className={`${classes.marginTop} ${classes.textCenter}`} >
                                <Grid item xs={12} >
                                    <Typography variant="caption" className={`${classes.footerTitle} ${classes.titleProjects}`} >PROJECT LIST</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.contentCenter}>
                                    <List classes={{ root: classes.listRoot }} >
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/al-quran-for-all">AlQuranForAll.com</Link>
                                        </ListItem>
                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} href="https://www.facebook.com/AlQuranDawahCenter/" target="_blank">See Events</Link>
                                        </ListItem>

                                        <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                            <Link variant="body2" className={classes.ListItem} href={process.env.REACT_APP_DONATE_URL}
                                                target="_blank"
                                                rel="noopener">Make Donation
                                            </Link>
                                        </ListItem>
                                        {/* <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                        <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/">How it works for org</Link>
                                    </ListItem> */}

                                        {/* <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                        <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/">Add Organization</Link>
                                    </ListItem>
                                    <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                        <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/">Org Secured Login</Link>
                                    </ListItem>
                                    <ListItem classes={{ root: classes.listItemRoot, gutters: classes.paddingLeft }}>
                                        <Link variant="body2" className={classes.ListItem} component={RouterLink} to="/">Make donations </Link>
                                    </ListItem> */}
                                    </List>
                                </Grid>
                            </Grid>

                            <Grid item md={3} xs={12} sm={6} className={`${classes.marginTop}  ${classes.textCenter}`}>
                                <Grid item xs={12} >
                                    <Typography variant="caption" className={classes.footerTitle} >FOLLOW US</Typography>
                                </Grid>
                                <Grid item md={12}>
                                    <Link target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/AlQuranDawahCenter/"><img src={Facebook} alt="facebook" className={classes.facebookImg} /></Link>
                                    {/* <Link target="_blank" rel="noopener noreferrer" href="https://twitter.com"><Twitter className={classes.socialLink} /></Link>
                                <Link target="_blank" rel="noopener noreferrer" href="https://youtube.com"><YouTube className={classes.socialLink} /></Link> */}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Container>
                </Box>
            </div>

            <FooterBottomOne
                bordercolor="#7266FB"
                Container="1170px"

                //target="_blank"

                TermsLink="/terms-and-policies"
                PrivacyLink="/terms-and-policies"

            />


        </React.Fragment>
    );
}
