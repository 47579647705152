import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Toolbar, MenuList, MenuItem, ListItem, Typography, Link, List, Grid, Button, ClickAwayListener, Grow, Popper, Paper, IconButton, AppBar, Box } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import Logo from '../../assets/logo.png';
import { Menu, KeyboardArrowDown } from '@material-ui/icons';
import { ReactComponent as Facebook } from '../../assets/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/mytwitter.svg';
import { ReactComponent as YouTube } from '../../assets/youtube.svg';
import { Link as RouterLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import QuranImg from '../../assets/quran.png';

import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import './style.css'


const styles = theme => ({
    emailText: {
        textAlign: 'left',
        fontFamily: 'Roboto',
        letterSpacing: 0.56,
        color: '#00000099',
        opacity: 1,
        fontSize: 14,
        textDecoration: 'none'
    },
    emailImg: {
        verticalAlign: 'middle',
        width: 20,
        height: 20,
        padding: '0px 5px',
        fill: '#666666'
    },
    textRight: {
        textAlign: 'right'
    },
    topBar: {
        borderBottom: '1px solid #0000001A',
        padding: '8px 0px'
    },
    listStyle: {
        listStyle: 'none',
        fontFamily: 'Roboto',
        display: 'inline',
        '@media (max-width: 1199px)': {
            display: 'none'
        }
    },
    menuList: {
        height: 10,
        lineHeight: 10,
        textAlign: 'right'
    },
    menuItems: {
        display: 'inline',
        padding: '0 15px',
        fontSize: 14
    },
    MenuLinks: {
        color: '#666666',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: "16px",
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    myStyle: {
        height: 'auto',
        lineHeight: '80px',
        textAlign: 'right',
        display: 'inline',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
        },
        '@media (max-width:361px)': {
            lineHeight: 4
        },
    },
    donateButton: {
        borderColor: '#00C9A8',
        borderRadius: 4,
        color: '#666666',
        fontSize: "16px",
        height: "45px",
        fontWeight: 500,
        '&:hover': {
            background: 'white'
        },

        '@media (max-width:768px)': {
            marginBottom: 20,
            width: "100%",
            display:"flex",
            margin: "0 auto",
            height: "56px",
        }
    },
    donateButtonLink: {
        color: '#00C9A8'
    },
    donateText: {
        color: '#666666',
        fontSize: 12,
        fontFamily: 'Roboto'
    },
    orderButton: {
        color: 'white',
        fontSize: "16px",
        background: '#00C9A8',
        borderRadius: 4,
        border: 0,
        marginLeft: 20,
        height: "45px",
        fontWeight: 500,
        '&:hover': {
            background: '#00C9A8'
        },
        '@media (max-width:768px)': {
            marginBottom: 20,
            width: "100%",
            display:"flex",
            margin: "0 auto",
            height: "56px",
        }
    },
    AppBarRoot: {
        flexGrow: 1,
        '@media (min-width: 1200px)': {
            display: "none"
        },
        [theme.breakpoints.down("lg")]: {
            position: 'fixed',
            width: '100%',
            top: 0,
            zIndex: 99999999999999999
        }
    },
    background: {
        backgroundColor: '#4B0082'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    menucontent: {
        background: '#4B0082',
        color: '#fff',
        marginTop: 0
    },

    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        borderBottom: '1px solid #f9f9f94a',
        padding: '10px 15px',
        color: '#fff',
        textDecoration: 'none',
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'none !important'
        }
    },
    menuSubList: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        color: '#fff',
        textDecoration: 'none',
        fontWeight: 500,
        marginTop: 0,
        '&:hover': {
            textDecoration: 'none !important'
        }
    },
    padding: {
        paddingTop: 3,
        padding: '0px 30px',
        '@media (max-width: 1199px)': {
            marginTop: 55
        },
        '@media (max-width:550px)': {
            padding: '0px 20px'
        },
        '@media (min-width:1280px)': {
            padding: '0px 100px'
        }
    },
    logoImg: {
        width: '320px',

        '@media (max-width:1500px)': {
            width: '230px',
        }
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    dropdown: {
        position: 'absolute',
        willChange: 'transform',
        display: 'grid',
        right: 410,
        width: 200,
        top: '10px !important',
        left: 'unset',
        transform: 'none'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        widt: '100%',

        '@media (max-width:768px)': {
           
        }

    },
    activeLink: {
        color: '#00C9A8',

    },
    dropdownLink: {
        color: '#666',
        fontSize: 16,
        padding: '15px 20px',
        borderBottom: "#0000001A 1px solid",
        '&:hover': {
            color: '#00C9A8'
        },

        '&:last-child': {
            borderBottom: "none",
        },
    },
    dropMenu: {
        verticalAlign: 'middle'
    },
    marginZero: {
        marginTop: 0
    },
    quranImg: {
        marginRight: 6,
        width: 40,
        height: 40
    },
    menuSubheading: {
        fontSize: "11px"
    },

    logo:{
       
        '@media (max-width:768px)': {
            display: "inline-block",
            margin:"0 auto",
            marginTop: "20px",
            marginBottom:"20px",
        }
            
        
    }

});




class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            open: false
        }
        this.anchorRef = React.createRef();
        this.anchorRef2 = React.createRef();
        this.anchorRef3 = React.createRef();
        this.anchorRef4 = React.createRef();
    }

    handleClick = () => {
        this.setState({
            menu: !this.state.menu
        })
    }

    openDropDown = (dropdown) => {
        this.setState({
            open: dropdown
        })
    };

    handleClose = (event) => {
        this.openDropDown('');
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        const { pathname } = this.props.history.location;

        return (
            <React.Fragment>
                <div className={classes.AppBarRoot}>
                    <AppBar position="static" classes={{ root: classes.background }}>
                        <Toolbar variant="dense">
                            <IconButton edge="start" onClick={() => this.handleClick()} className={classes.menuButton} color="inherit" aria-label="menu">
                                <Menu className={classes.colorWhite} />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>

                            </Typography>
                            <img src={QuranImg} className={classes.quranImg} alt="Quran" />
                        </Toolbar>
                    </AppBar>

                    <Grid container style={this.state.menu ? { display: 'block' } : { display: 'none' }} >
                        <Grid item xs={12} className={classes.menucontent}>
                            <Link
                                component={RouterLink}
                                to="/"
                            >
                                <ListItemText classes={{ root: classes.menucontent, primary: classes.menuItem }} primary="Home" />
                            </Link>

                            {/* <Link 
                        component={RouterLink} 
                        to="/about-us"
                    >
                        <ListItemText classes={{ primary : classes.menuItem }} primary="About Us" />
                    </Link> */}

                            <ListItem className={classes.menuItem} onClick={() => this.openDropDown('dropdown4')} >
                                <ListItemText classes={{ root: classes.marginZero, primary: classes.menuSubList }} primary="About us" />
                                {open === 'dropdown4' ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={open === 'dropdown4'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem component={RouterLink} to="/about-us#about">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="About Us" />
                                    </ListItem>
                                    <ListItem component={RouterLink} to="/about-us#mission">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="Vission And Mission" />
                                    </ListItem>
                                    <ListItem component={RouterLink} to="/about-us#contact-us">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="Contact Us" />
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem className={classes.menuItem} onClick={() => this.openDropDown('dropdown1')} >
                                <ListItemText classes={{ root: classes.marginZero, primary: classes.menuSubList }} primary="Projects" />
                                {open === 'dropdown1' ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={open === 'dropdown1'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem component={RouterLink} to="/al-quran-for-all">
                                        <ListItemText classes={{ primary: classes.menuItem }} >
                                            <Box component="span">
                                                <div>Al-Quran For All</div>
                                                <Typography component={"span"} className={classes.menuSubheading}> (Order, Receive and Explore words of God) </Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem component={RouterLink} to="/al-quran-for-all">
                                        <ListItemText classes={{ primary: classes.menuItem }} >
                                            <Box component="span">
                                                <div>Quran Research Center</div>
                                                <Typography component={"span"} className={classes.menuSubheading}> (Dive into the words of God) </Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem component={RouterLink} to="/about-us#projects">
                                        <ListItemText classes={{ primary: classes.menuItem }} >
                                            <Box component="span">
                                                <div>More Projects</div>
                                                <Typography component={"span"} className={classes.menuSubheading}> (Coming Soon) </Typography>
                                            </Box>
                                        </ListItemText>

                                    </ListItem>
                                </List>
                            </Collapse>


                            <ListItem className={classes.menuItem} onClick={() => this.openDropDown('dropdown2')} >
                                <ListItemText classes={{ root: classes.marginZero, primary: classes.menuSubList }} primary="About Islam" />
                                {open === 'dropdown2' ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={open === 'dropdown2'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    <ListItem component={RouterLink} to="/faq">
                                        <ListItemText  classes={{ primary: classes.menuItem }} primary="What is Islam?" />
                                    </ListItem>

                                    <ListItem component={RouterLink} to="/faq">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="What does Islam mean?" />
                                    </ListItem>

                                    <ListItem component={RouterLink} to="/faq">
                                        <ListItemText  classes={{ primary: classes.menuItem }} primary="What do Muslims believe?" />
                                    </ListItem>

                                    <ListItem component={RouterLink} to="/faq">
                                        <ListItemText  classes={{ primary: classes.menuItem }} primary="Who is Allah?" />
                                    </ListItem>

                                    <ListItem component={RouterLink} to="/faq">
                                        <ListItemText  classes={{ primary: classes.menuItem }} primary="Who is Allah?" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem className={classes.menuItem} onClick={() => this.openDropDown('dropdown3')} >
                                <ListItemText classes={{ primary: classes.menuSubList }} primary="Resources" />
                                {open === 'dropdown3' ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            <Collapse in={open === 'dropdown3'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem component={RouterLink} to="/order">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="Free Quran" />
                                    </ListItem>
                                    <ListItem component={RouterLink} to="/order">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="Books" />
                                    </ListItem>
                                    <ListItem component={RouterLink} to="/order">
                                        <ListItemText classes={{ primary: classes.menuItem }} primary="Brochures" />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </Grid>
                    </Grid>
                </div>
                <Box className={classes.padding} >

                    <Grid container className={classes.topBar} >
                        <Grid item xs={8}>
                        <Email className={classes.emailImg} />
                            <a href="mailto:team@alquranforall.com" className={classes.emailText}>team@alquranforall.com</a>
                        </Grid>
                        <Grid className={classes.textRight} item xs={4}>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/AlQuranDawahCenter/"><Facebook className={classes.emailImg} /></a>
                            {/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com"><Twitter className={classes.emailImg} style={{width:"16px"}} /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://youtube.com"><YouTube className={classes.emailImg} /></a> */}
                        </Grid>
                    </Grid>



                    <Grid container >
                        <Grid item xs={12} md={2} className={classes.logoContainer}>
                            <Link className={classes.logo} href="/"><img src="https://dc-img-assets.s3.amazonaws.com/DEENCONNECT-website-for-organization-alquran-dawah-center-logo.webp" alt="DEENCONNECT-AlQuranForAll-Order-Free-Quran" className={classes.logoImg} /></Link>
                        </Grid>
                        <Grid item xs={12} md={10} className={classes.myStyle}  >


                            <ul className={classes.listStyle}>
                                <li className={classes.menuItems}>
                                    <Link
                                        variant="body2"
                                        component={RouterLink}
                                        to="/"
                                        className={`${classes.MenuLinks} ${pathname === '/' && classes.activeLink}`}
                                    >
                                        Home
                                    </Link>
                                </li>

                                {/* <li className={ classes.menuItems}>
                        <Link
                            variant="body2"
                            component={RouterLink} 
                            to="/about-us"
                            className={`${classes.MenuLinks} ${pathname === '/about-us' && classes.activeLink}`}
                            >
                            About us
                        </Link>
                    </li> */}

                                <li className={classes.menuItems}>
                                    <Link
                                        className={classes.MenuLinks}
                                        ref={this.anchorRef4}
                                        onClick={() => this.openDropDown('dropdown4')}
                                    >
                                        About us <KeyboardArrowDown className={classes.dropMenu} />
                                    </Link>

                                    <Popper className={classes.dropdown} open={open === 'dropdown4'} anchorEl={this.anchorRef4.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper >
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        {/* onKeyDown={handleListKeyDown} */}
                                                        <MenuList autoFocusItem={open === 'dropdown4'} id="menu-list-grow" >
                                                            <MenuItem component={RouterLink} to="/about-us#about" className={classes.dropdownLink} onClick={this.handleClose}>About Us</MenuItem>
                                                            <MenuItem component={RouterLink} to="/about-us#mission" className={classes.dropdownLink} onClick={this.handleClose}>Vission And Mission</MenuItem>
                                                            <MenuItem component={RouterLink} to="/about-us#contact-us" className={classes.dropdownLink} onClick={this.handleClose}>Contact Us</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </li>

                                <li className={classes.menuItems}>
                                    <Link
                                        className={classes.MenuLinks}
                                        ref={this.anchorRef}
                                        onClick={() => this.openDropDown('dropdown1')}
                                    >
                                        Projects   <KeyboardArrowDown className={classes.dropMenu} />
                                    </Link>

                                    <Popper className={classes.dropdown} open={open === 'dropdown1'} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper >
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        {/* onKeyDown={handleListKeyDown} */}
                                                        <MenuList autoFocusItem={open === 'dropdown1'} id="menu-list-grow" >
                                                            <MenuItem component={RouterLink} to="/al-quran-for-all" style={{ display: "block" }} className={classes.dropdownLink} onClick={this.handleClose}>
                                                                <div>Al-Quran For All</div>
                                                                <Typography component={"span"} className={classes.menuSubheading}> (Order, Receive and Explore words of God) </Typography></MenuItem>
                                                            <MenuItem component={RouterLink} to="/al-quran-for-all" style={{ display: "block" }} className={classes.dropdownLink} onClick={this.handleClose}>
                                                                <div>Quran Research Center</div>
                                                                <Typography component={"span"} className={classes.menuSubheading}>(Dive into the words of God) </Typography>
                                                            </MenuItem>
                                                            <MenuItem component={RouterLink} to="/about-us#projects" style={{ display: "block" }} className={classes.dropdownLink} onClick={this.handleClose}>
                                                                <div> More Projects </div>
                                                                <Typography component={"span"} className={classes.menuSubheading}> (Coming Soon) </Typography>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </li>

                                <li className={classes.menuItems}>
                                    <Link
                                        className={classes.MenuLinks}
                                        ref={this.anchorRef2}
                                        onClick={() => this.openDropDown('dropdown2')}
                                    >
                                        About Islam <KeyboardArrowDown className={classes.dropMenu} />
                                    </Link>

                                    <Popper className={classes.dropdown} open={open === 'dropdown2'} anchorEl={this.anchorRef2.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper >
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        {/* onKeyDown={handleListKeyDown} */}
                                                        <MenuList autoFocusItem={open === 'dropdown2'} id="menu-list-grow" >
                                                            <MenuItem className={classes.dropdownLink} onClick={this.handleClose} component={RouterLink} to="/faq" >What is Islam?</MenuItem>
                                                            <MenuItem className={classes.dropdownLink} onClick={this.handleClose} component={RouterLink} to="/faq" >What does Islam mean?</MenuItem>
                                                            <MenuItem className={classes.dropdownLink} onClick={this.handleClose} component={RouterLink} to="/faq" >What do Muslims believe?</MenuItem>
                                                            <MenuItem className={classes.dropdownLink} onClick={this.handleClose} component={RouterLink} to="/faq" >Who is Allah?</MenuItem>
                                                            <MenuItem className={classes.dropdownLink} onClick={this.handleClose} component={RouterLink} to="/faq" >Who is Allah?</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </li>

                                <li className={classes.menuItems}>
                                    <Link
                                        className={classes.MenuLinks}
                                        ref={this.anchorRef3}
                                        onClick={() => this.openDropDown('dropdown3')}
                                    >
                                        Resources <KeyboardArrowDown className={classes.dropMenu} />
                                    </Link>

                                    <Popper className={classes.dropdown} open={open === 'dropdown3'} anchorEl={this.anchorRef3.current} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper >
                                                    <ClickAwayListener onClickAway={this.handleClose}>

                                                        <MenuList autoFocusItem={open === 'dropdown3'} id="menu-list-grow" >
                                                            <MenuItem component={RouterLink} to="/order" className={classes.dropdownLink} onClick={this.handleClose}>Free Quran</MenuItem>
                                                            <MenuItem component={RouterLink} to="/order" className={classes.dropdownLink} onClick={this.handleClose}>Books</MenuItem>
                                                            <MenuItem component={RouterLink} to="/order" className={classes.dropdownLink} onClick={this.handleClose}>Broachers</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </li>
                            </ul>

                            <Link
                                // href={process.env.REACT_APP_DONATE_URL}
                                href={process.env.REACT_APP_DONATE_URL}
                                underline="none"
                                target="_blank"
                                rel="noopener"
                                className={classes.donateButtonLink}
                            >
                                <Button variant="outlined" size="medium" className={classes.donateButton}>
                                    DONATE US
                                </Button>
                            </Link>

                            <Button variant="outlined" size="medium" className={classes.orderButton} component={RouterLink} to="/order">

                                Order Free Quran

                            </Button>


                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(Header));
