import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BluePattern from '../../assets/blue_pattren.png';


const useStyles = makeStyles((theme) => ({
    FD_banner_img: {
        background: `#4B0082 url(${BluePattern})`,
        backgroundSize: "cover",
        opacity: 1,
        backgroundPosition: "center",
        paddingTop: "70px",
        paddingBottom: "70px",
    },
    FD_inner_banner: {
        padding: '24px'
    },
    FD_title: {
        textAlign: "center",
        fontWeight: 600,
        color: "#fff",
        fontSize: "48px",
        margin: "28.22px 0px",
        '@media (max-width:600px)': {
            padding: '0px 30px'
        },
    },

}));


const Banner = (props) => {
    const { title } = props;

    const classes = useStyles();
    return (
        <Grid className={classes.FD_banner_img} >
            <Grid container className={classes.FD_footerInner} >
                <Grid item container xs={12} direction="row" alignItems="stretch" justify="flex-start" spacing={2} >

                    <Grid item xs={12} >
                        <Typography className={classes.FD_title}>
                            {title}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    );
}

export default Banner;