import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Button,
  Box,
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import FaceBookImg from "../../assets/facebook.png";
import BackgroundImage from "../../assets/blue_pattren.png";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  factMainBox: {
    textAlign: "center",
    backgroundImage: `url(${BackgroundImage})`,
    color: "#fff",
    padding: "80px",
    marginTop: 80,

    '@media (max-width:600px)': {
      padding: '24px 0px'
  }
  },
  heading: {
    fontFamily: "Open Sans",
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  title: {
    fontFamily: "Roboto Slab",
    fontWeight: 400,
    marginTop: 20,
    marginBottom: 40,
    "@media (max-width:600px)": {
      fontSize: 22,
    },
    "@media (max-width:959px)": {
      padding: 0,
    },
    "@media (min-width:960px)": {
      fontSize: 60,
    },
  },
  quote: {
    color: "#fff",
    fontFamily: "Open Sans",
    lineHeight: 1.5,
    opacity: 0.9,
    display: "block",
    fontSize: 16,
    "@media (max-width:600px)": {
      fontSize: 16,
    },
    "@media (max-width:959px)": {
      padding: 0,
    },
    "@media (min-width:960px)": {
      fontSize: 30,
    },
  },
  donateButton: {
    background: "#FEC553",
    border: 0,
    color: "white",
    marginTop: 20,
    height: 70,
    width: "230px",
    fontSize: "20px",
    "&:hover": {
      background: "#FEC553",
    },

    "@media (max-width:768px)": {
      width: "100%",
    },

  },
  socialize: {
    color: "#080F58",
    fontSize: 60,
    fontFamily: "Roboto Slab",
    margin: 0,
    fontWeight: 400,
    marginTop: 80,
    marginBottom: 10,

    "@media (max-width:768px)": {
      margin: 20,
    },
  },
  socialItem: {
    background: "#fbfafd",
    padding: 20,
    textAlign: "center",

    "@media (max-width:768px)": {
      padding:"20px !important",
    },
  },

  imgContainer: {
    width: 68,
    height: 68,
    margin: "0 auto",
    borderRadius: 70,

    "@media (max-width:768px)": {
      margin: "0 auto !important",
    },

  },
  imgtag: {
    position: "relative",
    top: 23,
    color: "#fff",
    cursor: "pointer",
  },
  facebook: {
    background: "#4267B2",
    marginTop: 15,
  },
  twitter: {
    background: "#03A9F4",
    marginTop: 15,
  },
  youtube: {
    background: "#F44336",
    marginTop: 15,
  },
  facebookButton: {
    marginTop: 40,
    background: "#4267B2",
    width: "100%",
    border: 0,
    color: "#fff",
    width: "300px",
    height: "70px",
    fontSize: "20px",
    "&:hover": {
      background: "#4267B2",
    },
  },
  twitterButton: {
    marginTop: 40,
    background: "#03A9F4",
    width: "100%",
    border: 0,
    color: "#fff",
    width: "300px",
    height: "70px",
    fontSize: "20px",
    "&:hover": {
      background: "#03A9F4",
    },
  },
  youtubeButton: {
    marginTop: 40,
    background: "#F44336",
    width: "100%",
    border: 0,
    color: "#fff",
    width: "300px",
    height: "70px",
    fontSize: "20px",
    "&:hover": {
      background: "#F44336",
    },
  },
  followText: {
    color: "#080F58",
    fontSize: 24,
    fontFamily: "Roboto Slab",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "500",
  },
  link: {
    color: "#080F58",
    fontSize: 20,
    margin: 0,
    cursor: "pointer",
  },
  adTitle: {
    fontFamily: "Roboto Slab",
    textAlign: "center",
    background: "#fafaff",
    padding: 10,
    maxWidth: "60%",
    margin: "0 auto",
    fontWeight: 900,
    fontSize: 50,
    color: "#b2b2b2",
  },
  adBox: {
    maxWidth: "65%",
    margin: "0px auto 30px",
    "@media (max-width:959px)": {
      maxWidth: "100%",
    },
  },
  adItem: {
    padding: "5px 20px",
    background: "#fbfafd",
    textAlign: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  maxWidth: {
    maxWidth: 1120,
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Box className={classes.factMainBox}>
        <Container classes={{ root: classes.maxWidth }}>
          <Grid container>
            <Grid item xs={12}>
              <div className="MobileViewContainer">
              <Typography variant="caption" className={classes.heading}>
                DONATE US
              </Typography>
              <Typography variant="h4" className={classes.title}>
                Help us to increase our efforts and add more projects
              </Typography>
              <Typography variant="caption" className={classes.quote}>
                Whoever guides [another] to a good deed will get a reward
                similar to the one who performs it. (Muslim)
              </Typography>
              <br />

              <Link
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_DONATE_URL}
                className={classes.donateNowTab}
              >
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.donateButton}
                >
                  DONATE US
              </Button>
              </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container classes={{ root: classes.maxWidth }} style={{marginBottom:"60px"}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.socialize}>
              Follow Us
            </Typography>
          </Grid>

          <Grid item md={4} xs={12} sm={6}>
            <Box className={classes.socialItem}>
              <Box className={` ${classes.facebook} ${classes.imgContainer}`}>
                <img
                  src={FaceBookImg}
                  className={classes.imgtag}
                  alt="facebook"
                />
              </Box>
              <Typography className={classes.followText}>
                Follow us on Facebook
              </Typography>
              <Typography className={classes.link}>@AlQuranDawahCenter</Typography>

              <Link
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
                href="https://www.facebook.com/AlQuranDawahCenter/"
              >
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.facebookButton}
                >
                  FOLLOW NOW
                </Button>
              </Link>
            </Box>
          </Grid>

          {/* <Grid item md={4} xs={12} sm={6}>
            <Box className={classes.socialItem}>
              <Box className={` ${classes.twitter} ${classes.imgContainer}`}>
                <TwitterIcon className={classes.imgtag} />
              </Box>
              <Typography className={classes.followText}>
                Follow us on Twitter
              </Typography>
              <Typography className={classes.link}>@freequranforall</Typography>

              <Link
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
                href="https://twitter.com"
              >
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.twitterButton}
                >
                  FOLLOW NOW
                </Button>
              </Link>
            </Box>
          </Grid> */}

          {/* <Grid item md={4} xs={12} sm={6}>
            <Box className={classes.socialItem}>
              <Box className={` ${classes.youtube} ${classes.imgContainer}`}>
                <YouTubeIcon className={classes.imgtag} alt="youtube" />
              </Box>
              <Typography className={classes.followText}>
                Subscribe us on Youtube
              </Typography>
              <Typography className={classes.link}>@freequranforall</Typography>

              <Link
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
                href="https://youtube.com"
              >
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.youtubeButton}
                >
                  SUBSCRIBE
                </Button>
              </Link>
            </Box>
          </Grid> */}

          {/* <Grid item xs={12} className={classes.adBox}>
            <Box className={classes.adItem}>
              <Typography className={classes.adTitle}>ADS</Typography>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
