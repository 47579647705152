import React from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import './Modal.css'

function ModalDelete(props) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <div>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className='DeleteModalBox'>
                        <div className='DeleteModalBoxInner'>
                            <div className='FldIconCtn'>
                                <CloseRoundedIcon className='closeIcon' onClick={() => props.handleClose()} />
                            </div>

                            <div className='DltCmpMdlBody'>

                                <div className='DeleteCampCtn'>
                                    <h2>Remove order?</h2>
                                    <p>Are you sure you want to remove this order? Removing will permanently delete the order.</p>
                                </div>

                            </div>

                            <div className='ModalFooterCtn'>
                                <Button className="btn btnCencel" variant="contained">CANCEL</Button>
                                <Button className=" btn btnDelete" variant="contained">YES, DELETE</Button>
                            </div>

                        </div>
                    </div>
                </Fade>
            </Modal>


        </div>


    )
}






export default ModalDelete;