import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Box, Grid, List, ListItem, ListItemText, Typography, Slide } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import PattrenImg from '../../assets/blue_pattren.png';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    factMainBox: {
        textAlign: 'center',
        backgroundImage: `url(${PattrenImg})`,
        color: '#fff',
        padding: 24,
        paddingBottom: 80,
        paddingTop: 80,
        '@media (max-width:600px)': {
            padding: '24px 0px'
        }
    },
    factBoxTitle: {
        fontFamily: 'Open Sans',
        fontSize: 20,
        fontWeight: 700,

        '@media (max-width:768px) ': {
            marginTop:"30px",
        },
    },
    factHeading: {
        fontFamily: 'Roboto Slab',
        fontWeight: 600,
        marginTop: 0,
        lineHeight: 1.3,
        fontSize: 60,

        '@media (max-width:768px) ': {
            padding: "0 30px",
        },

        '@media (max-width:600px) ': {
            fontSize: 20
        },

    },
    root: {
        listStyle: 'none',
        border: '1px solid #ffffff30',
        padding: '10px 0px',
        borderRadius: 10,
        marginTop: 40,
    },
    fact: {
        display: 'inline-block',
        textAlign: 'center',
        '@media (max-width:440px)': {
            paddingLeft: 6,
            paddingRight: 6
        }
    },
    borderRight: {
        borderRight: '1px solid #ffffff30'
    },
    factWidth: {
        width: '24%',
        padding: "20px",

        '@media (max-width:768px)': {
            padding: "20px",
            width: '100%',
        }

    },


    factMaxWidth: {
        width: '48%',
        padding: "20px",

        '@media (max-width:768px)': {
            width: '100%',
        },
    },
    factTitle: {
        fontSize: 40,
        fontWeight: 600,
        lineHeight: "35px",
        fontFamily: 'Open Sans',

        '@media (max-width:768px)': {
            fontSize: 22
        },

    },
    Line: {
        opacity: 0.2
    },
    readMore: {
        fontSize: 24,
        fontFamily: 'Open sans',
        margin: '40px auto',
        width: '46%',
        '@media (max-width:960px)': {
            width: '100%',
            fontSize: 18
        }
    },
    paginationLeft: {
        display: 'flex',
        textAlign: 'left',
        '@media (max-width:960px)': {
            marginBottom: 20,
            justifyContent: 'center'
        }
    },
    paginationLeftText: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 500,
        paddingRight: 20,
        fontFamily: 'Roboto Slab',
        lineHeight: 1.5
    },
    paginationRight: {
        justifyContent: 'flex-end',
        padding: 0,
        display: 'flex',
        textAlign: 'left',
        '@media (max-width:960px)': {
            marginBottom: 20,
            justifyContent: 'center'
        }
    },
    paginationRightText: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 500,
        fontFamily: 'Roboto Slab',
        lineHeight: 1,
        position: 'relative',
        top: 8,
        right: 10
    },
    Arrows: {
        background: '#FEC553',
        borderRadius: 30,
        padding: 5,
        height: 25,
        marginRight: 10
    },
    orderButton: {
        background: '#FEC553',
        border: 0,
        color: 'white',
        height: 70,
        width: 347,
        fontSize: 20,
        fontFamily: 'Open sans',
        fontWeight: 600,
        '&:hover': {
            background: '#fec553'
        },
        '@media (max-width:960px)': {
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center'
        }
    },
    timesStyle: {
        fontSize: 24,
        fontFamily: 'Open Sans',
        '@media (max-width:440px)': {
            fontSize: 16
        }
    },
    maxWidth: {
        maxWidth: 1120
    },

    Description: {
        background: '#FEC553',
        border: 0,
        color: 'white',
        height: 70,
        width: 347,
        fontSize: 40,
        fontFamily: 'Open sans',
        fontWeight: 400,
        lineHeight:"52",
    }
}));

export default function MainFeaturedPost(props) {
    const classes = useStyles();

    const [slideNumber, setslideNumber] = useState(0);

    const titles = [
        "Quran mentions by name of 25 Prophets and Messengers of God",
        "Birth of Jesus",
        "The Last Sermon",
        "What is al-Qur'an?",
    ]

    const incSlideNumber = () => {
        setslideNumber((prevState) => {
            if (prevState === 3) {
                return 0;
            }
            return prevState + 1;
        });
    };

    const decSlideNumber = () => {
        setslideNumber((prevState) => {
            if (prevState === 0) {
                return 3;
            }
            return prevState - 1;
        });
    };



    return (
        <Box className={classes.factMainBox}>
            <Container classes={{ root: classes.maxWidth }}>
                <Grid container style={{ overflow: "hidden" }}>
                    <Grid item xs={12} className={classes.Box} >
                        <Typography variant="h5" className={classes.factBoxTitle} >SOME QURANIC FACTS</Typography><br />

                        {slideNumber === 0 && (<Slide direction="left" in={slideNumber === 0}>
                            <Box>
                                <Typography variant="h3" className={classes.factHeading} >Quran mentions by name of 25 Prophets and Messengers of God.</Typography><br />
                                <List classes={{ root: classes.root }} >
                                    <ListItem className={`${classes.fact} ${classes.factWidth}  ${classes.borderRight}`}>
                                        <ListItemText classes={{ primary: classes.factTitle }} >Moses </ListItemText>  <Typography variant="caption" className={classes.timesStyle}>136 Times</Typography>
                                    </ListItem>
                                    <ListItem className={`${classes.fact} ${classes.factWidth}  ${classes.borderRight}`}>
                                        <ListItemText classes={{ primary: classes.factTitle }}>Abraham </ListItemText> <Typography variant="caption" className={classes.timesStyle}>69 Times</Typography>
                                    </ListItem>
                                    <ListItem className={`${classes.fact} ${classes.factWidth}  ${classes.borderRight}`}>
                                        <ListItemText classes={{ primary: classes.factTitle }} >Noah </ListItemText><Typography variant="caption" className={classes.timesStyle}> 43 Times</Typography>
                                    </ListItem>
                                    <ListItem className={`${classes.fact} ${classes.factWidth}`}>
                                        <ListItemText classes={{ primary: classes.factTitle }} >Ismael </ListItemText><Typography variant="caption" className={classes.timesStyle}> 12 Times</Typography>
                                    </ListItem>
                                    <hr className={classes.Line} />
                                    <ListItem className={`${classes.fact} ${classes.factMaxWidth} ${classes.borderRight}`}>
                                        <ListItemText classes={{ primary: classes.factTitle }} >Jesus </ListItemText><Typography variant="caption" className={classes.timesStyle} >25 Times</Typography>
                                    </ListItem>
                                    <ListItem className={`${classes.fact} ${classes.factMaxWidth}`}>
                                        <ListItemText classes={{ primary: classes.factTitle }} >Muhammad(PBUH)</ListItemText><Typography variant="caption" className={classes.timesStyle}>4 Times</Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Slide>)
                        }

                        {slideNumber === 1 && (<Slide direction="left" in={slideNumber === 1}>
                            <Box >
                                <Typography variant="h3" className={classes.factHeading} >Birth of Jesus <br /> Peace Be Upon Him</Typography><br />
                                    <p className='factDescription'>He said , "I am only the messenger of your Lord to give you [news of] a pure boy."
                                        She said, "How can I have a boy while no man has touched me and I have not been unchaste?"
                                        He said, "Thus [it will be]; your Lord says, 'It is easy for Me, and We will make him a sign to the people and a mercy from Us. And it is a matter [already] decreed.' " So she conceived him, and she withdrew with him to a remote place. (Al Qur'an 19:19-22)</p>
                            </Box>
                        </Slide>)
                        }

                        {slideNumber === 2 && (<Slide direction="left" in={slideNumber === 2}>
                            <Box>
                                <Typography variant="h3" className={classes.factHeading} >The Last Sermon</Typography><br />
                                <p className='factDescription'>"…an Arab has no superiority over a non-Arab nor a non-Arab has any superiority over an Arab; also a white has no superiority over a black nor a black has any superiority over white, except by piety and good action. " Prophet Muhammad (PBUH)</p>
                            </Box>
                        </Slide>)
                        }


                        {slideNumber === 3 && (<Slide direction="left" in={slideNumber === 3}>
                            <Box >
                                <Typography variant="h3" className={classes.factHeading} >What is al-Qur'an?</Typography><br />
                                    <p className='factDescription'>"And We will bring you, [O Muhammad], as a witness over your nation. And We sent down to you the Book as clarification for all things and as a guidance and mercy and good tidings for the Muslims." (Al Qur'an 16:89)</p>
                            </Box>
                        </Slide>)
                        }
                    </Grid>


                    {slideNumber === 0 && (<Slide direction="left" in={slideNumber === 0}><Grid item xs={12}>
                        <Typography className={classes.readMore}>25 Prophets</Typography>
                    </Grid></Slide>)}

                    {slideNumber === 1 && (<Slide direction="left" in={slideNumber === 1}><Grid item xs={12}>
                        <Typography className={classes.readMore}>Birth of Jesus</Typography>
                    </Grid></Slide>)}

                    {slideNumber === 2 && (<Slide direction="left" in={slideNumber === 2}><Grid item xs={12}>
                        <Typography className={classes.readMore}>The Last Sermon</Typography>
                    </Grid></Slide>)}

                    {slideNumber === 3 && (<Slide direction="left" in={slideNumber === 3}><Grid item xs={12}>
                        <Typography className={classes.readMore}>What is al-Qur'an?</Typography>
                    </Grid></Slide>)}




                    <Grid item xs={12} md={3} className={classes.paginationLeft} >
                        <ArrowBack className={classes.Arrows} onClick={decSlideNumber} />
                        <Typography variant="caption" classes={{ caption: classes.paginationLeftText }} >
                            {slideNumber === 0 ? titles[2] : titles[slideNumber - 1]}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <Button variant="outlined" size="large" className={classes.orderButton} component={RouterLink} to="/order">
                            ORDER YOUR FREE QURAN
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={3} className={classes.paginationRight}>
                        <Typography variant="caption" classes={{ caption: classes.paginationRightText }}>
                            {slideNumber === 3 ? titles[0] : titles[slideNumber + 1]}
                        </Typography>
                        <ArrowForward className={classes.Arrows} onClick={incSlideNumber} />
                    </Grid>

                </Grid>
            </Container>
        </Box>
    );
}
