import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
//=== Forms ====//
import OrderInfo from './OrderInfo'
import RequesterInfo from './RequesterInfo'
import Checkout from './Checkout'

import { makeOrder } from './functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from 'react-redux'
import { setRequesterInfo } from '../../features/counter/counterSlice'


import './OrderForm.css';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));



function getSteps() {
    return ['Order Info', 'Requester Info', 'Checkout'];
}

// function 




function Steper() {

    const rInfo = useSelector((state) => state.counter.requesterInfo)
    const oInfo = useSelector((state) => state.counter.orderInfo)
    const paymentInfoState = useSelector((state) => state.counter.paymentInfo)
    const [pInfo, setPinfo] = useState(paymentInfoState);



    const history = useHistory();

    const dispatch = useDispatch()

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();

    const totalSteps = () => {
        return steps.length;
    };

    const [errorValues, seterrorValues] = useState({
        postalCode: false, 
        city: false ,
        streetAddress: false
    });


    const getStepContent = (step) => {
        // console.log( 'step', step );
        switch (step) {
            case 0:
                return (<OrderInfo />);
            case 1:
                return (<RequesterInfo errorObject={requesterError} />);
            case 2:
                return (<Checkout errorValues={errorValues} setbuttonDisabled={setbuttonDisabled} PaymentFailedAlert={PaymentFailedAlert} setshowLoading={setshowLoading} setPaymentInfo={setPinfo} />);

            default:
                return 'Unknown step';
        }
    }

    const [PaymentFailedAlert, setPaymentFailedAlert] = useState( false );

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
        if( newActiveStep ==2 ){
            setbuttonDisabled( true );
        }
    };

    

    const handleStep = (step) => () => {
        if( step ==0 ){
            setActiveStep(step);
        }else if( completed[step-1] ){
            setActiveStep(step);
        }
        
    };

    const [requesterError, setRequesterError] = useState({
        firstName: false,
        lastName: false,        
        phone: false,
        billingStreetAddress: false,
        billingFloorNumber: false,
        billingCity: false,
        billingState: false,
        billingPostalCode: false
    });

    const handleComplete = async () => {        

        if (activeStep == 1) {

            let requesterErrorCopy = requesterError;
            let errorOccured = false ; 

            if (rInfo.firstName.length == 0) {
                
                requesterErrorCopy.firstName = true ; 
                errorOccured = true ; 
                setRequesterError( {
                    ...requesterErrorCopy, 
                ['firstName']: true });
                
                
            } else {
                requesterErrorCopy.firstName = false ;
            }

            if (rInfo.lastName.length == 0) {
                requesterErrorCopy.lastName = true ; 
                errorOccured = true ; 
                setRequesterError( {
                    ...requesterErrorCopy, 
                ['lastName']: true });
                
                
            } else {
                requesterErrorCopy.lastName =  false   ;
            }
            
            if (rInfo.billingStreetAddress.length == 0) {
                
                requesterErrorCopy.billingStreetAddress = true ; 
                errorOccured = true ; 

                setRequesterError( {
                    ...requesterErrorCopy, 
                ['billingStreetAddress']: true });
                
            } else {
                requesterErrorCopy.billingStreetAddress = false ; 
            }
            if (rInfo.billingCity.length == 0) {
                
                requesterErrorCopy.billingCity = true ; 
                errorOccured = true ; 

                setRequesterError( {
                    ...requesterErrorCopy, 
                ['billingCity']: true });

            } else {
                requesterErrorCopy.billingCity = false ; 
            }

            if (rInfo.billingPostalCode.length == 0) {
                requesterErrorCopy.billingPostalCode = true ; 
                errorOccured = true ;

                setRequesterError( {
                    ...requesterErrorCopy, 
                ['billingPostalCode']: true });

                return ; 

            } else {
                requesterErrorCopy.billingPostalCode = false ; 
            }

            // console.log( requesterErrorCopy);            

            if( errorOccured ){
                
                return '';
            }


        }

        if ( isLastStep() ) {
            if(pInfo){

                let errorOccured = false ; 
                let errorFlagCopy = errorValues ; 

                if( pInfo.city.length == 0 ){
                    errorFlagCopy.city = ( pInfo.city.length == 0 ) ;
                    errorOccured = true ; 
                } 

                if( pInfo.streetAddress.length == 0 ){
                    errorFlagCopy.streetAddress = (  pInfo.streetAddress.length == 0  ) ; 
                    errorOccured = true ; 
                }

                if( pInfo.postalCode.length == 0){
                    errorFlagCopy.postalCode = ( pInfo.postalCode.length == 0 ) ; 
                    errorOccured = true ; 
                }

                seterrorValues( 
                    {
                        postalCode: errorFlagCopy.postalCode, 
                        city: errorFlagCopy.city  ,
                        streetAddress: errorFlagCopy.streetAddress
                    }
                 ); 

                if( errorOccured && pInfo.method == 'card' && (pInfo.billingAddressSameAsShipping == 0 )){
                    return ''; 
                }
                
                if (
                    ( (pInfo.method != 'card')) ||
                    (parseInt(oInfo.orderSubtotal) < 1) ||
                    (pInfo && pInfo.cardNumber && pInfo.cardNumber.length > 0 && pInfo.securityCode && pInfo.securityCode.length > 0 && pInfo.expiration && pInfo.expiration.length > 0 && pInfo.nameOnCard && pInfo.nameOnCard.length > 0)) {
                } else {
                    setShowSuccessMessage(true);
    
                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
    
                    return;
                }
            }
            
        }
        const newCompleted = completed;

        let postData = {
            orderFor: oInfo.orderFor,
            orderItems: [
                {
                    language: oInfo.language,
                    quantity: oInfo.quantity,
                    itemName: oInfo.itemName,
                    orderSubtotal: oInfo.orderSubtotal,
                }
            ],
            requester: rInfo,
            shipping: {
                firstName: rInfo.shipppingFirstName,
                lastName: rInfo.shippingLastName,
                email: rInfo.shippingEmail,
                phone: rInfo.shippingPhone,
                streetAddress: rInfo.shippingStreetAddress,
                floorNumber: rInfo.shippingFloorNumber,
                sameRequester: rInfo.shippingAddressSameAsBilling,
                city: rInfo.shippingCity,
                state: rInfo.shippingState,
                postalCode: rInfo.shippingPostalCode,
            },
            billing: {
                firstName: rInfo.firstName,
                lastName: rInfo.lastName,
                email: rInfo.email,
                phone: pInfo.phone,
                streetAddress: pInfo.streetAddress,
                floorNumber: pInfo.floorNumber,
                sameRequester: pInfo.sameRequester,
                city: pInfo.city,
                state: pInfo.state,
                postalCode: pInfo.postalCode
            },
            orgId: process.env.REACT_APP_ORGANIZATION_ID,
            payment: pInfo,
        };
        
        if (isLastStep()) {

            setshowLoading(true);

            const paymentResponse = await makeOrder(postData, handlePaymentResponse);

            console.log( paymentResponse );

            setshowLoading(false);

            newCompleted[activeStep] = true;
            setCompleted(newCompleted);


            history.push('/thank-you');
        }else{

            newCompleted[activeStep] = true;
            setCompleted(newCompleted);

        }
        handleNext();
    };

    const handlePaymentResponse  = ( data ) =>{
        if( data.status == 400 ){   
            setPaymentFailedAlert( true );
            setTimeout(function () {
                setPaymentFailedAlert(false);
            }, 3000);
            setshowLoading(false);
        }
    }

    

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showLoading, setshowLoading] = useState(false);


    const [buttonDisabled, setbuttonDisabled] = useState( false );


    return (
        <div className='StapperMainContainer'>

            <div className="StepperHeader">
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton onClick={handleStep(index)} completed={completed[index]}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>

            {showSuccessMessage && (<div className='AlertCtnError'><Alert className='successAlert' onClose={() => { setShowSuccessMessage(false) }}>Please complete all payment method details.</Alert></div>)}

            {showLoading && (
                <div className='Loading'>
                <CircularProgress />
            </div>
            )} 





            <div>
                {allStepsCompleted() ? (
                    <div>


                    </div>
                ) : (


                    <div className="FormBody">
                        <Container maxWidth="md">

                            <Typography className="instructions">{getStepContent(activeStep)}</Typography>



                            <div className="FormButtonBody">

                                {/* {activeStep !== 0 &&
                                <Button onClick={handleBack} className="buttonBack">
                                    <ArrowBackIosRoundedIcon /> GO BACK
                                </Button>
                            }
                            <span></span> */}
                                {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                Next
                            </Button> */}
                                {activeStep !== steps.length &&
                                    (completed[activeStep] ? (
                                        <Typography variant="caption" className="completed">
                                            Step {activeStep + 1} already completed
                                        </Typography>
                                    ) : (
                                        <Button disabled={buttonDisabled} variant="contained" color="primary" onClick={handleComplete}>
                                            {completedSteps() === totalSteps() - 1 ? 'PLACE ORDER' : 'CONTINUE'}
                                        </Button>
                                    ))}
                            </div>
                        </Container>
                    </div>
                )}
            </div>

        </div>
    );
}


export default Steper;