import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Typography, Button, Box} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CheckCircle  from '../../assets/check_circle-yellow.png';

const useStyles = makeStyles((theme) => ({
mainBox : {
    background: '#fafbfe',
    marginTop : 40,
    paddingBottom :60
},
missionText : {
    color: '#666',
    fontSize: 16,
    fontFamily: 'Open Sans',
    lineHeight: 1.5
},
title:{
    color: '#080F58',
    fontSize: 36,
    fontFamily:'Roboto Slab',
    margin: '0 0 15px 0',
    fontWeight: 600,
    lineHeight: 1,
    paddingTop: 40
},
text :{
	color: '#666',
	fontSize: 16,
	fontFamily: 'Open Sans',
	lineHeight: 1.5,
	fontWeight: 600
},
influencerTitle : {
    color: '#080F58',
	fontFamily: 'Roboto Slab',
	fontSize: 20,
	fontWeight: 800,
    marginTop: 10
},
influencerText : {
	color: '#666',
	fontFamily: 'Open Sans',
	fontSize: 14,
    lineHeight: 1.5,
    marginTop : 20,
    marginBottom : 20
},
influencerParent : {
    textAlign: 'center',
    border: '1px solid #0000001A',
    borderRadius: 3,
    background: '#fff',
    padding : 20,
    height : '90%'
},
orderButton : {
    padding : 10,
    background: '#00C9A8',
    borderRadius: 3,
    color: 'white',
    border : 0,
    marginBottom : 10,
    width: 145,
    height: 45,
    '&:hover': {
        background: '#00C9A8'
    }
},
textCenter : {
    textAlign : 'center'
},
thanksTitle : {
    fontSize: 40,
    fontFamily: 'Roboto Slab',
    color: '#080F58',
    marginBottom :30,
    '@media (max-width:960px)' : {
        fontSize:26
    }
},
thanksText : {
    color: '#666',
    fontFamily: 'Open Sans',
    wordSpacing: 1.5,
    fontSize: 15,
    linHeight: 1.7,
    marginBottom : 30,
    '@media (max-width:960px)' :{
        fontSize : 14
    }
},
padding : {
    padding: '30px 200px 30px 200px',
    textAlign: 'center',
    lineHeight: 1.5,
    '@media (max-width:960px)' :{
        padding: '30px 60px 30px 60px'
    },
    '@media (max-width:576px)' : {
        padding:'30px 0px 30px 0px'
    }
},
imgWidth : {
    marginTop : 30,
    width: 100
},
mailFeed :  {
	color: '#00C9A8'
},
marginTop : {
    marginTop : 50,
    width: '100%'
},
maxWidth :{
    maxWidth: 1120
}
}));

  

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <React.Fragment>

    <Container classes={{ root : classes.maxWidth }}>
        <Grid container>
            <Grid item xs={12} className={ classes.textCenter} >
                <img src={CheckCircle} className={ classes.imgWidth} alt="influencer-img" />
                <Typography variant="h4" className={ classes.thanksTitle  }>Thanks. <br />Your message has been sent.</Typography>
                <Box className={ classes.padding }>
                    <Typography variant="caption" className={ classes.thanksText }>One of our awesome team members will reply to you. If you don’t receive a follow-up within 2 business days, or need to reach us, feel free to send us a quick email at <a href="mailto:team@alqurandc.org" className={ classes.mailFeed}>team@alqurandc.org</a></Typography>
                </Box>

                <Button variant="outlined" size="large" className={classes.orderButton }>
                    SEND AGAIN
                </Button>
            </Grid>
        </Grid>
    </Container>
    </React.Fragment>
  );
}
