import React, { createRef, useEffect } from "react";
import Header from '../common/head';
import Banner from './Banner';
import Body from './Body';
import Sponsoring from '../common/Sponsoring';
import Distributes from './Distributes';
import Footer from '../common/Footer';
import Inside from './inSide';
import Involved from './Involved';

function App() {
  const [tab, settab] = React.useState(0);
  const aboutRef = createRef();
  const insideRef = createRef();
  const wordsInActionRef = createRef();
  const howItWorksRef = createRef();

  useEffect(() => {
    if (window.location.href.indexOf("al-quran-for-all#about") > -1) {
      settab(0);
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.href.indexOf("al-quran-for-all#inside") > -1) {
      settab(1);
      insideRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.href.indexOf("al-quran-for-all#words-in-action") > -1) {
      settab(2);
      wordsInActionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      window.location.href.indexOf("al-quran-for-all#how-it-works") > -1
    ) {
      settab(3);
      howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [aboutRef, insideRef, wordsInActionRef, howItWorksRef]);

  const handleChange = (event, newValue) => {
    settab(newValue);
    if (newValue === 0) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 1) {
      insideRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 2) {
      wordsInActionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 3) {
      howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Body tab={tab} aboutRef={aboutRef} handleChange={handleChange} />
      <Distributes />
      <Sponsoring />
      <Inside insideRef={insideRef} />
      <Involved wordsInActionRef={wordsInActionRef} howItWorksRef={howItWorksRef} />
      {/*<OurProject />
      <OurActivities />
      <Questions />
      <Social /> */}
      <Footer />
    </React.Fragment>
  );
}

export default App;