import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Typography } from "@material-ui/core";
import QuranBox from "../../assets/Quran_Box_Mockup.png";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  paddingTop: {
    paddingTop: 40,
  },
  title: {
    color: "#080F58",
    fontSize: 36,
    fontFamily: "Roboto Slab",
    marginBottom: 15,
    fontWeight: 550,
    lineHeight: 1,
  },
  subtitle: {
    display: "flex",
    borderBottom: "1px solid #eaeaea",
    paddingBottom: 10,
  },
  quranImg: {
    marginRight: 10,
    width: 50,
  },
  alQuran: {
    marginTop: 10,
    textTransform: "uppercase",
    fontFamily: "Open Sans",
    color: "#080F58",
    fontWeight: 800,
  },
  alQuranSubHeading: {
    color: "#666",
    fontSize: 12,
    fontFamily: "Open Sans",
    marginTop: 5,
    display: "block",
    lineHeight: 1.5,
  },
  quranCommited: {
    fontFamily: "Open Sans",
    color: "#666",
    fontSize: 16,
    lineHeight: 1.5,
    marginTop: 0,
  },
  width: {
    width: "80%",
  },
  learnMoreButton: {
    background: "#00C9A8",
    marginTop: 35,
    color: "white",
    border: 0,
    "&:hover": {
      background: "#00C9A8",
    },
  },
  counterBox: {
    display: "inline-block",
    width: "49%",
    marginBottom: 30,
  },
  printed: {
    fontFamily: "Open Sans",
    fontSize: 14,
    color: "#666",
    fontWeight: 600,
  },
  counter: {
    fontSize: 36,
    marginTop: 5,
    color: "#080F58",
    fontFamily: "Roboto Slab",
    fontWeight: 100,
  },
  sponsoringQuran: {
    backgroundColor: "#4B0082",
    borderRadius: 10,
    marginTop: 100,
    padding: "60px 40px",
    position: "relative",
    paddingRight: "15px",

    "@media (max-width:768px)": {
      padding: "60px 20px",
    },
  },
  sponsoringRequired: {
    color: "#fff",
    fontFamily: "Roboto Slab",
    fontWeight: 400,
    fontSize: 34,
  },
  quranicQuote: {
    color: "#fff",
    fontFamily: "Open Sans",
    lineHeight: 1.5,
    fontSize: 20,
  },
  quranicQuotePadding: {
    paddingTop: 25,
    paddingRight: 50,
    paddingBottom: 0,
    "@media (max-width:959px)": {
      paddingRight: 10,
      paddingBottom: 30,
    },
  },
  mokeUp: {
    position: "relative",
    bottom: 30,
    left: 6,
    textAlign: "right",
    "@media (max-width:959px)": {
      bottom: "unset",
      position: "unset",
      textAlign: "center",
      marginBottom: 20,
    },
  },
  quranMockup: {
    width: "270px",
    right: "-6px",
    position: "absolute",
    top: "-31px",

    textAlign: "right",
    "@media (max-width:959px)": {
      width: "50%",
    },
  },
  padding: {
    paddingTop: 60,
    paddingLeft: 40,
    "@media (max-width:959px)": {
      paddingRight: 20,
    },
  },
  noItem: {
    background: "#fff",
    boxShadow: "0px 3px 10px #00000029",
    color: "#4B0082",
    fontFamily: "Roboto Slab",
    textAlign: "center",
    fontSize: 24,
    padding: 0,
    borderRadius: 4,
    height: "80px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",

    "&:hover": {
      background: "#fff",
    },
  },

  quranButton: {
    paddingTop: "30px",
  },

  maxWidth: {
    maxWidth: 1120,
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Container classes={{ root: classes.maxWidth }}>
      <Grid container>
        <Grid container item xs={12} className={classes.sponsoringQuran}>
          <Grid item xs={12} md={9}>
            <Typography variant="h6" className={classes.sponsoringRequired}>
              Be part of the reward by sponsoring Al-Quran
            </Typography>
            <Box className={classes.quranicQuotePadding}>
              <Typography
                variant="caption"
                classes={{ caption: classes.quranicQuote }}
              >
                Those who in charity spend of their goods by night and by day,
                in secret and in public, have their reward with their Lord: on
                them shall be no fear, nor shall they grieve (Al-Quran 2:274)
              </Typography>
            </Box>
            <img
              src={QuranBox}
              className={classes.quranMockup}
              alt="Quran Box"
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.quranButton}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Link
                href={process.env.REACT_APP_DONATE_URL}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box className={classes.noItem}>$15 - 5 Quran</Box>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link
                href={process.env.REACT_APP_DONATE_URL}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box className={classes.noItem}>$45 - 15 Quran</Box>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link
                href={process.env.REACT_APP_DONATE_URL}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box className={classes.noItem}>$150 - 50 Quran</Box>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link
                href={process.env.REACT_APP_DONATE_URL}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box className={classes.noItem}>$300 - 100 Quran</Box>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link
                href={process.env.REACT_APP_DONATE_URL}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box className={classes.noItem}>$900 - 300 Quran</Box>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link
                href={process.env.REACT_APP_DONATE_URL}
                underline="none"
                target="_blank"
                rel="noopener"
              >
                <Box className={classes.noItem}>$1500 - 500 Quran</Box>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
