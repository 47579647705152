import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BannerImage from '../../assets/blue_pattren.png'



const useStyles = makeStyles((theme) => ({
bannerImg : {
    backgroundImage: `url(${BannerImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    color: '#fff',
    padding: "40px 20px",
},
queryText : {
  padding : 20,
  margin: '0 auto',
  fontFamily: 'Roboto Slab, serif',
  color: '#fff',
  fontSize: 60,
  fontWeight : 600,
  lineHeight : 1,
  '@media (max-width:600px)' : {
    fontSize: 36
  }
},
title :{
  color: '#fff',
	fontFamily: 'Roboto Slab',
  fontSize: 24,
	display: 'block',
	lineHeight: 1.5,
  fontWeight: 600,

},
button : {
  background: '#fec553',
  marginTop: 15,
  color: 'white',
  '&:hover': {
    background: '#fec553' 
  }
},
arrowBox : {
  textAlign: 'right'
},
arrow :{
  border: '1px solid',
  borderRadius: 35,
  margin: '5px',
  color: '#fff'
},
textCenter : {
  textAlign : 'center'
},
plainText :{
  fontFamily: 'Open Sans',
	fontSize: 36,
  lineHeight: 1.5,
  '@media (max-width:600px)' : {
    fontSize: 18
  },
  '@media (min-width:961px)' :{
    fontSize: 26
  }
},
maxWidth :{
  maxWidth: 1120
}
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.bannerImg}> 
    <Container classes={{ root : classes.maxWidth }}>
      <Grid item xs={12}  className={ classes.textCenter}  >
      
           <Typography variant="caption" className={classes.title}>AlQuranForAll.com</Typography> 
          <Typography variant="h1" className={ classes.queryText }>Order Form</Typography>
          <Typography variant="caption" className={classes.plainText}>Place Order, Receive and Explore direct words of God.</Typography>
      </Grid>
      </Container>

  </Grid>
  );
}
