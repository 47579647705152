import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Button, Box, Slide, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ArrowForward, ArrowBack } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';


import BannerImage0 from '../../assets/Women_Rights_banner.png'
import BannerImage1 from '../../assets/Human_Rights.png';
import BannerImage2 from '../../assets/Islam_is_peace_banner.png'
import BannerImage3 from '../../assets/banner.png'
import BannerImage4 from '../../assets/muna-convention-2023-al-quran-dawah.png';


import './style.css'

const styles = theme => ({
  img0: {
    backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 49%, #6564DB00 100%), url(${BannerImage0})`,
  },
  img1: {
    backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 49%, #6564DB00 100%), url(${BannerImage1})`,
  },
  img2: {
    backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 49%, #6564DB00 100%), url(${BannerImage2})`,
  },

  img3: {
    backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 49%, #6564DB00 100%), url(${BannerImage3})`,
  },

  img4: {
    backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 49%, #6564DB00 100%), url(${BannerImage4})`,
  },

  bannerImg: {
    padding: '70px 0px',
    opacity: 1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',

    '@media (max-width:768px)': {
      
    }


  },
  queryText: {
    margin: '0 auto',
    color: '#fff',
    fontWeight: "bold",
    fontSize: 96,
    [theme.breakpoints.down("md")]: {
      fontSize: 36
    }
  },
  plainText: {
    color: '#fff',
    fontSize: 24,
    display: 'block',
    lineHeight: 2,
    paddingTop: 15,
    '@media (min-width:961px)': {
      paddingRight: 175
    }
  },

  auther: {
    color: '#fff',
    fontSize: 24,
    display: 'block',
    lineHeight: 2,
    paddingTop: 0,
    margin: "0px",
  },

  button: {
    background: '#fec553',
    marginTop: 25,
    color: 'white',
    height: 70,
    width: 234,

    '&:hover': {
      background: '#fec553'
    },

    '@media (max-width:768px)': {
      width: "100%",
    }
  },
  arrowBox: {
    textAlign: 'right',

    '@media (max-width:768px)': {
      textAlign: "center",
      position: "relative",
      top: "30px",
    }
  },
  arrow: {
    border: '1px solid',
    borderRadius: 35,
    margin: 5,
    color: '#fff'
  },
  maxWidth: {
    maxWidth: 1120
  },
  arrowContainer: {
    width: '100%',
    paddingRight: 60,

    '@media (max-width:768px)': {
      textAlign: "center",
      paddingRight: 0,
    }
  }
});


class HomeBanner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      banner: 0
    }
  }

  nextClick = () => {
    const nextIndex = (this.state.banner + 1) % 4
    this.setState({
      banner: nextIndex
    })
  }


  prevClick = () => {

    let nextIndex = ''

    if (this.state.banner === 0) {
      nextIndex = 2
    }
    else {
      nextIndex = this.state.banner - 1
    }

    this.setState({
      banner: nextIndex
    })
  }

  render() {

    const { classes } = this.props;
    const { banner } = this.state;

    return (

      // FIX SLIDER CONTENT REPITITION BY MAKING REUSEABLE COMPONENT
      <div className="banner" style={{ overflow: "hidden" }}>
        {/* {banner === 0 && (<Slide direction="left" in={banner === 0} ><Grid container className={`${classes.bannerImg} ${banner === 0 && classes.img0} `}>
          <Container classes={{ root: classes.maxWidth }}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="h1" component="h1" className={classes.queryText} style={{padding: '100px 0'}}> </Typography>
                <Typography variant="body1" className={classes.plainText} style={{padding: '100px 0'}}> </Typography>
              </Box>

              <Button variant="outlined" size="medium" className={classes.button} component={RouterLink} to="/order"> ORDER FREE QURAN</Button>

            </Grid>
          </Container>

          <Grid item xs={12} className={classes.arrowContainer} >
            <Box className={classes.arrowBox}>
              <ArrowBack className={classes.arrow} fontSize="large" onClick={this.prevClick} />
              <ArrowForward className={classes.arrow} fontSize="large" onClick={this.nextClick} />
            </Box>
          </Grid>

        </Grid></Slide>)} */}

        {banner === 0 && (<Slide direction="left" in={banner === 0} ><Grid container className={`${classes.bannerImg} ${banner === 0 && classes.img0} `}>

          <Container classes={{ root: classes.maxWidth }}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="h1" component="h1" className={classes.queryText}>Women's Rights in Islam</Typography>
                <Typography variant="body1" className={classes.plainText}>"There is no doubt that Islam came to honor women, guard her, protect her from the wolves of mankind, secure her rights, and raise her status."</Typography>
                <Typography variant="body1" className={classes.auther}>(Shaykh Ibn Baaz)</Typography>
              </Box>

              <Button variant="outlined" size="medium" className={classes.button} component={RouterLink} to='/order'> ORDER FREE QURAN</Button>

            </Grid>
          </Container>

          <Grid item xs={12} className={classes.arrowContainer} >
            <Box className={classes.arrowBox}>
              <ArrowBack className={classes.arrow} fontSize="large" onClick={this.prevClick} />
              <ArrowForward className={classes.arrow} fontSize="large" onClick={this.nextClick} />
            </Box>
          </Grid>

        </Grid></Slide>)}

        {banner === 1 && (<Slide direction="left" in={banner === 1} ><Grid container className={`${classes.bannerImg} ${banner === 1 && classes.img1} `}>
          <Container classes={{ root: classes.maxWidth }}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="h1" component="h1" className={classes.queryText}>Human Rights, Justice, & BLM</Typography>
                <Typography variant="body1" className={classes.plainText}>"O you who believe stand firm for justice, even against your own selves."</Typography>
                <Typography variant="body1" className={classes.auther}>(Al Qur'an 4:135)</Typography>
              </Box>

              <Button variant="outlined" size="medium" className={classes.button} component={RouterLink} to="/#order"> ORDER FREE QURAN</Button>

            </Grid>
          </Container>

          <Grid item xs={12} className={classes.arrowContainer} >
            <Box className={classes.arrowBox}>
              <ArrowBack className={classes.arrow} fontSize="large" onClick={this.prevClick} />
              <ArrowForward className={classes.arrow} fontSize="large" onClick={this.nextClick} />
            </Box>
          </Grid>

        </Grid></Slide>)}


        {banner === 2 && (<Slide direction="left" in={banner === 2} ><Grid container className={`${classes.bannerImg} ${banner === 2 && classes.img2} `}>
          <Container classes={{ root: classes.maxWidth }}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="h1" component="h1" className={classes.queryText}>Islam means peace</Typography>
                <Typography variant="body1" className={classes.plainText}>Did you know that the word Islam translated in english means peace and the greetings of every Muslim to one another is Salaam (peace)?</Typography>
              </Box>

              <Button variant="outlined" size="medium" className={classes.button} component={RouterLink} to="/order"> ORDER FREE QURAN</Button>

            </Grid>
          </Container>

          <Grid item xs={12} className={classes.arrowContainer} >
            <Box className={classes.arrowBox}>
              <ArrowBack className={classes.arrow} fontSize="large" onClick={this.prevClick} />
              <ArrowForward className={classes.arrow} fontSize="large" onClick={this.nextClick} />
            </Box>
          </Grid>

        </Grid></Slide>)}

        {banner === 3 && (<Slide direction="left" in={banner === 3} ><Grid container className={`${classes.bannerImg} ${banner === 3 && classes.img3} `}>
          <Container classes={{ root: classes.maxWidth }}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="h1" component="h1" className={classes.queryText}>Qur'an - direct the words of Allah</Typography>
                <Typography variant="body1" className={classes.plainText}>Revealed more than 1400 years ago, the words of the Qur'an have remained intact and unchanged from any human influence. </Typography>
              </Box>

              <Button variant="outlined" size="medium" className={classes.button} component={RouterLink} to="/order"> ORDER FREE QURAN</Button>

            </Grid>
          </Container>

          <Grid item xs={12} className={classes.arrowContainer} >
            <Box className={classes.arrowBox}>
              <ArrowBack className={classes.arrow} fontSize="large" onClick={this.prevClick} />
              <ArrowForward className={classes.arrow} fontSize="large" onClick={this.nextClick} />
            </Box>
          </Grid>

        </Grid></Slide>)}

     
      </div >
    );
  }
}

export default withStyles(styles)(HomeBanner);


// ${banner === 1 && classes.img1} ${banner === 2 && classes.img2}`}


// {banner === 1 && (<Slide direction="left" in={banner === 1}><Box>
// <Typography variant="h1" component="h1" className={classes.queryText}>Women's Rights in Islam 2</Typography>
// <Typography variant="body1" className={classes.plainText}>There is no doubt that Islam came to honor women, guard her, protect her from the wolves of mankind, secure her rights, and raise her status.</Typography>
// <Typography variant="body1" className={classes.auther}>(Shaykh Ibn Baaz)</Typography>
// </Box></Slide>)}

// {banner === 2 && (<Slide direction="left" in={banner === 2}><Box>
// <Typography variant="h1" component="h1" className={classes.queryText}>Women's Rights in Islam 3</Typography>
// <Typography variant="body1" className={classes.plainText}>There is no doubt that Islam came to honor women, guard her, protect her from the wolves of mankind, secure her rights, and raise her status.</Typography>
// <Typography variant="body1" className={classes.auther}>(Shaykh Ibn Baaz)</Typography>
// </Box></Slide>)}