import React, { useEffect } from 'react';
import Header from '../common/head';
import Banner from './Banner';
import Body from './Body';
import Social from '../common/Social';
import Footer from '../common/Footer';

function App() {

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <React.Fragment>
      <Header />
      <Banner />
       <Body />
      <Social /> 
      <Footer />
    </React.Fragment>
  );
}

export default App;