import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BannerImage from '../../assets/about-banner.png'



const useStyles = makeStyles((theme) => ({
bannerImg : {
    backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 13%, #6564DB00 128%), url(${BannerImage})`,
    backgroundRepeat: 'no-repeat'
},
queryText : {
  margin: '0 auto',
  fontFamily: 'Roboto Slab, serif',
  color: '#fff',
  fontSize: 48,
  fontWeight : 600,
  '@media (max-width:600px)' : {
    fontSize :36
  }
},
plainText :{
    color: '#fff',
	fontFamily: 'Open Sans, sans-serif',
	fontSize: 24,
	display: 'block',
	lineHeight: 1.5,
  paddingTop: 25,
  '@media (max-width:600px)' : {
    fontSize :20
  }
},
button : {
  background: '#fec553',
  marginTop: 15,
  color: 'white',
  '&:hover': {
    background: 'white'
  }
},
arrowBox : {
  textAlign: 'right'
},
arrow :{
  border: '1px solid',
  borderRadius: 35,
  margin: '5px',
  color: '#fff'
},
textCenter : {
    textAlign : 'center',
    padding : '80px 0px'
},
maxWidth :{
  maxWidth: 1120
}
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Grid Container className={classes.bannerImg}> 
    <Container classes={{ root : classes.maxWidth }}>
      <Grid item xs={12}  className={ classes.textCenter}  >
        
          <Typography variant="h1" className={ classes.queryText }>The book of truth</Typography>
          <Typography variant="caption" className={classes.plainText}>Indeed, We sent down to you the Book for the people in truth. So whoever is guided - it is for [the benefit of] his soul; and whoever goes astray only goes astray to its detriment. And you are not a manager over them.</Typography>
          <Typography variant="caption" className={classes.plainText}>Al-Quran 39:41</Typography>
      </Grid>
      </Container>

    
  </Grid>
  );
}
