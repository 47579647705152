import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box , Grid, Typography} from '@material-ui/core';
import HandImg from '../../assets/hand.png';
import AboutQuranImg from '../../assets/about-quran-pic.png';
import { Link as RouterLink } from 'react-router-dom';
 

const useStyles = makeStyles((theme) => ({
donateBox : {
    marginTop : 40
},
aboutTitle:{
    color: '#080F58',
    fontSize: 36,
    fontFamily:'Roboto Slab',
    margin: '0 0 15px 0',
    fontWeight: 400,
    lineHeight: 1,
    paddingTop: 40,
    '@media (max-width:600px)' : {
        fontSize :30
    }
},
subTitle:{
    color: '#666',
	fontFamily: 'Open Sans',
    wordSpacing: 1.5,
    fontSize: 15,
    lineHeight: 1.7,
    textAlign : "justify",
    fontWeight : 'bold'
},
aboutText :{
	color: '#666',
	fontFamily: 'Open Sans',
    wordSpacing: 1.5,
    fontSize: 15,
    lineHeight: 1.7,
    textAlign : "justify"
},
AboutImg : {
    width: '70%',
    marginLeft : 100
},
AboutbarImg : {
    width: '100%',
    marginTop : 20
},
handContent : {
	background: '#FEC553',
	padding: '10px 15px',
	textAlign: 'center',
    borderRadius:'4px 4px 0px 0px',
    marginTop :40
},
handContent2 : {
	background: '#00C9A8',
	padding: '10px 15px',
	textAlign: 'center',
    borderRadius:'4px 4px 0px 0px',
    marginTop :40
},
donateContent : {
    border: '2px solid #FEC553',
	background: '#fff',
	padding: '15px 15px 20px',
    borderRadius: '0px 0px 4px 4px'
},
donateContent2 : {
    border: '2px solid #00C9A8',
	background: '#fff',
	padding: '15px 15px 20px',
    borderRadius: '0px 0px 4px 4px'
},
donateTitle : {
	color: '#080F58',
	fontFamily: 'Roboto Slab',
	fontSize: 24,
	margin: 0
},
donateButton : {
    padding : 10,
    background: '#FEC553',
    borderRadius: 3,
    color: 'white',
    border : 0,
    width: '100%',
    marginBottom : 10,
    '&:hover': {
        background: '#FEC553'
    }
},
donateButton2 : {
    padding : 10,
    background: '#00C9A8',
    borderRadius: 3,
    color: 'white',
    border : 0,
    width: '100%',
    marginBottom : 10
},
donate : {
    color: '#666',
	fontSize: 14,
	fontFamily: 'Open Sans',
	lineHeight: 1.5
},
donateProjects :{
	display: 'flex',
	marginTop: 20,
	padding: '20px 0 0',
	borderTop: '1px solid #eaeaea'
},
donateLabel : {
    opacity: 0.2,
    background: '#080F58',
    height: 45,
    width: 45,
    borderRadius: 3
},
ourProject : {
    marginLeft: 10,
    paddingTop: 5
},
projectTitle : {
    margin: '0 0 5px', 
	color: '#080F58',
	fontSize: 14,
	fontFamily: 'Roboto Slab'
},
creative : {
	margin: 0,
	fontFamily: 'Open Sans',
	fontSize: 12,
	color: '#666'
},
contRead  : {
    marginTop :15,
	fontFamily: 'Open Sans',
	fontSize: 16,
	color : '#00C9A8',
	fontWeight: 600,
    textTransform: 'uppercase',
    textDecoration : 'none'
}
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
        <Grid item xs={12} >
            <Typography variant="h3" classes={{ h3 :classes.aboutTitle }}>{props.title } </Typography>
            <Typography className={classes.subTitle } >There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:</Typography>
        </Grid>
        
        <Grid item xs={12} md={8} className={ classes.donateBox }>
            <Typography className={ classes.aboutText } >The sky plays a crucial role in protecting the earth. The sky protects the earth from the lethal rays of the sun. </Typography>
            <br />
            <Typography className={ classes.aboutText } >If the sky did not exist, then the sun’s radiation would have killed off all life on earth. It also acts like a blanket wrapped around the earth, to protect it from the freezing cold of space.</Typography>
            
            <img src={AboutQuranImg} className={classes.AboutbarImg} alt="about bar img" />
            <Typography className={ classes.aboutText } >The temperature just above the sky is approximately -270oC. If this temperature was to reach earth, then the planet would freeze over instantly.</Typography>
            <br />
            <Typography variant="caption" className={ classes.aboutText } > The sky also protects life on earth by warming the surface through heat retention (greenhouse effect), and reducing temperature extremes between day and night [3].</Typography>
            <br />

            <RouterLink href="#" className={ classes.contRead } >Continue reading...</RouterLink>
        </Grid>

        <Grid item xs={12} md={4}  >
            
            <Box className={classes.handContent }>
                <img src={HandImg} alt="hand-png" />
            </Box>
            <Box className={ classes.donateContent }>
                <h4 className={classes.donateTitle}>ORDER FREE QURAN</h4>
                <p className={ classes.donate }>Order your free copy of Quran in the language and quantity you need</p>
                <Button variant="outlined" size="medium" className={classes.donateButton } component={RouterLink} to="/order" >
                    ORDER NOW
                </Button>
            </Box>

            <Box className={classes.handContent2 }>
                <img src={HandImg} alt="hand-png" />
            </Box>
            <Box className={ classes.donateContent2 }>
                <h4 className={classes.donateTitle}>DONATE US</h4>
                <p className={ classes.donate }>Be a part of our awesome quest. Be part of the reward by sponsoring Al-Quran</p>
                <Button variant="outlined" size="medium" className={classes.donateButton2 }>
                    DONATE US
                </Button>
            </Box>
            
        </Grid>
    </Grid>
    
  );
}
