import React from 'react';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';



function OurSocialmedia(props) {



  return (

    <div className='oursocialmedia'>
      <div className='mainContainer'>
        <h1>Our Social Media Activities</h1>

        <Grid container spacing={3}>

          <Grid item md={6} xs={12}>
               <div class="fb-page" data-href="https://www.facebook.com/AlQuranDawahCenter/" data-tabs="timeline" data-width="500px" data-height="880px" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/AlQuranDawahCenter/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/AlQuranDawahCenter/">Al Quran Dawah Center</a></blockquote></div>
          </Grid>


          <Grid item md={6} xs={12}>
            <div className='StayCard'>
              <h2>Stay Updated</h2>
              <p>Follow our Facebook page to stay updated on our activities.</p>
              <a href='https://www.facebook.com/AlQuranDawahCenter/' target="_blank">Find us on <FacebookIcon /></a>
            </div>
          </Grid>

        </Grid>

      </div>

    </div>

  );
}


export default OurSocialmedia;