import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Typography, TextField, Button, Box, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GetInquiryTypes } from '../../actions/homeActions';
import Captcha from '../common/Captcha';
import Axios from 'axios'
import ThankYou from './ThankYou'


const styles = theme => ({
    mainBox: {
        background: '#fafbfe',
        marginTop: 40,
        paddingBottom: 60
    },
    missionText: {
        color: '#666',
        fontSize: 16,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        '@media (max-width:576px)': {
            fontSize: 14
        }
    },
    title: {
        color: '#080F58',
        fontSize: 60,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        fontWeight: 400,
        lineHeight: 1,
        paddingTop: 40,
        '@media (max-width:576px)': {
            fontSize: 28
        }
    },
    text: {
        color: '#666',
        fontSize: 24,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        fontWeight: 600,
        maxWidth: '690px',
        margin: '0 auto',
        display: 'inline-block',
        '@media (max-width:576px)': {
            fontSize: 18
        },
        '@media (min-width: 992px)': {
            padding: '0px 95px'
        }
    },

    orderButton: {
        padding: 10,
        background: '#00C9A8',
        fontFamily: 'Open Sans',
        borderRadius: 4,
        fontSize: '20px',
        color: 'white',
        border: 0,
        width: 233,
        height: '70px',
        marginBottom: 10,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    textField: {
        width: '100%',
        background: 'transparent',
        marginBottom: 15,
        color: '#737373',
        fontSize: 14
    },
    textBox: {
        width: '100%',
        background: 'transparent',
        marginBottom: 15,
        color: '#737373',
        fontSize: 14,
        height: 70
    },
    captchaButton: {
        padding: 10,
        background: 'white',
        borderRadius: 3,
        color: '#666',
        marginBottom: 10,
        width: 220,
        '&:hover': {
            background: 'white'
        }
    },
    maxWidth: {
        maxWidth: 1120
    },
    charLength: {
        position: 'relative',
        top: '-43px',
        float: 'right',
        color: 'rgba(0, 0, 0, 0.54)',
        letterSpacing: '0.4px',
        fontSize: '12px',
        fontFamily: 'Roboto',
    },
    disableButton: {
        border: "1px solid #999999",
        backgroundColor: "#cccccc",
        color: "#666666",
    }
});

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#00C9A8',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#00C9A8',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#00C9A8',
            },
        },
    },
})(TextField);

let backendapiURL = process.env.REACT_APP_BACKEND_API_URL;


class ContactUsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inquiryTypes: [
                {
                    "inquiry": "Funds"
                },
                {
                    "inquiry": "Donation"
                },
                {
                    "inquiry": "Contact"
                }
            ],
            captcha: '',
            contactData: {
                name: '',
                email: '',
                inquiryType: '',
                message: '',
                stream: process.env.REACT_APP_CONTACT_STREAM,
                orgId: process.env.REACT_APP_ORGANIZATION_ID,
            },
            showThankYou: false
        }
    }

    componentDidMount() {
        this.props.GetInquiryTypes();
    }

    static getDerivedStateFromProps(props, state) {

        const { inquiryTypes } = props?.home ? props.home : {};

        let stateChanged = false;
        let changedState = {};


        if (inquiryTypes && JSON.stringify(state.inquiryTypes) !== JSON.stringify(inquiryTypes)) {
            changedState.inquiryTypes = inquiryTypes;
            stateChanged = true;
        }


        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    changeHandler = (e) => {
        if (e.target.name === "message" && e.target.value.length > 100) {
            return;
        } else {
            e.persist();
            this.setState(prevState => ({
                contactData: {
                    ...prevState.contactData,
                    [e.target.name]: e.target.value
                }
            }));
        }
    }

    changeCaptcha = (value) => {
        this.setState({
            captcha: value
        })
    }

    submitHandler = () => {
        const { name, message, inquiryType, email, stream, orgId } = this.state.contactData
        const postData = {
            'fullName': name, 
            'message': message, 
            'inquiryType': inquiryType, 
            'email': email, 
            'stream': stream, 
            'orgId': orgId, 
            'name': name
        };

        if (name, message, inquiryType, email) {
            Axios
                .post(
                    backendapiURL + '/contact', postData
                )
                .then(res => {
                    console.log('contact ', res.data);
                    // this.setState({
                    //     captcha: '',
                    //     contactData: {
                    //         name: '',
                    //         email: '',
                    //         inquiryType: '',
                    //         message: '',
                    //         stream: process.env.REACT_APP_CONTACT_STREAM,
                    //         orgId: process.env.REACT_APP_ORGANIZATION_ID,
                    //     },
                    // })
                    this.setState(prevState => ({
                        showThankYou: !prevState.showThankYou
                    }));
                })
                .catch(err => console.log('err', err))
        }
    }


    render() {

        const { classes } = this.props;

        const { inquiryTypes, contactData, captcha, showThankYou } = this.state;

        const { name, message, inquiryType, email } = contactData;

        let disableBtn = name && message && inquiryType && email && captcha ? false : true;

        // console.log('inqeryTypes', inquiryTypes)

        return (
            <React.Fragment>

                <Container classes={{ root: classes.maxWidth }} ref={this.props.contactusRef} >
                    {!showThankYou ? <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.textCenter} >
                            <Typography variant="h4" className={classes.title}>Contact Us</Typography>
                            <Box className={classes.padding}>
                                <Typography variant="caption" className={classes.text}>Our amazing team is very approachable and would love to help you with your queries.</Typography>
                            </Box>

                        </Grid>

                        <Grid item xs={12} sm={6}>

                            <CssTextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                className={classes.textField}
                                name="name"
                                onChange={this.changeHandler}
                                value={contactData.name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                id="outlined-basic"
                                label="Email Address"
                                variant="outlined"
                                className={classes.textField}
                                name="email"
                                onChange={this.changeHandler}
                                value={contactData.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>

                            <CssTextField
                                id="outlined-basic"
                                label="Subject"
                                variant="outlined"
                                className={classes.textField}
                            // name="name"
                            // onChange={this.changeHandler}
                            // value={this.state.name}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CssTextField
                                id="standard-select-currency"
                                select
                                label="Inquiry Types"
                                helperText="Please select Inquiry Types"
                                variant="outlined"
                                className={classes.textField}
                                name="inquiryType"
                                onChange={this.changeHandler}
                                value={contactData.inquiryType}
                            >
                                {inquiryTypes && inquiryTypes.map((type, index) => (
                                    <MenuItem key={index} value={type.inquiry}>
                                        {type.inquiry}
                                    </MenuItem>
                                ))}
                            </CssTextField>

                        </Grid>

                        <Grid item xs={12} >

                            <CssTextField
                                id="outlined-basic"
                                label="Write your message"
                                variant="outlined"
                                name="message"
                                helperText="Helper Text!"
                                multiline
                                rows={4}
                                onChange={this.changeHandler}
                                className={classes.textField}
                                value={contactData.message}
                            />
                            <p className={classes.charLength}>CHAR {contactData.message.length}/100</p>
                        </Grid>

                        <Grid item xs={12} className={classes.textCenter}>
                            <Captcha changeCaptcha={this.changeCaptcha} />

                            <br />
                            <Button variant="outlined" size="large" className={`${classes.orderButton} ${disableBtn ? classes.disableButton : ''}`} onClick={this.submitHandler} disabled={disableBtn}>
                                SEND MESSAGE
                            </Button>
                        </Grid>
                    </Grid> : <ThankYou email={this.state.contactData.email} />}

                </Container>

            </React.Fragment>
        );

    }

}


ContactUsForm.propTypes = {
    GetInquiryTypes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    home: state.home
});

// const mapDispatchToProps = (dispatch) => {
//     return {

//     }
// }


export default withStyles(styles)(connect(mapStateToProps, { GetInquiryTypes })(ContactUsForm));
