import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Button,
  Box,
  Grid,
  Typography,
  Link,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import AboutImg from "../../assets/about-img.png";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  projectsTitle: {
    margin: "0px",
    color: "#080F58",
    fontFamily: "Roboto Slab",
    fontWeight: "bold",
    fontSize: 24,
  },
  projectLabel: {
    color: "#00000099",
    fontSize: 16,
    fontFamily: "Open Sans",
    marginTop: 5,
    display: "inline-block",
  },
  rightArrow: {
    marginTop: -10,
    display: "inline-block",
    color: "#080F58",
    float: "right",
  },
  Box: {
    padding: "20px 35px",
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
      backgroundColor: "#f7f7f7",
    },

    "@media (max-width:768px)": {
      padding: "20px 0px",
    },


  },
  aboutUs: {
    marginBottom: 100,

    "@media (max-width:768px)": {
      padding: "0 0px",
      marginBottom: 0,
    },

    "@media (max-width:960px)": {
      textAlign: "center",
      marginLeft: "unset",
    },
  },
  aboutTitle: {
    color: "#080F58",
    fontSize: 60,
    fontFamily: "Roboto Slab",
    margin: "0 0 15px 0",
    fontWeight: 400,
    lineHeight: 1,
    paddingTop: 80,
    marginBottom: 30,
    "@media (max-width:768px)": {
      paddingTop: 30,
    },
  },
  aboutText: {
    color: "#666",
    fontFamily: "Open Sans",
    wordSpacing: 1.5,
    fontSize: 24,
    lineHeight: 1.7,
    textAlign: "left",
  },
  ButtonsBox: {
    marginTop: 30,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  ButtonMore: {
    background: "#00C9A8",
    marginRight: 30,
    border: 0,
    color: "white",
    fontSize: 20,
    height: 70,
    width: 250,
    "&:hover": {
      background: "#00C9A8",
    },

    "@media (max-width:768px)": {
      width: "100%",
      marginTop:"20px",
    },

    "@media (max-width:400px)": {
      marginBottom: 20,
    },

  },
  missionButton: {
    borderColor: "#00C9A8",
    borderRadius: 3,
    color: "#00C9A8",
    fontSize: 20,
    height: 70,
    width: 250,
    "&:hover": {
      background: "white",
    },
    "@media (max-width:768px)": {
      width: "100%",
      marginTop:"20px",
    },

    "@media (max-width:400px)": {
      marginBottom: 20,
    },
  },

  aboutUsImg: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  AboutImg: {
    width: "60%",
    marginLeft: 100,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    "@media (max-width:960px)": {
      marginLeft: 0,
    },
  },
  hrBorder: {
    border: "0.3px solid #0000001A",
    margin: 0,
  },
  paddingZero: {
    "@media (min-width:961px)": {
      paddingLeft: 0,
    },
  },
  maxWidth: {
    maxWidth: 1120,
  },
}));

export default function MainFeaturedPost(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container classes={{ root: classes.maxWidth }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            className={`${classes.Box} ${classes.paddingZero}`}
          >
            <Link component={RouterLink} to="/about-us#projects" underline="none">
              <Typography variant="h6" classes={{ h6: classes.projectsTitle }}>
                OUR PROJECTS
              </Typography>
              <Typography className={classes.projectLabel}>
              Building faithful communities
              </Typography>
              <ArrowForward className={classes.rightArrow} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.Box}>
            <Link component={RouterLink} to="/order" underline="none">
              <Typography variant="h6" classes={{ h6: classes.projectsTitle }}>
                ORDER FREE QURAN
              </Typography>
              <Typography className={classes.projectLabel}>
                AL-QURAN for all
              </Typography>
              <ArrowForward className={classes.rightArrow} />
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.Box}>
            <Link
              href={process.env.REACT_APP_DONATE_URL}
              underline="none"
              target="_blank"
              rel="noopener"
            >
              <Typography variant="h6" classes={{ h6: classes.projectsTitle }}>
                DONATE US
              </Typography>
              <Typography className={classes.projectLabel}>
                Be a part of our awesome quest
              </Typography>
              <ArrowForward className={classes.rightArrow} />
            </Link>
          </Grid>
        </Grid>
      </Container>
      <hr className={classes.hrBorder} />
      <Container classes={{ root: classes.maxWidth }}>
        <Grid container>
          <Grid item xs={12} md={7} className={classes.aboutUs}>
            <Typography variant="h3" classes={{ h3: classes.aboutTitle }}>
              About us
            </Typography>
            <Typography className={classes.aboutText}>
              Al Qur’an Dawah Center is a non-profit organization working on
              connecting others to the words of God directly through the Holy
              Book, Qur’an. We work to provide any amount of Qur’an and
              resources to be sent to any person, friend, or organizations out
              there at free cost.
            </Typography>

            <Box className={classes.ButtonsBox}>
              <Button
                variant="outlined"
                size="medium"
                className={classes.ButtonMore}
                component={RouterLink} to="/about-us#about"
              >
                MORE ABOUT US
              </Button>

              <Button
                variant="outlined"
                size="medium"
                className={classes.missionButton}
                component={RouterLink} to="/about-us#missions"
              >
                MISSION AND VISION
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={5} className={classes.aboutUsImg}>
            <img src={AboutImg} className={classes.AboutImg} alt="about us" />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
