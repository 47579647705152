import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Container ,TextField, MenuItem, Button, Box} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import BannerImage from '../../assets/about-banner.png'


const useStyles = makeStyles((theme) => ({
bannerImg : {
  backgroundImage: `linear-gradient(90deg, #4B0082 0%, #5833AFAD 13%, #6564DB00 128%), url(${BannerImage})`,
	opacity: 1,
	padding: '60px 0'
},
queryText : {
  margin: '0 auto',
  fontFamily: 'Roboto Slab, serif',
  color: '#fff',
  fontSize: 60,
  '@media (max-width:510px)' :{
    fontSize: 36,
  },
  '@media (min-width:961px)' :{
    fontSize :66
  }
},
plainText :{
  color: '#fff',
	fontFamily: 'Open Sans, sans-serif',
	fontSize: 16,
	display: 'block',
	lineHeight: 1.5,
  paddingTop: 15,
  paddingRight:"50px !important",
  paddingBottom: 30,
  '@media (max-width:510px)' :{
    paddingRight: 24
  },
  '@media (min-width:961px)' :{
    fontSize :22,
    paddingRight: 95,
    lineHeight: 1.8
  }

},
formContainer : {
  background: '#fff',
	textAlign: 'center',
  borderRadius: 4,
  paddingBottom: 20
},
form : {
    width: '80%',
    margin: '0 auto'
},
formTitle : {
	fontFamily: 'Roboto Slab',
	color: '#666',
  textTransform:"uppercase",
},
textField : {
    width: '100%',
    background:'transparent',
    marginBottom : 15,
    color : '#737373',
    fontSize : 14
},
orderButton: {
    padding : 10,
    background: '#00C9A8',
    borderRadius: 3,
    color: 'white',
    border : 0,
    width: '100%',
    marginBottom : 10,
    marginTop: 25,
    height:45,
    '&:hover': {
      background: '#00C9A8'
    }
},
weblink : {
  color: '#fff',
  fontWeight: 600,
  margin: 0,
  fontFamily: 'Roboto Slab'
},
maxWidth :{
  maxWidth: 1120
}
}));

const quantity = [
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4',
    },

    {
      value: '5',
      label: '5',
    },

    {
      value: '6',
      label: '6',
    },

    {
      value: '7',
      label: '7',
    },

    {
      value: '8',
      label: '8',
    },

    {
      value: '9',
      label: '9',
    },

    {
      value: '10',
      label: '10',
    },
  ];

const CssTextField = withStyles({
root: {
  '& label.Mui-focused': {
    color: '#00C9A8',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#00C9A8',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#00C9A8',
    },
  },
},
})(TextField);

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Box className={classes.bannerImg}> 
    <Container classes={{ root : classes.maxWidth }}>
        <Grid container>
            <Grid item xs={12} md={12} >
                <span className={classes.weblink}>AlQuranForAll.com</span>
                <h1 className={ classes.queryText }>Order free Quran for School</h1>
                <span className={classes.plainText}>Let’s guide ours hearts and actions with the direct words from the Almighty and Everlasting God.</span>
                
            </Grid>

            {/* <Grid item xs={12} md={5} className={classes.formContainer} >
                <form className={classes.form} noValidate autoComplete="off">
                    <h2 className={ classes.formTitle }>Order Free Quran</h2>
                    <CssTextField
                        id="standard-select-currency"
                        select
                        label="Select Quantity"
                        //helperText="Please select your currency"
                        variant="outlined"
                        className={ classes.textField }
                    >
                        {quantity.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </CssTextField>

                    <CssTextField 
                        id="outlined-basic" 
                        label="Enter Zip Code" 
                        variant="outlined" 
                        className={ classes.textField }
                        //helperText="helperText"
                    />

                    <Button variant="outlined" size="medium" className={classes.orderButton }>
                        CONTINUE
                    </Button>

                </form>

            </Grid> */}

        </Grid>

      </Container>
  </Box>
  );
}
