import React, { createRef, useState, useEffect } from 'react'
import Header from '../common/head';
import Banner from './banner';
import Body from './body'
import Facts from './Facts';
import OurSocialmedia from './OurSocialmedia';
import OurProject from './ourProject';
import Sponsoring from '../common/Sponsoring';
import OurActivities from './OurActivities';
import Questions from './questions';
import Social from '../common/Social';
import Footer from '../common/Footer';

function App() {

  const quranicfacts = createRef();
  const ourProjects = createRef();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.indexOf("/#quranic-facts") > -1) {
        quranicfacts && quranicfacts.current.scrollIntoView({ behavior: "smooth" });

      } else if (window.location.href.indexOf("/#our-projects") > -1) {
        ourProjects && ourProjects.current.scrollIntoView({ behavior: "smooth" });

    }

      else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
      }
    }, 1000);

  }, [quranicfacts, ourProjects]);



  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Body />
      <div ref={quranicfacts}>
        <Facts />
      </div>
      <OurSocialmedia />
      <div ref={ourProjects}>
        <OurProject />
      </div>
      <Sponsoring />
      <OurActivities />
      <Questions />
      <Social />
      <Footer />
    </React.Fragment>
  );
}

export default App;