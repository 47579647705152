import React, { createRef, useEffect } from "react";
import Banner from './banner';
import Body from './Body';
import ContactUsForm from './contactUsForm';
import Social from '../common/Social';
import Header from '../common/head';
import Footer from '../common/Footer';
import './style.css'

function App() {
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  // });
  const [tab, settab] = React.useState(0);
  const aboutRef = createRef();
  const missionRef = createRef();
  const projectsRef = createRef();
  const contactusRef = createRef();

  useEffect(() => {
    if (window.location.href.indexOf("about-us#about") > -1) {
      settab(0);
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.href.indexOf("about-us#mission") > -1) {
      settab(1);
      missionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (window.location.href.indexOf("about-us#projects") > -1) {
      settab(2);
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      window.location.href.indexOf("about-us#contact-us") > -1
    ) {
      settab(3);
      contactusRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [aboutRef, missionRef, projectsRef, contactusRef]);

  const handleChange = (event, newValue) => {
    settab(newValue);
    if (newValue === 0) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 1) {
      missionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 2) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (newValue === 3) {
      contactusRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Body tab={tab} aboutRef={aboutRef} missionRef={missionRef} projectsRef={projectsRef} handleChange={handleChange} />
      <ContactUsForm contactusRef={contactusRef} />
      <Social />
      <Footer />
    </React.Fragment>
  );
}

export default App;