import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Typography, Button} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircleImg from '../../assets/check_circle_outline.png';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
MainBox : {
    textAlign: 'center',
    marginTop : 50
},
heading : {
    fontSize: 14,
	fontFamily: 'Open Sans',
	color: '#666',
    lineHeight: 1.5,
    display: 'block',
    marginTop: 20,
    '@media (min-width:992px)' : {
        padding: '0px 70px',
    }
},
title : {
    fontSize: 40,
	fontFamily: 'Roboto Slab',
	color: '#080F58',
    fontWeight: 400,
    '@media (max-width:510px)' : {
        fontSize: 24
    }
},
text : {
    fontFamily: 'Roboto Slab',
	fontSize: 23,
	color: '#666',
    lineHeight: 1.5,
    '@media (min-width:992px)' : {
        padding: '0px 182px',
    },
    '@media (max-width:510px)' : {
        fontSize: 18
    }
},
distributes : {
    marginTop : 40,
    fontSize: 24,
	fontFamily: 'Roboto Slab',
	color: '#666',
    fontWeight: 400
},
options : {
	paddinTop: 0,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #efefef',
    paddingTop : 20,
    paddingBottom : 20,
},
optionItem : {
	color: '#080F58',
	fontSize: 20,
	fontFamily: 'Open Sans',
	fontWeight: 700
},
circleImg : { 
	width: 25,
	marginRight: 10	
},
orderQuran:  {
	margin: '25px auto'
},
buttonContainer : {
    textAlign: 'center',
    borderTop: '1px solid #efefef',
    paddingTop : 30,
    paddingBottom : 30
},
orderButton : {
    padding : 10,
    background: '#00C9A8',
    borderRadius: 3,
    color: 'white',
    border : 0,
    marginBottom : 0,
    height: 45,
    width: 190,
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: 14,
    '&:hover': {
        background: '#00C9A8'
    }
},
maxWidth :{
    maxWidth: 1120
}
}));

  

export default function MainFeaturedPost(props) {
  const classes = useStyles();
  return (
    <Container classes={{ root : classes.maxWidth }}>
        <Grid container>
            <Grid item xs={12} className={ classes.MainBox} >
                <Typography variant="h4" className={ classes.title  }>What Is AlQuranForAll.com?</Typography><br/>
                <Typography variant="h5" className={ classes.text  }>AlQuranForAll.com is committed to directly <br/>connect you to God’s words.</Typography>
                <Typography variant="caption" className={ classes.heading  }>It’s our pleasure to announce that Muslims & organizations collectively around the globe has <br/>pioneered and contributed immensely to the development of human civilization.</Typography>
                <Typography variant="h5" className={ classes.distributes  }>We distribute the directs words of God to</Typography>
            </Grid>


            <Grid container className={ classes.MainBox} >
                <Grid item xs={12} sm={6} md={4} className={`${classes.options}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Households</Typography>
                </Grid>


                <Grid item xs={12} sm={6} md={4} className={`${classes.options}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Retirement Homes</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={`${classes.options} ${classes.optionBorder}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Hotels</Typography>
                </Grid>

            
                <Grid item xs={12} sm={6} md={4} className={`${classes.options}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Hospitals/Doctor Offices</Typography>
                </Grid>


                <Grid item xs={12} sm={6} md={4} className={`${classes.options}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Dawah Organizations</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={`${classes.options} ${classes.optionBorder}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >College Dorms</Typography>
                </Grid>

            
                <Grid item xs={12} sm={6} md={4} className={`${classes.options}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Street Dawah</Typography>
                </Grid>


                <Grid item xs={12} sm={6} md={4} className={`${classes.options}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Prisons</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={`${classes.options} ${classes.optionBorder}`}>                

                    <img src={CircleImg} alt="circle-img" className={ classes.circleImg } />
                    <Typography variant="caption" className={ classes.optionItem } >Households</Typography>
                </Grid>

            </Grid>
            <Grid item xs={12} className={ classes.buttonContainer} >
                <Button variant="outlined" size="medium" className={classes.orderButton } component={RouterLink} to="/order">
                    ORDER FREE QURAN
                </Button>
            </Grid>

        </Grid>
    </Container>
  );
}
