import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tabs, Tab, Box, Link, Typography, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
// import PanelContent from './PanelContent';
import QuranImg from '../../assets/quran.png';
import PersonReading from '../../assets/person-book.png';
import BackgroundImage from '../../assets/grey_pattren.png';
import HandImg from '../../assets/hand.png';
import AboutQuranImg from '../../assets/Books.png';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    rootBox: {
        background: '#20C9A8',
        width: '100%',
        color: '#fff',

        '@media (max-width:768px)': {
            display: "block",
            width: "100%",
        }
    },
    Link: {
        textDecoration: "none",
        color: "inherit",

        '@media (max-width:768px)': {
            display: "block",
            width: "100%",
        }
    },

    selected: {
        background: '#23ECCB',
        opacity: 1
    },
    indicator: {
        display: 'none'
    },
    scroller: {
        '@media (max-width:840px)': {
            whiteSpace: 'normal'
        }
    },
    flexContainer: {
        '@media (max-width:840px)': {
            display: 'block'
        }
    },
    maxWidth: {
        maxWidth: 1120
    },
    paddingZero: {
        '@media (min-width:961px)': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    donateNowTab: {
        color: "#fff"
    },

    // OUR PROJECT STYLES
    mainBox: {
        backgroundImage: `url(${BackgroundImage})`,
        marginTop: 80,
        paddingBottom: 127,

    },
    // mainBox: {
    //     background: '#fafbfe'
    // },
    missionText: {
        color: '#00000099',
        fontSize: 24,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        // lineHeight: '42px',
    },
    title: {
        color: '#080F58',
        fontSize: 60,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        fontWeight: 400,
        lineHeight: 1,
        paddingTop: 70,
        
        '@media (max-width:600px)': {
            fontSize: 40
        }
    },
    text: {
        color: '#00000099',
        fontSize: 24,
        fontFamily: 'Open Sans',
        lineHeight: 1.5,
        fontWeight: 600
    },
    influencerTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 24,
        fontWeight: 800,
        marginTop: 10
    },
    influencerText: {
        color: '#00000099',
        fontFamily: 'Open Sans',
        fontSize: 20,
        lineHeight: 1.5,
        marginTop: 20,
        marginBottom: 20
    },
    influencerParent: {
        textAlign: 'center',
        border: '1px solid #0000001A',
        borderRadius: 3,
        background: '#fff',
        padding: 20,
        '@media (max-width:959px)': {
            height: 'auto'
        }
    },
    orderButton: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        fontSize: '20px',
        width: '213px',
        height: '70px',
        marginTop: 10,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    padding: {
        padding: '30px 210px 30px 210px',
        textAlign: 'center',
        lineHeight: 1.5
    },
    imgWidth: {
        marginTop: 30,
        width: 100
    },
    mailFeed: {
        color: '#00C9A8'
    },
    marginTop: {
        marginTop: 50,
        width: '100%'
    },
    projectImg: {
        width: '150px',
    },

    paddingRight: {
        '@media (min-width:961px)': {
            paddingRight: 60
        }
    },

    // Panel Content
    donateBox: {
        marginTop: 40
    },
    aboutTitle: {
        color: '#080F58',
        fontSize: 60,
        fontFamily: 'Roboto Slab',
        margin: '0 0 15px 0',
        fontWeight: 500,
        lineHeight: 1,
        paddingTop: 40,
        '@media (max-width:600px)': {
            fontSize: 30
        }
    },
    aboutText: {
        color: '#00000099',
        fontFamily: 'Open Sans',
        fontSize: 24,
        lineHeight: 1.7,
        textAlign: "justify"
    },
    fontBold: {
        fontWeight: 'bold',
        fontSize: 24,
        color: '#00000099',
    },
    AboutImg: {
        width: '70%',
        marginLeft: 100
    },
    AboutbarImg: {
        width: '100%',
        marginTop: 20,
        marginBottom: 20
    },
    handContent: {
        background: '#FEC553',
        padding: '10px 15px',
        textAlign: 'center',
        borderRadius: '4px 4px 0px 0px',
        marginTop: 40
    },
    handContent2: {
        background: '#00C9A8',
        padding: '10px 15px',
        textAlign: 'center',
        borderRadius: '4px 4px 0px 0px',
        marginTop: 40
    },
    donateContent: {
        border: '2px solid #FEC553',
        background: '#fff',
        padding: '15px 15px 20px',
        borderRadius: '0px 0px 4px 4px'
    },
    donateContent2: {
        border: '2px solid #00C9A8',
        background: '#fff',
        padding: '15px 15px 20px',
        borderRadius: '0px 0px 4px 4px'
    },
    donateTitle: {
        color: '#080F58',
        fontFamily: 'Roboto Slab',
        fontSize: 24,
        margin: 0,
        textTransform: "uppercase",
    },
    donateButton: {
        padding: 10,
        background: '#FEC553',
        borderRadius: 4,
        color: 'white',
        border: 0,
        width: '100%',
        height: '70px',
        fontSize: 20,
        marginBottom: 10,
        '&:hover': {
            background: '#FEC553'
        }
    },
    donateButton2: {
        padding: 10,
        background: '#00C9A8',
        borderRadius: 3,
        color: 'white',
        border: 0,
        width: '100%',
        height: '70px',
        fontSize: 20,
        marginBottom: 10,
        '&:hover': {
            background: '#00C9A8'
        }
    },
    donate: {
        color: '#00000099',
        fontSize: 16,
        fontFamily: 'Open Sans',
        lineHeight: 1.5
    },
    donateProjects: {
        display: 'flex',
        marginTop: 20,
        padding: '20px 0 0',
        borderTop: '1px solid #eaeaea'
    },
    donateLabel: {
        opacity: 0.2,
        background: '#080F58',
        height: 45,
        width: 45,
        borderRadius: 3
    },
    ourProject: {
        marginLeft: 10,
        paddingTop: 5
    },
    projectTitle: {
        margin: '0 0 5px',
        color: '#080F58',
        fontSize: 14,
        fontFamily: 'Roboto Slab'
    },
    creative: {
        margin: 0,
        fontFamily: 'Open Sans',
        fontSize: 12,
        color: '#666'
    },
    contRead: {
        marginTop: 30,
        fontFamily: 'Open Sans',
        fontSize: 20,
        color: '#00C9A8',
        fontWeight: 700,
        textTransform: 'uppercase',
        textDecoration: 'none',
        display: 'inline-block',
        cursor: 'pointer'
    }


}));



export default function MainFeaturedPost(props) {
    const { tab, aboutRef, missionRef, projectsRef, handleChange } = props;
    const classes = useStyles();

    const [continueReading, setcontinueReading] = useState(false)


    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Box className={classes.rootBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Tabs classes={{ indicator: classes.indicator, flexContainer: classes.flexContainer, scroller: classes.scroller }} value={tab} onChange={handleChange} aria-label="simple tabs example">
                                <RouterLink to="/about-us#about" className={classes.Link}>
                                    <Tab label="ABOUT US" {...a11yProps(0)} className={tab === 0 ? classes.selected : ""} />
                                </RouterLink>
                                <RouterLink to="/about-us#mission" className={classes.Link}>
                                    <Tab label="Vission And Mission" {...a11yProps(1)} className={tab === 1 ? classes.selected : ""} />
                                </RouterLink>

                                <RouterLink to="/about-us#projects" className={classes.Link}>
                                    <Tab label="OUR PROJECTS" {...a11yProps(2)} className={tab === 2 ? classes.selected : ""} />
                                </RouterLink>

                                <RouterLink to="/about-us#contact-us" className={classes.Link}>
                                    <Tab label="CONTACT US" {...a11yProps(3)} className={tab === 3 ? classes.selected : ""} />
                                </RouterLink>

                                <Tab />
                                <Link
                                    target="_blank"
                                    underline="none"
                                    rel="noopener noreferrer"
                                    href={process.env.REACT_APP_DONATE_URL}
                                    className={classes.donateNowTab}
                                >
                                    <Tab label="DONATE US" {...a11yProps(4)} classes={{ root: classes.selected, selected: classes.selected }} />
                                </Link>
                            </Tabs>
                        </Grid>
                    </Grid>

                </Container>
            </Box>
            <Box mb={4}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container spacing={6} >
                        <Grid item xs={12} >

                            <Typography variant="h3" ref={aboutRef} classes={{ h3: classes.aboutTitle }}>About Us </Typography>
                            <Typography className={`${classes.aboutText} ${classes.fontBold}`} >There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:</Typography>

                        </Grid>

                        <Grid item xs={12} md={8} className={classes.donateBox}>
                            <Typography className={classes.aboutText} >The inimitable nature of the Qur’an continues to be the most compelling proof that Muhammad ﷺ was, in fact, the final prophet of God. He ﷺ said, “There was no prophet except that he was granted signs that caused the people to believe in him, but what I have been [uniquely] granted is a revelation that Allah has inspired me with, and thus I am hopeful to have the most followers among them on the Day of Resurrection.”</Typography>
                            <br />
                            
                            <img src={AboutQuranImg} className={classes.AboutbarImg} alt="about bar img" />

                            <Typography className={classes.aboutText} >This does not mean that he ﷺ had no other signs (such as performing physical miracles), just as it does not mean that the previous prophets had no revelation. Rather, the implication here is that the Qur’an would be uniquely effective in guiding people to faith over the passage of time.</Typography>

                            {continueReading && <Box mt={3}>
                                <Typography className={classes.aboutText} >should not surprise us that a permanent living miracle that can be experienced firsthand by successive generations would outperform a miraculous event witnessed by a limited group at some moment in history. What does astound many, though, is how any “mere work of literature” could ever qualify as otherworldly in its origins.</Typography>
                                <br />
                                <Typography className={classes.aboutText} >The Qur’an presents itself as the literal speech of God (9:16) and asserts that nothing like it will ever be produced (17:88). This inimitability is multidimensional and we have chosen four of those dimensions for this paper while addressing “alternative explanations” posited by some critics in their attempts to deny the Qur’an’s divine origins.</Typography>
                            </Box>}

                            {continueReading ? <Typography className={classes.contRead} onClick={() => { setcontinueReading(false) }}>Read Less...</Typography> : <Typography className={classes.contRead} onClick={() => { setcontinueReading(true) }}>Continue reading...</Typography>}


                        </Grid>

                        <Grid item xs={12} md={4}  >

                            <Box className={classes.handContent}>
                                <img src={HandImg} alt="hand-png" />
                            </Box>
                            <Box className={classes.donateContent}>
                                <h4 className={classes.donateTitle}>ORDER FREE QURAN</h4>
                                <p className={classes.donate}>Order your free copy of Quran in the language and quantity you need.</p>
                                <Button variant="outlined" size="medium" className={classes.donateButton} component={RouterLink} to="/order">
                                    ORDER NOW
                                 </Button>
                            </Box>

                            <Box className={classes.handContent2}>
                                <img src={HandImg} alt="hand-png" />
                            </Box>
                            <Box className={classes.donateContent2}>
                                <h4 className={classes.donateTitle}>Support Us</h4>
                                <p className={classes.donate}>Be a part of our awesome quest and take part in the reward by sponsoring Al-Qur'an</p>

                                <Link
                                    target="_blank"
                                    underline="none"
                                    rel="noopener noreferrer"
                                    href={process.env.REACT_APP_DONATE_URL}
                                    className={classes.donateNowTab}
                                >
                                    <Button variant="outlined" size="medium" className={classes.donateButton2}>
                                        DONATE US
                                    </Button></Link>
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container classes={{ root: classes.maxWidth }}>
                <Grid container>
                    <Grid item xs={12} md={8} className={classes.paddingRight} >


                    <Typography variant="h3" classes={{ h3: classes.title }}>Our Vision </Typography>
                        <Typography className={classes.missionText} >Connect the seekers of truth and peace with direct words of God.</Typography>
                        <Typography variant="h3" ref={missionRef} classes={{ h3: classes.title }}>Our Mission </Typography>
                        <Typography className={classes.missionText} >Our goal is to send the message of the Holy Qur’an by distributing free Qur’ans to households, prisons, hotels, and various lobbies in different languages. Add verse: "And who is better in speech than one who invites to Allah and does righteousness and says, "Indeed, I am of the Muslims." (al Qur'an, 41:33)</Typography>

                        

                    </Grid>
                    <Grid item xs={12} md={4}  >
                        <img src={PersonReading} className={classes.marginTop} alt="person reading" />
                    </Grid>
                </Grid>
            </Container>

            <Box className={classes.mainBox}>
                <Container classes={{ root: classes.maxWidth }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Typography variant="h3" ref={projectsRef} classes={{ h3: classes.title }}>Our Projects </Typography>
                            <Typography className={classes.text} >There are many clear signs that the religious book of Islam, the Quran, is the word of God and we have many reasons to support and validate this claim:</Typography>

                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box className={classes.influencerParent}>
                                <img className={classes.projectImg} src={QuranImg} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >AL-QURAN FOR ALL</Typography>
                                <Typography className={classes.influencerText}>Alquranforall.com is committed to directly connect you to God’s words.</Typography>

                                <Button component={RouterLink} to="/al-quran-for-all" variant="outlined" size="medium" className={classes.orderButton}>
                                    LEARN MORE
                                </Button>
                            </Box>
                        </Grid>

                        {/* <Grid item xs={12} md={4} >
                            <Box className={classes.influencerParent}>
                                <img className={classes.projectImg} src={QuranImg} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >PROJECT 2</Typography>
                                <Typography className={classes.influencerText}>Alquranforall.com is committed to directly connect you to God’s words.</Typography>

                                <Button component={RouterLink} to="/al-quran-for-all" variant="outlined" size="medium" className={classes.orderButton}>
                                    LEARN MORE
                                </Button>
                            </Box>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box className={classes.influencerParent}>
                                <img className={classes.projectImg} src={QuranImg} alt="influencer-img" />
                                <Typography variant="h3" className={classes.influencerTitle} >PROJECT 3</Typography>
                                <Typography className={classes.influencerText}>Alquranforall.com is committed to directly connect you to God’s words.</Typography>

                                <Button component={RouterLink} to="/al-quran-for-all" variant="outlined" size="medium" className={classes.orderButton}>
                                    COMING SOON
                                </Button>
                            </Box>

                        </Grid> */}
                    </Grid>
                </Container>
            </Box>
        </React.Fragment>
    );
}

function TabPanel(props) {
    const { classes, children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box className={classes.paddingZero} p={3}>{children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};