import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Container ,Tabs, Tab, Box} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import PanelContent from './PanelContent';

const useStyles = makeStyles((theme) => ({
rootBox : {
    background: '#20C9A8',
    width : '100%',
    color :'#fff'
},
mainBox : {
    background: '#fafbfe'
},
selected : {
    background: '#23ECCB',
    opacity: 1
},
indicator :{
    display : 'none'
},
scroller : {
    '@media (max-width:840px)' :{
        whiteSpace: 'normal'
    }
},
flexContainer : {
    '@media (max-width:840px)' :{
        display: 'block'        
	}
},
maxWidth :{
    maxWidth: 1120
},
paddingZero :{
    '@media (min-width:961px)' :{
        paddingLeft : 0,
        paddingRight : 0
    }
}
}));

function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
  

export default function MainFeaturedPost(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return ( 
    <React.Fragment>
    <Box className={ classes.rootBox }>
    <Container classes={{ root : classes.maxWidth }}>
        <Grid container>
            <Grid item xs={12}>
        
                <Tabs classes={{ indicator : classes.indicator , flexContainer : classes.flexContainer, scroller : classes.scroller }} value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="ABOUT AL-QURAN" {...a11yProps(0)} classes={{ selected : classes.selected }} />
                    <Tab label="WHAT'S INSIDE" {...a11yProps(1)} classes={{ selected : classes.selected }} />
                    <Tab label="WORDS IN ACTION" {...a11yProps(2)} classes={{ selected : classes.selected }} />
                    <Tab label="HOW IT WORKS" {...a11yProps(3)} classes={{ selected : classes.selected }} />
                    <Tab />
                    <Tab label="DONATE US" {...a11yProps(4)} classes={{ root : classes.selected , selected : classes.selected }} />
                </Tabs>
            </Grid>
        </Grid>

      </Container>
    </Box>
    <Box className={ classes.mainBox }>
    <Container classes={{ root : classes.maxWidth }}>
        <Grid container >
            <Grid item xs={12} >
                <TabPanel value={value} index={0} classes={classes}>
                    <PanelContent title="ABOUT AL-QURAN"  />
                </TabPanel>
                <TabPanel value={value} index={1} classes={classes}>
                    <PanelContent title="WHAT'S INSIDE" />
                </TabPanel>
                <TabPanel value={value} index={2} classes={classes}>
                    <PanelContent title="WORDS IN ACTION" />
                </TabPanel>
                <TabPanel value={value} index={3} classes={classes}>
                    <PanelContent title="HOW IT WORKS" />
                </TabPanel>
                <TabPanel value={value} index={4} classes={classes}>
                    <PanelContent title="DONATE US" />
                </TabPanel>
            </Grid>
        </Grid>

    </Container>
    </Box>
    </React.Fragment>
    );
}
 
function TabPanel(props) {
    const { classes,children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box className={classes.paddingZero } p={3}>{children}</Box>}
      </Box>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};