import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { TextInputFixedLabel, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import './Modal.css'

import { useSelector, useDispatch } from 'react-redux'
import { setOrderinfo } from '../../features/counter/counterSlice'

function ModalOrder(props) {

    const orderInfo = useSelector((state) => state.counter.orderInfo)
    const dispatch = useDispatch()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [values, setValues] = React.useState({
        contributingAmount: '',       
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        
    };

    

    const confirmButtonClicked = (prop) =>{
        dispatch( setOrderinfo({ ...orderInfo, 
            ['contributingAmount']: values.contributingAmount, 
            ['orderSubtotal']: values.contributingAmount  }) );
        props.setShowSuccessMessage( true ); 
        props.handleClose(); 
    }
    
    return (
        <div>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>

                <div>
                
                    <div className='DeleteModalBox'>

                        <div className='DeleteModalBoxInner'>
                            <div className='FldIconCtn borderBottom'>
                                <h2>Get your order for free</h2>
                                <CloseRoundedIcon className='closeIcon' onClick={() => props.handleClose()} />
                            </div>

                            <div className='DltCmpMdlBody'>

                                <div className='orderModalCampCtn'>
                                    <p>Our projects are running with generous support from amazing donors like you. If not full, you can contribute any amount ($) you like. Please enter the amount you want to contribute below:</p>
                                </div>

                                <div className='ModalFormCtn'>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <label class="input_label">Order total</label>
                                            <div className='FormGroup Fieldfill'>
                                                <div className='amountSign'>$</div>
                                                <TextInputFixedLabel
                                                    value={props.orderTotal}
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item md={6} xs={12}>
                                            <label class="input_label">Enter your amount</label>
                                            <div className='FormGroup'>
                                                <div className='amountSign'>$</div>
                                                <TextInputFixedLabel
                                                    
                                                    onChange={handleChange('contributingAmount')}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                            </div>

                            <div className='ModalFooterCtn ModalOrderFooterCtn'>
                                <Button className="btn btnclose" variant="contained" onClick={() => props.handleClose()}>CLOSE</Button>
                                <Button className=" btn btnconfirm" onClick={confirmButtonClicked} variant="contained">CONFIRM</Button>
                            </div>

                        </div>
                    </div>
                    </div>
                </Fade>
            </Modal>


        </div>


    )
}






export default ModalOrder;