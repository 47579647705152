import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { TextInputFixedLabel, } from "master-react-lib";

import product from '../../assets/about-quran-pic.png'

import './OrderSummary.css'
import { useSelector, useDispatch } from 'react-redux'
import { setOrderinfo } from '../../features/counter/counterSlice'



const OrderSummary = (props) => {

    

    const orderInfo = useSelector((state) => state.counter.orderInfo)
    const dispatch = useDispatch()

    const [totalProducts, setTotalProducts] = useState( orderInfo.quantity );

    const [totalPrice, setTotalPrice] = useState( orderInfo.orderSubtotal );

    // console.log( orderInfo );

    const products =[
        { img:product, title:orderInfo.itemName , quantity:orderInfo.quantity, price:orderInfo.orderSubtotal}
    ]


    return (
        <div className="OrderSummaryCtn">


            {/* <div className="itemContainer">
                <Typography className="itemText">{totalProducts} items</Typography>
                <Button href="#" className="editButton">Edit</Button>
            </div> */}

            <div className="OrderSummaryProductList">
                {products.map((item, i) => (
                    <li>
                        <div className='ProductListleft'>
                            {/* <div className='ProductListleftimg'>
                                <img src={item.img} />
                            </div> */}
                            <div className='ProductListleftInfo'>
                                <h2>{item.title}</h2>
                                <p>Quantity: {item.quantity}</p>
                            </div>
                        </div>
                        <div className='ProductListRight itemAmountText'>${item.price}</div>
                    </li>
                ))}


            </div>

            <div className="itemListContainer">
                <div className="itemListing">
                    <Typography className="itemListText">Subtotal</Typography>
                    <Typography className="itemAmountText">${totalPrice}</Typography>
                </div>

                <div className="itemListing">
                    <Typography className="itemListText">Shipping</Typography>
                    <Typography className="itemAmountText">Free</Typography>
                </div>


                {/* <div className="itemListing">
                    <Typography className="itemListText">
                        Coupon: <spa className="itemBlueText">11218</spa>
                    </Typography>
                    <Typography className="itemAmountText">$100.00</Typography>
                </div> */}

                {/* <div className="itemListing">
                    <Typography className="itemListText">
                        Estimated tax for: <spa className="itemBlueText">11218</spa>
                        <Tooltip title="The sales tax listed on the checkout page is only an estimate. 
                        Your invoice will contain the final sales tax, including state and local taxes,
                         as well as any applicable rebates or fees." arrow placement="top">
                            <HelpRoundedIcon className="infoIcon" />
                        </Tooltip>
                    </Typography>
                    <Typography className="itemAmountText">$100.00</Typography>
                </div> */}

                {/* <div className="itemListing">
                    <Typography className="itemListText">Discounts</Typography>
                    <Typography className="itemAmountText">$0.00</Typography>
                </div> */}

                {/* <div className="FieldContainer">
                    <TextInputFixedLabel
                        helpertext="Helper text"
                        HelpertextStatus={false}
                        placeholder="Enter promo code"
                        Errormessage="Error message"
                        errortextStatus={true}
                        count={100}
                        countStatus={false}
                        tooltipStatus={false}
                    />
                    <Button href="#" className="ButtonApply">Apply</Button>
                </div> */}

                <div className="TotalContainer">
                    <Typography className="TotalText">Total</Typography>
                    <Typography className="TotalAmount">${totalPrice}</Typography>
                </div>

            </div>

        </div>

    )
}

export default OrderSummary;